import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import {
  ElegirLabel,
  CambiarEstadoCheckinReserva,
  ElegirTexto,
  CalcularDeuda,
  chooseStarredCustomer,
  PersonasEnRegistro
} from '../../../Funciones/utils';
import { connect } from 'react-redux';
import { Label, Dropdown } from 'semantic-ui-react';
import Imprimir from './imprimir';
import DateRangePicker from '../extras/dateRangePicker';
import { Button, Icon, Checkbox } from 'semantic-ui-react';
import { Col } from 'reactstrap';
import { Collapse } from 'react-collapse';
import XLSX from 'xlsx-with-styles';
import ReactTable from 'react-table-6';

//ACTIONS
import setReserva from '../../../redux/actions/setReserva';
import setModal from '../../../redux/actions/setModalReserva';
import { Menu } from 'semantic-ui-react';
import printJS from 'print-js';

let dniSearched = '';
let nameSearched = '';
const printListado=(listado, hasCheckin)=>{
  const lista = [...listado].sort((a, b) => a.roomsList.map((x) => x.number).join(' ').localeCompare(b.roomsList.map((x) => x.number).join(' '))).map(y=>({
    'Datos':`${y.customersList.filter(x=>x.fullName).map(z=>`${z.fullName} (${(y.birthdate&&moment().diff(y.birthdate,'years')!=0)?moment().diff(y.birthdate,'years'):'-'})`).join('<br/>')}`,
    'Vehiculos':y.vehiclesList.length>0?y.vehiclesList.map(y=>(`${y.brand?y.brand.toUpperCase():""} ${y.plate}`)).toString().replace(/,/g,' | '):"-",
    "Lugares":y.roomsList.length>0?y.roomsList.map(x=>x.number)?.join('<br/>'):'-',
    "Es deudor":y.isDeudor?"Si":"No",
    "Debe":y.isDeudor?`${y.nightsDue>1?y.nightsDue+" noches":y.nightsDue+" noche"} <br/> $${CalcularDeuda(y)}`:"-",
    'Fecha De Ingreso':y.checkinMade?moment(y.checkinMade).format("DD MMM YYYY HH:mm") : moment(y.checkinEstimated).format("DD MMM YYYY"),
    'Fecha De Egreso':y.checkoutMade?moment(y.checkoutMade).format("DD MMM YYYY HH:mm") : moment(y.checkoutEstimated).format("DD MMM YYYY"),
  }))
  if(hasCheckin){
    printJS({gridStyle: 'text-align:center;border: 1px solid #d7d7d7;',printable: lista, properties: ['Datos', 'Vehiculos',"Es deudor","Debe", 'Lugares','Fecha De Ingreso','Fecha De Egreso'], type: 'json'})
  }
  if(!hasCheckin){
    printJS({gridStyle: 'text-align:center;border: 1px solid #d7d7d7;',printable: lista, properties: ['Datos', 'Vehiculos','Lugares','Fecha De Ingreso','Fecha De Egreso'], type: 'json'})
  }
}

const withCaja = (componente, dispatch, props) => (
  <div
    className="test-WithCaja"
    style={{ cursor: 'pointer' }}
    onClick={() => {
      dispatch(setModal(true));
      dispatch(setReserva(props.original));
    }}
  >
    {componente}
  </div>
);

const TablaRegistro = ({
  listingLoading,
  pageListPreReservation,
  cancelledReservationList,
  pageListCancelled,
  listaReservas: lista,
  dispatch,
  isMobile,
  withoutPlace,
  stateLabel,
  preReservationList,
  preReservation,
}) => {
  const [mostrarReservas, setMostrarReservas] = useState('alojados');
  const [forceReload, setForceReload] = useState(false);
  const [rangoFechasOpened, setRangoFechasOpened] = useState(false);
  const [openMasiveCheckout, setOpenMasiveCheckout] = useState(false);
  const [massiveCheckoutList, setMassiveCheckoutList] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [cantidadDias, setCantidadDias] = useState(null);
  const [listaSeleccionada, setListaSeleccionada] = useState([]);
  const [numberoLista, setNumeroLista] = useState({});
  const startRef = useRef();
  const endRef = useRef();

  useEffect(() => {
    if (stateLabel != '') {
      setMostrarReservas(stateLabel);
    }
  }, [stateLabel]);

  useEffect(() => {
    let listaAux = [];
    if (mostrarReservas == 'fllegar') {
      listaAux = lista.sort((a, b) =>
        moment(a.checkinEstimated).diff(moment(b.checkinEstimated))
      );
    } else {
      listaAux = lista.sort((a, b) =>
        moment(b.created).diff(moment(a.created))
      );
    }
    const alojados = lista.filter((x) => x.state == 'checkin');
    const adeudados = alojados.filter((x) => x.isDeudor);
    const fllegar = lista.filter((x) => x.state == 'confirmada');
    const nopresentado = lista.filter((x) => x.state == 'nopresentado');
    const imprimir = lista.filter((x) => x.state == 'checkout');
    const abiertas = lista.filter((x) => x.state == 'abierta');
    const sinlugar = lista.filter(
      (x) => x.roomsList.length == 0 && x.state == 'checkin'
    );
    setNumeroLista({
      alojados: alojados.length,
      fllegar: fllegar.length,
      sinlugar: sinlugar.length,
      abiertas: abiertas.length,
      imprimir: imprimir.length,
      nopresentado: nopresentado.length,
      preReservationList: preReservationList.length,
      adeudados: adeudados.length,
      cancelledReservationList: cancelledReservationList.length,
    });
    switch (mostrarReservas) {
      case 'alojados':
        listaAux = alojados;
        break;
      case 'adeudados':
        listaAux = adeudados;
        break;
      case 'fllegar':
        listaAux = fllegar;
        break;
      case 'imprimir':
        listaAux = imprimir;
        break;
      case 'abiertas':
        listaAux = abiertas;
        break;
      case 'sinlugar':
        listaAux = sinlugar;
        break;
      case 'nopresentado':
        listaAux = nopresentado;
        break;
      case 'canceladas':
        listaAux = cancelledReservationList.sort((a, b) => moment(b.created).diff(moment(a.created)));
        break;
      case 'preingreso':
        listaAux = preReservationList.sort((a, b) => moment(b.created).diff(moment(a.created)));
        break;
      default:
        listaAux = lista;
        break;
    }
    if (mostrarReservas == 'imprimir' || mostrarReservas == 'todas') {
      if (fechaInicio) {
        if (mostrarReservas == 'imprimir') {
          listaAux = listaAux.filter(
            (x) =>
              moment(x.checkoutMade).format('YYYY-MM-DD') ==
              moment().format('YYYY-MM-DD')
          );
        } else if (mostrarReservas == 'todas') {
          listaAux = listaAux.filter((x) => {
            const listaDias = new Array(
              moment(x.checkoutEstimated).diff(x.checkinEstimated, 'days')
            )
              .fill(undefined)
              .map((y, i) =>
                moment(x.checkinEstimated)
                  .add(i, 'days')
                  .format('YYYY-MM-DD')
              );
            return (
              listaDias.filter((y) =>
                moment(y).isBetween(
                  moment(fechaInicio).format('YYYY-MM-DD'),
                  moment(fechaInicio).add(cantidadDias, 'days'),
                  null,
                  '[]'
                )
              ).length != 0
            );
          });
        }
      }
    }
    setListaSeleccionada(listaAux);
  }, [mostrarReservas, lista, preReservationList, cancelledReservationList]);

  useEffect(() => {
    if (mostrarReservas == 'imprimir') {
      const listaAux = lista
        .filter((x) => x.state == 'checkout')
        .filter(
          (x) =>
            moment(x.checkoutMade).isBetween(
              moment(fechaInicio).format('YYYY-MM-DD'),
              moment(fechaInicio)
                .add(cantidadDias, 'days')
                .format('YYYY-MM-DD')
            ),
          null,
          '[]'
        );
      setListaSeleccionada(listaAux);
    }
  }, [mostrarReservas, fechaInicio, cantidadDias]);

  const timeFormat = isMobile ? 'DD/MM' : 'DD/MM/YYYY';

  const showingReservations = mostrarReservas == 'fllegar' || mostrarReservas == 'nopresentado' ||mostrarReservas == 'abiertas' || mostrarReservas == 'preingreso' || mostrarReservas == 'canceladas' || mostrarReservas == 'todas' || mostrarReservas == 'imprimir';

  var columns = [
    {
      Header: 'Creada',
      accessor: 'created',
      width: isMobile ? 80 : showingReservations?130:80,
      sortMethod: (a, b) => {
        if (moment(a).diff(moment(b), 'minutes') > 0) {
          return 1;
        } else {
          return -1;
        }
      },
      Cell: (props) =>
        withCaja(
          isMobile
            ? moment(props.value)
                .format(timeFormat)
                .split('.')
                .join('')
            : moment(props.value)
                .format(showingReservations?'DD MMM hh:mm a':"DD MMM")
                .split('.')
                .join(''),
          dispatch,
          props
        ),
      filterable: false,
    },
    
    {
      Header: "Entrada",
      accessor: 'checkinEstimated',
      width: showingReservations?80:130,
      sortMethod: (a, b) => {
        if (moment(a).diff(moment(b), 'minutes') > 0) {
          return 1;
        } else {
          return -1;
        }
      },
      Cell: (props) =>
        withCaja(
          isMobile
            ? moment(showingReservations?props.value:props.original.checkinMade)
                .format(timeFormat)
                .split('.')
                .join('')
            : moment(showingReservations?props.value:props.original.checkinMade)
                .format(showingReservations?'DD MMM':"DD MMM hh:mm a")
                .split('.')
                .join(''),
          dispatch,
          props
        ),
      filterable: false,
    },
    /*{
        Header: isMobile?'Ingreso':'Fecha Ingreso',
        accessor: 'checkinEstimated',
        
        sortMethod:(a, b, desc) => {
            if (moment(a).diff(moment(b),"minutes") > 0) {
              return 1
            }
            else{
                return -1
            }
          
          },
        Cell: props => withCaja(isMobile?moment(props.value).format(timeFormat).split('.').join(""):moment(props.value).format(timeFormat).split('.').join(""),dispatch,props),
        filterable: false
          },*/ {
      Header: isMobile ? 'Apellido' : 'Nombre y Apellido',
      id: 'fullName',
      accessor: (d) => d.customersList,
      Cell: (props) => {
        const values = props.value.map((x, i) =>
          withCaja(
            <div
              style={{
                textDecorationThickness: 2,
                textDecorationColor: '#88b2ff',
                textDecorationLine:
                  (nameSearched.length > 1 &&
                    x.fullName?.toLowerCase().includes(nameSearched)) ||
                  (dniSearched.length > 1 &&
                    x.dni?.toLowerCase().includes(dniSearched.toString()))
                    ? 'underline'
                    : '',
              }}
            >
              {isMobile ? x.fullName.split(' ')[0] : x.fullName}
              {i == 0 && props.original.description && (
                <Label
                  key={i}
                  size={'small'}
                  style={{ marginLeft: '5px' }}
                  color="red"
                >
                  !
                </Label>
              )}
            </div>,
            dispatch,
            props
          )
        );
        return values;

      },
      //withCaja(isMobile?props.value.split(" ")[0]:props.value,dispatch,props)}
      filterMethod: (filter, rows) => {
        nameSearched = filter.value.toLowerCase();
          return rows.filter((x) =>
            x._original.customersList.find((y) =>
              y.fullName.toLowerCase().includes(filter.value.toLowerCase())
            )
          );
      },
      filterAll: true, // String-based value accessors!
    },
    {
      Header: 'Noches',
      accessor: 'nights',
      width: isMobile ? 70 : 80,
      Cell: (props) => withCaja(props.value, dispatch, props),
      filterMethod: (filter, rows) =>
        rows.filter((x) => x.nights == filter.value),
      filterAll: true, // String-based value accessors!
    },
    {
      Header: 'Estado',
      accessor: 'state',
      width: 110,
      Cell: (props) =>
        withCaja(
          ElegirLabel(props.value, props.original.nightsDue),
          dispatch,
          props
        ),
      filterMethod: (filter, rows) =>
        rows.filter((x) =>
          x.state.toLowerCase().includes(filter.value.toLowerCase())
        ),
      filterAll: true, // String-based value accessors!
    },
    {
      Header: 'Pagado',
      accessor: 'percentPayed',
      width: isMobile ? 70 : 100,
      Cell: (props) => withCaja(props.value + '%', dispatch, props),
      filterMethod: (filter, rows) =>
        rows.filter((x) => x.percentPayed == filter.value),
      filterAll: true, // String-based value accessors!
    },
    {
      Header: 'DNI/PAS',
      id: 'dni',
      accessor: (d) => d.customersList,
      Cell: (props) => {
        const values = props.value.map((x) => withCaja(x.dni, dispatch, props));
          return values;
      },
      filterMethod: (filter, rows) => {
        dniSearched = filter.value.toLowerCase();
          return rows.filter((x) =>
            x._original.customersList.find(
              (y) =>
                y.dni &&
                y.dni.toLowerCase().includes(filter.value.toLowerCase())
            )
          );
      },
      filterAll: true, // String-based value accessors!
    },
    {
      Header: 'Vehiculos',
      Cell: (props) =>
        props.value.length > 0
          ? props.value
              .map((x) =>
                withCaja(
                  `${x.brand ?? ''} ${x.plate ?? ''} ${x.color ?? ''}`,
                  dispatch,
                  props
                )
              )
              .map((x, i) => <p key={i}>{x}</p>)
          : '-',
      accessor: 'vehiclesList',
      filterMethod: (filter, rows) =>
        rows.filter(
          (x) =>
            x.vehiclesList
              .map((y) => y.plate)
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase()) ||
            x.vehiclesList
              .map((y) => y.brand)
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase()) ||
            x.vehiclesList
              .map((y) => y.color ?? '')
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase())
        ),
      filterAll: true,
    },
    {
      Header: 'Lugar',
      width: 100,
      accessor: 'roomsList',
      Cell: (props) =>
        withCaja(
          props.value.length == 0
            ? '-'
            : props.value.map((x) => x.number).join(' '),
          dispatch,
          props
        ),
      filterMethod: (filter, rows) =>
        rows.filter((x) =>
          x.roomsList
            .map((x) => x.number)
            .join(' ')
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        ),
      filterAll: true,
    },
    {
      Header: 'Salida',
      accessor: 'checkoutEstimated',
      Cell: (props) =>
        withCaja(
          isMobile
            ? moment(props.value)
                .add(3, 'hours')
                .format('DD/MM HH:mm')
            : moment(props.value)
                .add(3, 'hours')
                .format('dddd DD MMM hh:mm a'),
          dispatch,
          props
        ),
      filterable: false,
    },
  ];

  if (
    (mostrarReservas == 'alojados' ||
    mostrarReservas == 'sinlugar' ||
    mostrarReservas == 'adeudados')
    &&!openMasiveCheckout
  ) {
    columns = [
      ...columns,
      {
        Header: 'Checkout',
        accessor: '',
        width: 100,
        Cell: (props) => (
          <Button
            className="checkoutButton"
            onClick={() => {
              //dispatch(setCheckout(props.original.id))
              if (window.confirm('¿Confirmar checkout?')) {
                CambiarEstadoCheckinReserva(
                  props.original.reservationId,
                  'checkout',
                  (loading) => {
                    dispatch({ type: 'MAIN_LOADING', payload: loading });
                  },
                  dispatch
                );
              }
            }}
          >
            <Icon style={{ marginLeft: '5px' }} name="sign-out" />
          </Button>
        ),
        filterable: false,
      },
    ];
  }
  else if(  (mostrarReservas == 'alojados' ||
  mostrarReservas == 'sinlugar' ||
  mostrarReservas == 'adeudados')
  &&openMasiveCheckout){
    columns = [
      ...columns,
      {
        Header: 'Checkout',
        accessor: '',
        Cell: (props) => {
          return (
            <Checkbox
              checked={massiveCheckoutList.includes(props.original.reservationId)}
              onChange={(e, { checked }) => {
                if (checked) {
                  setMassiveCheckoutList([
                    ...massiveCheckoutList,
                    props.original.reservationId,
                  ]);
                } else {
                  setMassiveCheckoutList(
                    massiveCheckoutList.filter(
                      (x) => x != props.original.reservationId
                    )
                  );
                }
              }}
            />
          );
        },
        width: 80,
        maxWidth: 80,
        minWidth: 80,
        show: !isMobile,
        filterable: false,
      },
    ];
  }
  const handleNoPresentadosClick=()=>{
    setRangoFechasOpened(false);
    setFechaInicio('');
    setMostrarReservas('nopresentado');
  }
  const handleAbiertasClick=()=>{
    setRangoFechasOpened(false);
    setFechaInicio('');
    setMostrarReservas('abiertas');
  }
  const handleEgresosClick = () => {
    setRangoFechasOpened(false);
    setFechaInicio('');
    setMostrarReservas('imprimir');
  };

  const handleTodosClick = () => {
    setRangoFechasOpened(false);
    setFechaInicio('');
    setMostrarReservas('todas');
  };
  const isManualTable = mostrarReservas == 'preingreso' || mostrarReservas == 'canceladas'
  const manualPage = mostrarReservas == 'preingreso' ? pageListPreReservation : pageListCancelled;
  return (
    <React.Fragment>
      <Menu pointing={!isMobile} stackable secondary={!isMobile}>
        <Menu.Item
          style={{ justifyContent: 'space-between' }}
          active={mostrarReservas === 'adeudados'}
          onClick={() => {
            setRangoFechasOpened(false);
            setFechaInicio('');
            setMostrarReservas('adeudados');
          }}
        >
          {`Adeudados`}
          <Label color="yellow">{numberoLista.adeudados}</Label>
        </Menu.Item>
        {withoutPlace && (
          <Menu.Item
            style={{ justifyContent: 'space-between' }}
            active={mostrarReservas === 'sinlugar'}
            onClick={() => {
              setRangoFechasOpened(false);
              setFechaInicio('');
              setMostrarReservas('sinlugar');
            }}
          >
            {`Sin Lugar`}
            <Label>{numberoLista.sinlugar}</Label>
          </Menu.Item>
        )}

        <Menu.Item
          style={{ justifyContent: 'space-between' }}
          name={`Alojados (${numberoLista.alojados})`}
          active={mostrarReservas === 'alojados'}
          onClick={() => {
            setRangoFechasOpened(false);
            setFechaInicio('');
            setMostrarReservas('alojados');
          }}
        >
          {`Alojados`}
          <Label color="green">{numberoLista.alojados}</Label>
        </Menu.Item>
        <Menu.Item
          style={{ justifyContent: 'space-between' }}
          active={mostrarReservas === 'fllegar'}
          onClick={() => {
            setRangoFechasOpened(false);
            setFechaInicio('');
            setMostrarReservas('fllegar');
          }}
        >
          {`Reservas`}
          <Label color="blue">{numberoLista.fllegar}</Label>
        </Menu.Item>
        <Menu.Item
          style={{ justifyContent: 'space-between' }}
          active={mostrarReservas === 'canceladas'}
          onClick={() => {
            setRangoFechasOpened(false);
            setFechaInicio('');
            setMostrarReservas('canceladas');
          }}
        >
          {`Canceladas`}
          <Label color="red">  {numberoLista.cancelledReservationList >= 19
                ? `+20`
                : numberoLista.cancelledReservationList}</Label>
        </Menu.Item>
        {/* <Menu.Item
          style={{ justifyContent: 'space-between' }}
          active={mostrarReservas === 'abiertas'}
          onClick={() => {
            setRangoFechasOpened(false);
            setFechaInicio('');
            setMostrarReservas('abiertas');
          }}
        >
          {`Abiertas`}
          <Label color="purple">{numberoLista.abiertas}</Label>
        </Menu.Item> */}
        {/* <Menu.Item
          style={{ justifyContent: 'space-between' }}
          active={mostrarReservas === 'nopresentado'}
          onClick={() => {
            setRangoFechasOpened(false);
            setFechaInicio('');
            setMostrarReservas('nopresentado');
          }}
        >
          {`No presentado`}
          <Label color="orange">{numberoLista.nopresentado}</Label>
        </Menu.Item> */}

        {preReservation && (
          <Menu.Item
            style={{ justifyContent: 'space-between' }}
            active={mostrarReservas === 'preingreso'}
            onClick={() => {
              setRangoFechasOpened(false);
              setFechaInicio('');
              setMostrarReservas('preingreso');
            }}
          >
            {`Pre Ingresos`}
            <Label color={'pink'}>
              {numberoLista.preReservationList >= 19
                ? `+20`
                : numberoLista.preReservationList}
            </Label>
          </Menu.Item>
        )}

        {/* <Menu.Item
          name="Egresos"
          active={mostrarReservas === 'imprimir'}
          onClick={() => {
            setRangoFechasOpened(false);
            setFechaInicio('');
            setMostrarReservas('imprimir');
          }}
        />

        <Menu.Item
          name="Todos"
          active={mostrarReservas === 'todas'}
          onClick={() => {
            setRangoFechasOpened(false);
            setFechaInicio('');
            setMostrarReservas('todas');
          }}
          
        /> */}
        <Menu.Item>
          <Dropdown text='Más'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleNoPresentadosClick} text={<div style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr", alignItems:"center" ,gap:"10px"}}> <span style={{gridColumn:"1/4"}}>No presentados</span><Label size='tiny' color="orange">{numberoLista.nopresentado}</Label></div>} />
              <Dropdown.Item onClick={handleAbiertasClick} text={<div style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr", alignItems:"center" ,gap:"10px"}}> <span style={{gridColumn:"1/4"}}>Abiertas</span><Label size='tiny' color="purple">{numberoLista.abiertas}</Label></div>} />
              <Dropdown.Item onClick={handleEgresosClick} text={<div style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr", alignItems:"center" ,gap:"10px"}}> <span style={{gridColumn:"1/4"}}>Egresos</span></div>} />
              <Dropdown.Item onClick={handleTodosClick} text={<div style={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr", alignItems:"center" ,gap:"10px"}}> <span style={{gridColumn:"1/4"}}>Todos</span></div>} />

              {/* <Dropdown.Item text='Open...' description='ctrl + o' />
              <Dropdown.Item text='Save as...' description='ctrl + s' />
              <Dropdown.Item text='Rename' description='ctrl + r' />
              <Dropdown.Item text='Make a copy' />
              <Dropdown.Item icon='folder' text='Move to folder' />
              <Dropdown.Item icon='trash' text='Move to trash' /> */}
              {/* <Dropdown.Divider />
              <Dropdown.Item text='Download As...' />
              <Dropdown.Item text='Publish To Web' />
              <Dropdown.Item text='E-mail Collaborators' /> */}
            </Dropdown.Menu>
        </Dropdown>
        </Menu.Item>
        {!openMasiveCheckout&&<Menu.Item
          className={'massiveCheckoutButtonAccept'}
          disabled={mostrarReservas !== 'alojados'}
          content={<><Icon name="users"/><Icon name="sign-out" /></>}
          position='right'
          active={false}
          onClick={() => {
            // include all reservations id to massiveCheckoutList
            setMassiveCheckoutList(listaSeleccionada.map(reservation => reservation.reservationId));
            setOpenMasiveCheckout(true);
          }}
        />}
    {openMasiveCheckout&&<Menu.Item
          className={'massiveCheckoutButtonCancel'}
          disabled={mostrarReservas !== 'alojados'}
          content={<><Icon name="cancel"/><Icon name="sign-out" /></>}
          position='right'
          active={false}
          onClick={() => {
            setOpenMasiveCheckout(false);
            setMassiveCheckoutList([]);
          }}
        />}
    {openMasiveCheckout&&<Menu.Item
          className={'checkoutButton'}
          disabled={mostrarReservas !== 'alojados'}
          content={<><Icon name="users"/><Icon name="sign-out" /></>}
          active={false}
          onClick={() => {
            if (window.confirm('¿Confirmar checkout para las reservas seleccionadas?')) {
              dispatch({type: 'MASSIVE_CHECKOUT', payload: {massiveCheckoutList,dispatch,onComplete:()=>{setOpenMasiveCheckout(false);}}});
            }
          }}
        />}
      {/*Exportar a excel con icono de excel*/}
        <Menu.Item
          className={'descargarListado'}
          style={
            !isMobile
              ? { borderRadius: '5% 5% 0 0%', fontSize: '16x' }
              : { fontSize: '17px',  backgroundColor:"#999", }
          }
          content={<Icon name="print" />}
          onClick={() => {
            printListado(listaSeleccionada, mostrarReservas == 'alojados' || mostrarReservas == 'sinlugar' || mostrarReservas == 'adeudados')
          }}
        />
        {/*Exportar a excel con icono de excel*/}
        <Menu.Item
          className={'descargarListado'}
          style={
            !isMobile
              ? { borderRadius: '5% 5% 0 0%', fontSize: '16x' }
              : { fontSize: '17px' }
          }
          content={<Icon name="download" />}
          onClick={() => {
            //EXPORT listaSeleccionada TO EXCEL
            if (listaSeleccionada.length > 0) {
              let data = listaSeleccionada.map((x) => {
                //Nombre solo toma las primeras dos palabras
                return {
                  Nombre: chooseStarredCustomer(x.customersList, x.starredCustomerId).fullName,
                  Personas: PersonasEnRegistro(x),
                  Estado: `${ElegirTexto(x.state, x.nightsDue)} ${
                    x.nightsDue > 0 ? `$${CalcularDeuda(x)}` : ''
                  }`,
                  Entrada: moment(x.checkinEstimated).format('DD/MM/YYYY'),
                  Vence: moment(x.checkoutEstimated).format('DD/MM/YYYY'),
                  Noches: x.nights,
                  Vehiculos:
                    x.vehiclesList.length > 0
                      ? x.vehiclesList.reduce(
                          (a, y) =>
                            a +
                            `${y.plate ?? ''} ${y.color ?? ''} ${y.brand ??
                              ''}`,
                          ''
                        )
                      : '',
                  Lugares: x.roomsList.map((y) => y.number).join('\n') ?? '-',
                  Notas: x.description,
                };
              });
              //sort data by Lugares
              data = data.sort((a, b) => {
                if (a.Lugares < b.Lugares) return -1;
                if (a.Lugares > b.Lugares) return 1;
                return 0;
              });
              const ws = XLSX.utils.json_to_sheet(data);
              // add borders to all cells
              const range = XLSX.utils.decode_range(ws['!ref']);
              for (let R = range.s.r; R <= range.e.r; ++R) {
                for (let C = range.s.c; C <= range.e.c; ++C) {
                  const cell = ws[XLSX.utils.encode_cell({ c: C, r: R })];
                  cell.s = {
                    border: {
                      top: { style: 'thin', color: { rgb: 'FF000000' }},
                      left: { style: 'thin' , color: { rgb: 'FF000000' }},
                      bottom: { style: 'thin' , color: { rgb: 'FF000000' }},
                      right: { style: 'thin' , color: { rgb: 'FF000000' }}
                    },
                    alignment: { wrapText: true },
                  };
                }
              }

              // make first column bigger
              ws['!cols'] = [
                { wch: 20 },
                { wch: 8 },
                { wch: 15 },
                { wch: 14 },
                { wch: 14 },
                { wch: 7 },
                { wch: 20 },
                { wch: 8 },
                { wch: 30 },
              ];
              const wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
              XLSX.writeFile(
                wb,
                `${mostrarReservas} ${moment().format('DD/MM')}.xlsx`
              );
            }
          }}
        />
      </Menu>
      {false && (
        <Button
          onClick={() => {
            setRangoFechasOpened(!rangoFechasOpened);
          }}
        >
          Rango de fechas
        </Button>
      )}
      <br></br>

      <br></br>
      {rangoFechasOpened && (
        <Collapse isOpened={rangoFechasOpened}>
          <Col xs={12}>
            <DateRangePicker startRef={startRef} endRef={endRef} />
            <Button
              basic
              color="blue"
              size={'big'}
              onClick={() => {
                const inicioFixed = startRef.current.value
                  .split('/')
                  .reverse()
                  .join('-');
                const salidaFixed = endRef.current.value
                  .split('/')
                  .reverse()
                  .join('-');
                setFechaInicio(inicioFixed);
                setCantidadDias(
                  moment(salidaFixed).diff(moment(inicioFixed), 'days')
                );
              }}
            >
              Buscar
            </Button>
          </Col>
        </Collapse>
      )}
      {mostrarReservas != 'imprimir' && (
        <ReactTable
          manual={isManualTable}
          showPageSizeOptions={false}
          pages={isManualTable ? 999 : undefined}
          page={isManualTable ? manualPage : undefined}
          loading={isManualTable ? listingLoading : false}
          showPageJump={false}
          showFilters={false}
          filterable={!isManualTable}
          rowsText={'Filas'}
          nextText={'Siguiente'}
          pageText={'Página'}
          ofText={'de'}
          loadingText={'Cargando...'}
          previousText={'Anterior'}
          onPageChange={(pageIndex) => {
            if (isManualTable) {
              if(mostrarReservas == 'preingreso'){
                dispatch({
                  type: 'RELOAD_PRE_RESERVATION',
                  payload: { page: pageIndex },
                });
              }
              if(mostrarReservas == 'canceladas'){
                dispatch({
                  type: 'RELOAD_CANCELLED_RESERVATION',
                  payload: { page: pageIndex },
                });
              }
            }
          }}
          onFilteredChange={(a, b, c) => {
            if (c.length == 0) {
              nameSearched = '';
              dniSearched = '';
              setForceReload(!forceReload);
            }
          }}
          noDataText={'No se encontraron registros'}
          className="-striped -highlight"
          columns={columns}
          data={listaSeleccionada}
        ></ReactTable>
      )}
      {mostrarReservas == 'imprimir' && <Imprimir />}
    </React.Fragment>
  );
};

export default connect(
  (state) => ({
    listingLoading: state.mainReducer.listingLoading,
    pageListPreReservation: state.mainReducer.pageListPreReservation,
    preReservation: state.mainReducer.preReservation,
    cancelledReservationList: state.mainReducer.cancelledReservationList,
    pageListCancelled: state.mainReducer.pageListCancelled,
    preReservationList: state.mainReducer.preReservationList,
    withoutPlace: state.mainReducer.withoutPlace,
    isMobile: state.mainReducer.isMobile,
  }),
  null
)(TablaRegistro);
