import React,{useState,useEffect} from "react"
import ReactTable from 'react-table-6'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay';
import { Col,Row,Container} from 'reactstrap';
import {AgregarYPagarServicios,PuedeReservar, printPOS, DevolverComponentesParaJSX,ReturnComponentsExtra,CambiarEstadoReserva, DevolverComponentesEnCero, chooseStarredCustomer, CopyTicketXToClipboard, GetListaHorarios} from "../../../Funciones/utils"
import {connect, shallowEqual} from 'react-redux'
import  Pagos  from './pagos'
import { Button,Divider,Icon,Loader} from 'semantic-ui-react'
import Precios from './precios'
import ConfirmarPago from './confirmarPago'


export const Caja = ({togglePrinter,isCopyingTicket,billList,loadingBill,goToFacturacion,isAdmin,billSession,dispatch,reserva:ingreso,isMobile,paymentLoading,calcularPrecio}) => {
  const [componentes,setComponentes]=useState(0)
  const [costo,setCosto]=useState(0)
  const [pagoParcial,setPagoParcial]=useState(0)
  const [loading,setLoading] =useState(false);
  const [service,setService] =useState(null);
  const [fechaIngreso, setFechaIngreso] = useState("");
  const [fechaSalida, setFechaSalida] = useState("");
  const [forma, setForma] = useState("");
  const [nombreCuenta, setNombreCuenta] = useState("");
  const [servicio, setServicio] = useState();
  const [openPago, setOpenPago] = useState(false);
  const [makeBill, setMakeBill] = useState(true);
  const [hora,setHora] = useState("00:00");

    useEffect(()=>{
      setHora(moment(ingreso.checkoutEstimated).add(3, 'hours').format("HH:mm"))
    },[ingreso])

    useEffect(()=>{
      setNombreCuenta(ingreso.nuevaReserva?'':chooseStarredCustomer(ingreso.customersList,ingreso.starredCustomerId)?.fullName)
      if (Boolean(ingreso.components) && ingreso.components!="null"){ 
        const componentesEnCero = DevolverComponentesEnCero()
        setComponentes({...componentesEnCero,...JSON.parse(ingreso.components)})
      }
      else{ 
        const componentesEnCero = DevolverComponentesEnCero()
        setComponentes({...componentesEnCero, ...DevolverComponentesParaJSX(ingreso.vehiclesList,ingreso.customersList,ingreso,ingreso.servicesList)})
      }
    },[ingreso])

    const serviciosLimpios=ingreso.servicesList.filter(x =>!x.canceled);
    const primerPago=serviciosLimpios.length == 0||
    (serviciosLimpios.length == 1&&serviciosLimpios[0].name=="preingreso");

    var paymentColumn = [
    {
    Header: '',
    accessor: 'date',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
   
    Cell: props =>(moment(props.value).format(isMobile?'DD/MM':'DD/MM/YYYY - hh:mm a')),
    filterable: false
      },
    {
    Header: '',
    accessor: 'isRefund',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
  
    filterable: false,
    Cell: props =>(props.value?"Devolucion":"Pago"),
      },
    {
    Header: '',
    accessor: 'amount',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: '',
    accessor: 'method',
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
 
    filterable: false
      },
      {
        Header: '',
        accessor: '',
        style:{fontSize:"1.1rem"},
        headerStyle:{fontSize:"1.2rem"},
        filterable: false,
        Cell: props =>(
        <>
        {isCopyingTicket?<Loader active inline />:
<>
        <div style={{cursor:"pointer"}}>
          <Icon size={"large"} name={"copy outline"} onClick={()=>{
            CopyTicketXToClipboard({paymentsList:[props.original],description:props.original.description,date:props.original.date,payExpirationDateTime:ingreso.checkoutEstimated,reservation:ingreso})
        }}
        /></div>
        </>
        }</>),
      },
    ]
    var serviceColumn = [
    {
    Header: isMobile?'Creada':'Fecha de creacion',
    accessor: 'date',
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
    width:isMobile?70:160,
    Cell: props =>(moment(props.value).format(isMobile?'DD/MM':'DD/MM - HH:mm')),
    filterable: false
      },

    {
    Header: 'Descripcion',
    accessor: 'description',
    width:isMobile?120:340,
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
    filterable: false,
    Cell: props =>(<div style={{textAlign:"center"}}>{props.value}</div>),
      },
    {
    Header: 'Valor',
    accessor: 'cost',
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: 'Saldo',
    accessor: 'due',
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: 'Pagar',
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>(<div style={{cursor:props.original.canceled?"":props.row.due>0?"pointer":""}}><Icon color={props.original.canceled?'grey':'green'} onClick={()=>{if(props.row.due>0&&!props.original.canceled){setService(props.original)}}} size='large' style={{marginLeft:"5px"}} name={props.original.canceled?'minus':props.row.due>0?'plus square':`check`} /></div>),
    filterable: false
      },
      {
        Header: 'Copiar',
        accessor: '',
        style:{fontSize:"1.1rem"},
        headerStyle:{fontSize:"1.2rem"},
        filterable: false,
        width:70,
        Cell: props =>(
        <>
        {isCopyingTicket?<Loader active inline />:
<>
        <div style={{cursor:props.original.paymentsList.length>0?"pointer":""}}>
          <Icon size={"large"} name={props.original.paymentsList.length>0?"copy outline":"minus"} onClick={()=>{
          if (props.original.paymentsList.length>0) {
            CopyTicketXToClipboard({...props.original,payExpirationDateTime:ingreso.checkoutEstimated,reservation:ingreso})
          }    
        }}
        /></div>
        </>
        }</>),
      },
    ]

//Solo los que tienen facturacion
if (billSession.toggleBilling) {
  serviceColumn=[...serviceColumn,    
    {
      Header: 'Facturar',
      style:{fontWeight:"bold",fontSize:"1.2rem"},
      width:80,
      headerStyle:{fontSize:"1.2rem"},
       Cell: props =>{
        const paymentsIdBilled = billList.map(x=>x.listProduct).flat().map(x=>x.paymentId)
        const paymentsIdInService = props.original.paymentsList.map(x=>x.paymentId)
        // all payments in service must be billed
        const isBilled = paymentsIdInService.every(x=>paymentsIdBilled.includes(x))
        return(
        loadingBill?<Loader active inline />:<div style={{cursor:(props.original.paymentsList.length==0||isBilled)?"":"pointer"}}><Icon 
       disabled={props.original.paymentsList.length==0}
       onClick={()=>{
            goToFacturacion();
            dispatch({type:'SET_PAYMENT_TO_BILL',payload:props.original.paymentsList.find(x=>!paymentsIdBilled.includes(x.paymentId))})
        }} size='large' style={{marginLeft:"5px"}} name={isBilled?'check':'file alternate outline'}/></div>)},
      filterable: false
        }]
}
if(togglePrinter){
  serviceColumn.push(
    {
    Header: 'Imprimir',
    accessor: '',
    width:80,
    style:{fontSize:"1.1rem"},
    headerStyle:{fontSize:"1.2rem"},
    Cell: props =>(<div style={{cursor:props.original.paymentsList.length>0?"pointer":""}}><Icon size={"large"} name={props.original.paymentsList.length>0?"print":"minus"} onClick={()=>{
      if (props.original.paymentsList.length>0) {
        const paymentsIdBilled = billList.map(x=>x.listProduct).flat().map(x=>x.paymentId)

        const serviceIdIsBilled = props.original.paymentsList.every(x=>paymentsIdBilled.includes(x.paymentId))
        if(!serviceIdIsBilled){
          printPOS({...props.original,payExpirationDateTime:ingreso.checkoutEstimated,reservation:ingreso},'ticketx')
        }
        else {
          goToFacturacion();

        }      
      
      }
      
    }
    }/></div>),
    filterable: false
})
paymentColumn.push(
  {
  Header: '',
  accessor: '',
  style:{fontSize:"1.1rem"},
  headerStyle:{fontSize:"1.2rem"},
  Cell: props =>(<div style={{cursor:"pointer"}}><Icon size={"large"} name={"print"} onClick={()=>{
    const paymentsIdBilled = billList.map(x=>x.listProduct).flat().map(x=>x.paymentId)

    const paymentIdIsBilled = paymentsIdBilled.includes(props.original.paymentId)
    if(!paymentIdIsBilled){
      printPOS({paymentsList:[props.original],description:props.original.description,date:props.original.date,payExpirationDateTime:ingreso.checkoutEstimated,reservation:ingreso},'ticketx')
    }
    else {
      goToFacturacion();

    }  }
  }/></div>),
  filterable: false
}
)
}


//Solo los superUser pueden cancelar pagos
if (isAdmin) {
  serviceColumn=[...serviceColumn,    
    {
    Header: 'Cancelar',
    width:80,
    style:{fontWeight:"bold",fontSize:"1.2rem"},
    headerStyle:{fontSize:"1.2rem"},
     Cell: props =>(<div style={{cursor:"pointer"}}><Icon color='red' onClick={()=>{
       if(window.confirm(`¿Seguro que deseas ${props.original.canceled?"reactivar":"cancelar"} el pago con valor ${props.row.cost}?`))
          {dispatch({type:'CANCEL_SERVICE',payload:{reservationId:ingreso.reservationId,serviceId:props.original.serviceId,isCanceled:!props.original.canceled}})
        }}} size='large' style={{marginLeft:"5px"}} name='x'/></div>),
    filterable: false
      }]
}

const quantity=servicio?servicio.quantity:1;
const componentesExtra=ReturnComponentsExtra(componentes)
const mostrarComponentesExtra=ingreso&&!ingreso.nuevaReserva&&componentesExtra&&Object.keys(componentesExtra).length!=0;
const mostrarExtraGuardar=ingreso.components&&!shallowEqual(JSON.parse(ingreso.components),componentes)
const listaHorarios = GetListaHorarios()
const checkoutTimeHasChanged = moment(ingreso.checkoutEstimated).add(3,"hours").format('HH:mm') != hora;
const Guardar=async (setLoadingAux)=>{
    const newCheckoutTime = moment(ingreso.checkoutEstimated).set({hour: parseInt(hora), minute: 0, second: 0, millisecond: 0 }).add(-3, 'hours').format()
    const reservaAux={...ingreso,checkoutEstimated:newCheckoutTime}
    await CambiarEstadoReserva(reservaAux,setLoadingAux,()=>{},dispatch,JSON.stringify(componentes))
}

const handleOpenPago=()=>{
  setOpenPago(true);
  setPagoParcial(costo);
}

 return (
    <React.Fragment>
      <LoadingOverlay
      active={loading||paymentLoading}
      spinner
      text='Agregando'
      >
      <ConfirmarPago 
      hora={hora}
      makeBill={makeBill}
      pagoParcial={pagoParcial}
      setPagoParcial={setPagoParcial}
      setMakeBill={setMakeBill}
      customersList={ingreso.customersList?ingreso.customersList.filter(x=>x.dni):[]}
      onPay={async()=>{
          const fSalidaAux=`${fechaSalida}T${ingreso.checkoutEstimated.split('T')[1]}`;
          const puedeReservar=PuedeReservar(ingreso.checkinEstimated,fSalidaAux,ingreso);
          if (pagoParcial!=0 && forma=="") {
            alert("falta agregar forma de pago")
          }
          else if(puedeReservar.resultado || (!puedeReservar.resultado&&window.confirm(`Ya hay un registro en el lugar ${puedeReservar.habitacion.number} para la fecha\n¿Desea continuar de todos modos?`))){
            setLoading(true)
            let quantityAux=(primerPago&&(serviciosLimpios.length == 1&&serviciosLimpios[0].name=="preingreso"))?ingreso.nights-servicio.quantity:servicio.quantity;
            if (quantityAux<0) {
              quantityAux=-quantityAux;
            }
            const AgregarYPagarServiciosFn = AgregarYPagarServicios.bind(
              this,
              ingreso.reservationId,
              servicio,
              dispatch,
              setLoading,
              quantityAux,
              pagoParcial!=0?{ amount: pagoParcial, isRefund: false, date: moment().format(), method: forma }:null,
              false
            );
              
            if (checkoutTimeHasChanged) {
              await Guardar(() => {});
              setTimeout(AgregarYPagarServiciosFn, 800);
            } else {
              AgregarYPagarServiciosFn();
            }

            setOpenPago(false)
          }
      }} loading={loading} setForma={setForma} isMobile={isMobile} precio={costo} open={openPago} setOpen={setOpenPago} nombreCuenta={nombreCuenta} fechaSalida={fechaSalida} noches={quantity} forma={forma} descripcion={servicio?.description} />
          
          <Pagos setService={setService} service={service} loading={loading} setLoading={setLoading} />

          <Precios
          hora={hora}
          setHora={setHora}
          listaHorarios={listaHorarios}
          vistaCompleta={true}
          calcularPrecio={calcularPrecio}
          componentesExtra={componentesExtra}
          mostrarComponentesExtra={mostrarComponentesExtra}
          mostrarExtraGuardar={mostrarExtraGuardar}
          fechaIngreso={fechaIngreso} setFechaIngreso={setFechaIngreso}
          servicesList={ingreso.servicesList} ingreso={ingreso} componentes={componentes} setComponentes={setComponentes} setFechaSalida={setFechaSalida} setServicio={setServicio} costo={costo} setCosto={setCosto}/>

          {!calcularPrecio&&<>          
          <br></br>
          <Container>
            <Row>
              <Col style={{display: "contents"}}>
                <Button  style={{width:"68%", margin:"auto"}}  size={isMobile?'massive':'big'} color='teal' fluid onClick={handleOpenPago}>{`${quantity==0?'Pasar el dia':`Renovar ${quantity} ${(quantity == 1) ? "noche" : "noches"}`} $${costo}`}</Button>
              </Col>
            </Row>
           </Container>
           <br></br>

            <ReactTable
              rowsText={"Filas"}
              nextText={"Siguiente"}
              pageText={"Página"}
              ofText={"de"}
              previousText={"Anterior"}
             getTrProps={(state, rowInfo) => {  
                if (rowInfo) {
                  return {
                    style: {
                      textDecoration: rowInfo.original.canceled? 'line-through' : ''
                          }
                        }
                 }
                else{
                    return{}
                  }
              }} 
            defaultPageSize={5}
            noDataText={'No hay pagos'} 
            className="-striped -highlight" 
            filterable 
            loadingText={"Cargando..."} 
            
            loading={false} columns={serviceColumn} 
            SubComponent={row => {
              
              return (
                <ReactTable 
                rowsText={"Filas"}
                nextText={"Siguiente"}
                pageText={"Página"}
                ofText={"de"}
                previousText={"Anterior"}
                defaultPageSize={7}
                noDataText={'No hay pagos'} 
                className="-striped -highlight" 
                filterable 
                showPagination= {false}
                loadingText={"Cargando..."}
                loading={false} columns={paymentColumn}
                data={row.original.paymentsList.sort(function(a, b){return moment(b.date).diff(moment(a.date))}).map(x=>({...x,description:row.original.description}))} />
              )
            }}
            data={ingreso.servicesList.filter(x=>x.name!="preingreso").sort(function(a, b){return moment(b.date).diff(moment(a.date))})} />
            <br/>
            <Row>
              {/* <Col xs={4} >
                <h3 style={{textAlign:"center"}}>Noches pagas</h3>
              </Col>
             <Col xs={8}>
                <h3>{ingreso.nochesPagas}</h3>
          </Col>*/}
            </Row>
            <Row>
              <Col xs={4} >
                <h3>Total abonado ${ingreso.servicesList.filter(x=>!x.canceled).map(x=>x.paid).reduce((a,b) => a + parseInt(b), 0)}</h3>
              </Col>
              <Col xs={8}>
                <h4></h4>
              </Col>
            </Row>
        
            <Row>
              {/*<Col xs={4} >
                <h4 style={{textAlign:"center"}}>Total Cta corriente</h4>
            </Col>
              <Col xs={8}>
                <h4>${ingreso.listaPagos.filter(x=>x.condicion=="Cta/cte").map(x=>x.importe).reduce((a,b) => a + b, 0)}</h4>
              </Col>*/}
            </Row></>}
            </LoadingOverlay>
      </React.Fragment>
   
    )
}



export default connect(state=>({
  loadingBill:state.billReducer.loadingBill,
  billList:state.billReducer.billList,
  printTicket:state.printerReducer.printer?.printTicket,
  togglePrinter:state.printerReducer.togglePrinter,
  billSession:state.billReducer,
  isAdmin:state.mainReducer.isAdmin,
  tipoPrecio:state.mainReducer.tipoPrecio,
  session:state.mainReducer.session,
  paymentLoading:state.mainReducer.paymentLoading,
  listaDeptos:state.mainReducer.listaDeptos,
  refrescarDispoYPrecios:state.mainReducer.refrescarDispoYPrecios,
  precios:state.mainReducer.precios,
  isCopyingTicket:state.printerReducer.isCopyingTicket,
  isMobile:state.mainReducer.isMobile}),null)(Caja)

