import React from "react"
import moment from "moment"
import * as htmlToImage from 'html-to-image';
import store from "../redux/store.js"
import { Label } from "semantic-ui-react"
import { API, graphqlOperation } from "aws-amplify"
import { NotificationManager } from 'react-notifications';
import QRCode from 'qrcode'
import {
  reservationList,
  roomsList,
  getPrices,
  getMapInfo,
  getPaymentList,
  getBillList
} from "../graphql/queries"
import {
  addService,
  addRoom,
  modifyRoom,
  modifyPrices,
  modifyReservation,
} from "../graphql/mutations"

import listaIngresosAction from "../redux/actions/listaIngresos"
import { ArreglarServicesList, GetPercentPayed } from "../redux/sagas"
import GetCard from "../components/dormis/main/getCard"
import {
  CheckinSignature,
  TicketFacturaB,
  TicketFacturaC,
  TicketFacturaA,
  CheckoutSignature,
  TicketX,
  CleaningReportInAndOut,
  CleaningReport,
  CashWithdrawReport
} from "./printerTemplates.js"
import qz from "qz-tray"
import { v4 as uuidv4 } from 'uuid';
import { Select } from 'semantic-ui-react'

//ACTIONS
import setReserva from "../redux/actions/setReserva"
import refrescarPrecioYDispo from "../redux/actions/refrescarPrecioYDispo"
import setMapInfo from "../redux/actions/setMapInfo"
import setPrecios from "../redux/actions/setPrecios"
let imagenMap={};

export const ActualizarTabla = (setLoading, dispatch) => {
  // Actualiza el título del documento usando la API del navegador
  API.graphql({
    query: reservationList,
    variables: { tipo: "camping" },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })
    .then(res => {
      setLoading(false)
      const lista = res.data.reservationList
      dispatch(listaIngresosAction(lista))
    })
    .catch(x => {
      setLoading(false)
    })
}

export const ParseScan = str => {
  try {
    const obj = str
      .split("@")
      .join('"')
      .split('"')
    if (obj.length > 11) {
      return {
        fullName: `${obj[5].trim()} ${obj[4].trim()}`,
        sex: obj[8].trim(),
        dni: obj[1].trim(),
        bday: obj[7].trim(),
      }
    }
    return {
      fullName: `${obj[2].trim()} ${obj[1].trim()}`,
      sex: obj[3].trim(),
      dni: obj[4].trim(),
      bday: obj[6].trim(),
    }
  } catch (error) {
    //Cuando tira error parseamos de distinta forma
    const obj = str
      .split("@")
      .join('"')
      .split('"')[0]
      .split(" ")
    let fullName = ""
    let sex = ""
    let foundSex = false
    let bday = ""
    let dni = ""
    let acom = 0
    for (let item of obj) {
      acom++
      if (acom == 5) {
        dni = item
      }
      if (item == "F" || item == "M") {
        sex = item
        foundSex = true
        continue
      }
      if (!foundSex) {
        fullName = fullName + item + " "
      } else {
        if (item.split("/").length == 3) {
          bday = item
          break
        }
      }
    }
    fullName = fullName.trim()
    return { fullName, sex, bday, dni }
  }
}
export const ParseScanPassport = str => {
  const fullName = str.lastName + " " + str.firstName
  const sex = str.sex
  const dni = str.documentNumber.split(".").join("")
  const bday =
    (str.dateOfBirth.year < 1901
      ? str.dateOfBirth.year + 100
      : str.dateOfBirth.year) +
    "-" +
    (str.dateOfBirth.month < 10
      ? "0" + str.dateOfBirth.month
      : str.dateOfBirth.month) +
    "-" +
    (str.dateOfBirth.day < 10 ? "0" + str.dateOfBirth.day : str.dateOfBirth.day)
  return { fullName, sex, dni, bday }
}

export const ActualizarListaReservas = async (setLoading, dispatch) => {
  const { tipoPrecio } = store.getState().mainReducer

  // Actualiza el título del documento usando la API del navegador
  try {
    const response = await API.graphql({
      query: reservationList,
      variables: { type: tipoPrecio },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })

    dispatch({ type: "LISTA_RESERVAS", payload: response.data.reservationList })
    setLoading(false)
  } catch (error) {
    console.log(error)
  }
}

export const ActualizarMapInfo = async dispatch => {
  const response = await API.graphql({
    query: getMapInfo,
    variables: {},
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })
  dispatch(setMapInfo(JSON.parse(response.data.getMapInfo)))
}

export const ActualizarListaDeptos = async (setLoading, dispatch) => {
  // Actualiza el título del documento usando la API del navegador
  const response = await API.graphql({
    query: roomsList,
    variables: {},
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })
  const listaDeptos = response.data.roomsList
  dispatch({ type: "SET_LISTA_DEPTOS", payload: listaDeptos })

  const responseReservas = await API.graphql({
    query: reservationList,
    variables: { type: "departamento" },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })

  const listaReservas = responseReservas.data.reservationList
  listaReservas.forEach(x => {
    x.roomsList = x.roomsList.map(y => {
      if (y.roomId) {
        return listaDeptos.find(x => x.roomId == y.roomId)
      }
      return listaDeptos.find(x => x.roomId == y)
    })
  })
  dispatch({ type: "" })
  setLoading(false)
}

const addUUID = (reserva) => {
  const reservaAux={...reserva}
  if(!reservaAux.reservationId){
    reservaAux.reservationId=uuidv4()
  }
  for (let service of reservaAux.servicesList) {
    if (!service.serviceId) {
    service.serviceId = uuidv4()
    service.date = new Date().toISOString()
    }
    for (let payment of service.paymentsList) {
      if (!payment.paymentId) {
        payment.paymentId = uuidv4()
        payment.date = new Date().toISOString()
      }
    }
  }

  for (let vehicle of reservaAux.vehiclesList) {
    if (!vehicle.vehicleId) {
      vehicle.vehicleId = uuidv4()
    }
  }
  
  return reservaAux
}

export const MandarFormularioDepto = async (
  reservation,
  setLoading
) => {
  delete reservation.nights
  delete reservation.nightsDue
  const { tipoPrecio } = store.getState().mainReducer
  reservation.type = tipoPrecio
  const dispatch = store.dispatch
  const reservationWithUUID =  addUUID(reservation)

    try {
      await dispatch({ type: "ADD_RESERVATION", payload: reservationWithUUID })
      setLoading(false)
        // if (nuevaReserva) {
        //   if (billSession.toggleBilling && makeBill) {
        //     await dispatch({
        //       type: "SET_PAYMENT_TO_BILL_NOW",
        //       payload: nuevoIngreso.servicesList[0].paymentsList[0],
        //     })
        //   }
        //   if (printer.isConnected) {
        //     (async () => {
        //       if (printer.printCheckin) {
        //         await printPOS(nuevoIngreso, "checkin")
        //       }
        //       if (printer.printCheckout) {
        //         await printPOS(nuevoIngreso, "checkout")
        //       }
        //       if (printer.printTicket && !billSession.toggleBilling) {
        //         await printPOS({...nuevoIngreso.servicesList[0],payExpirationDateTime:nuevoIngreso.checkoutEstimated,reservation:nuevoIngreso}, "ticketx")
        //       }
        //     })()
        //   }
        //   onComplete()
        // }
    } catch (error) {
      alert("No se pudo cargar el ingreso. Por favor vuelve a intentarlo")
      setLoading(false)
    }

}



export const AgregarNochesReserva = async (reservation, noches) => {
  const reservationAux = JSON.parse(JSON.stringify(reservation))
  reservationAux.checkoutEstimated = CambiarEstadoReserva(
    reservationAux,
    () => {},
    () => {},
    () => {},
    null
  )
}

export const GetListaHorarios = () => {
  let listaHorarios = ""
  for (let y of [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ]) {
    listaHorarios = [...listaHorarios, `${y}:00`]
  }
  return listaHorarios
}

export const SacarDeLista = async (lista, setLista, valor) => {
  if (
    window.confirm(
      `¿Seguro desea eliminar el lugar ${valor.number} de este registro?`
    )
  ) {
    setLista(lista.filter(x => x != valor))
  }
}
export const CalcularDeuda = ingreso => {
  let deuda = ingreso.servicesList
    .filter(x => x.due != 0 && !x.canceled)
    .map(x => x.due)
    .reduce((a, b) => a + b, 0)
  if (deuda > 0) {
    return deuda
  } else if (
    ingreso.nightsDue > 0 &&
    ingreso.servicesList.filter(x => !x.canceled).length > 0
  ) {
    return (
      ingreso.servicesList.filter(x => !x.canceled)[0].cost * ingreso.nightsDue
    )
  } else {
    return 0
  }
}

export const AgregarServicios = async (
  reservationId,
  serviceAux,
  dispatch,
  setLoading,
  agregarNoches,
  setService = () => {}
) => {
  try {
    const reservaState = store.getState().mainReducer.reserva
    serviceAux.paymentsList = []
    const response = await API.graphql(
      graphqlOperation(addService, {
        reservationId,
        service: serviceAux,
        addDays: agregarNoches,
      })
    )
    const listaReservas = JSON.parse(
      JSON.stringify(store.getState().mainReducer.listaGeneralReservas)
    )
    const service = response.data.addService
    reservaState.servicesList = [...reservaState.servicesList, service]
    reservaState.servicesList = ArreglarServicesList(reservaState.servicesList)
    reservaState.checkoutEstimated = moment(reservaState.checkoutEstimated)
      .add(agregarNoches, "days")
      .format()
    if (reservaState.state == "preingreso") {
      const preReservationList = store.getState().mainReducer.preReservationList
      if (
        preReservationList.find(
          x => x.reservationId == reservaState.reservationId
        )
      ) {
        await dispatch({
          type: "PRE_RESERVATION_LIST",
          payload: [
            ...preReservationList.filter(
              x => x.reservationId != reservaState.reservationId
            ),
            reservaState,
          ],
        })
      }
    }
    await dispatch({
      type: "LISTA_RESERVAS",
      payload: [
        ...listaReservas.filter(
          x => x.reservationId != reservaState.reservationId
        ),
        reservaState,
      ],
    })
    const reserva = store
      .getState()
      .mainReducer.listaGeneralReservas.find(
        x => x.reservationId == reservationId
      )
    await dispatch(setReserva(reserva))
    setLoading(false)
  } catch (error) {
    console.log(error)
    alert("Error inesperado")
    setLoading(false)
  }
}
export const BuscarReserva = reservationId => {
  const reserva = store
    .getState()
    .mainReducer.listaGeneralReservas.find(
      x => x.reservationId == reservationId
    )
  return reserva
}

export const AgregarServicioAReserva = (reservationToModify, newService, newPaymentsList, addDays) => {
    const reservation = {...reservationToModify}
    const service = {...newService}
    service.paymentsList = newPaymentsList
    reservation.servicesList = [...reservation.servicesList, service]
    reservation.servicesList = ArreglarServicesList(reservation.servicesList)
    reservation.percentPayed = GetPercentPayed(reservation.servicesList)
    reservation.checkoutEstimated = moment(reservation.checkoutEstimated)
      .add(addDays, "days")
      .format()
    return reservation;
  }

export const AgregarPagoAReserva = (reservationToModify, serviceId, newPayment) => {
    const reservationAux = {...reservationToModify}
    const service = reservationAux.servicesList.find(x => x.serviceId == serviceId)
    service.paymentsList = [...service.paymentsList, newPayment]
    reservationAux.servicesList = ArreglarServicesList(reservationAux.servicesList)
    reservationAux.percentPayed = GetPercentPayed(reservationAux.servicesList)
    return reservationAux
  }

export const CancelarServicio = (reservation, serviceId, isCanceled) => {
    const reservationAux = {...reservation}
    const service = reservationAux.servicesList.find(x => x.serviceId == serviceId)
    const cantidad = reservationAux.servicesList.find(x => x.serviceId == serviceId).quantity
    reservationAux.checkoutEstimated = moment(
      `${moment(reservationAux.checkoutEstimated)
        .add(isCanceled ? -cantidad : cantidad, "days")
        .format("YYYY-MM-DD")}T${reservationAux.checkoutEstimated.split("T")[1]}`
    ).format()
    service.canceled = isCanceled

    reservationAux.servicesList = ArreglarServicesList(reservationAux.servicesList)
    reservationAux.percentPayed = GetPercentPayed(reservationAux.servicesList)
    return reservationAux
  }

export const AgregarYPagarServicios = async (
  reservationId,
  serviceAux,
  dispatch,
  setLoading,
  agregarNoches,
  paymentAux,
  isPagarLuego,
  newCheckoutTime
) => {
  try {
    serviceAux = isPagarLuego ? serviceAux:{...serviceAux, paymentsList:[]}
    if(newCheckoutTime){
      await modifyReservation(reservationId, {checkoutEstimated: newCheckoutTime})
    }
    await dispatch({
      type: "ADD_SERVICE_TO_RESERVATION",
      payload: {
        reservationId,
        newService: serviceAux,
        newPayment: paymentAux,
        addDays: agregarNoches,
      }})


  } catch (error) {
    console.log(error)
    if (error?.errors && error?.errors[0]?.message == "Network Error") {
      alert("¡Error de internet, vuelve a intentarlo!")
    } else {
      alert("Error inesperado agregando pago")
    }
  }
  setLoading(false)
}

export const AgregarPagos = async (
  serviceId,
  paymentAux,
  dispatch,
  setLoading,
  onComplete
) => {
  try {
    const reservation = store.getState().mainReducer.reserva
    await dispatch({
      type: "ADD_PAYMENT_TO_SERVICE",
      payload: {
        reservationId: reservation.reservationId,
        serviceId,
        newPayment: paymentAux
      }})

    // const payment = response.data.addPayment
    // const listaReservas = JSON.parse(
    //   JSON.stringify(store.getState().mainReducer.listaGeneralReservas)
    // )
    // var reservaAux = {}
    // let reservationId
    // const service = reserva.servicesList.find(x => x.serviceId == serviceId)
    // if (service) {
    //   reservationId = reserva.reservationId
    //   service.paymentsList = [...service.paymentsList, payment]
    //   reserva.servicesList = ArreglarServicesList(reserva.servicesList)
    //   reserva.percentPayed = GetPercentPayed(reserva.servicesList)
    //   reservaAux = JSON.parse(JSON.stringify(reserva))
    // }
    // if (reserva.state != "preingreso") {
    //   await dispatch({
    //     type: "LISTA_RESERVAS",
    //     payload: [
    //       ...listaReservas.filter(
    //         x => reserva.reservationId != x.reservationId
    //       ),
    //       reserva,
    //     ],
    //   })
    // } else {
    //   const preReservationList = JSON.parse(
    //     JSON.stringify(store.getState().mainReducer.preReservationList)
    //   )
    //   if (
    //     preReservationList.find(x => x.reservationId == reserva.reservationId)
    //   ) {
    //     await dispatch({
    //       type: "PRE_RESERVATION_LIST",
    //       payload: [
    //         ...preReservationList.filter(
    //           x => x.reservationId != reserva.reservationId
    //         ),
    //         reserva,
    //       ],
    //     })
    //   }
    // }

    // dispatch(setReserva(MejorarIngreso(reserva)))
    setLoading(false)
    onComplete()
  } catch (error) {
    if (error?.errors && error?.errors[0]?.message == "Network Error") {
      alert("¡Error de internet, vuelve a intentarlo!")
    } else {
      alert(
        "Habia otro pago identico anteriormente, no se pudo cargar este, actualice la aplicacion para verlo reflejado"
      )
    }
    setLoading(false)
  }
}

export const ListaFechas = (fechaInicio, fechaFin, everyXTime = 1) => {
  let lista = []
  const noches = moment(fechaFin).diff(fechaInicio, "days") + 1
  for (let index = 0; index < noches / everyXTime; index++) {
    lista = [
      ...lista,
      moment(fechaInicio)
        .add(index * everyXTime, "days")
        .format("YYYY-MM-DD"),
    ]
  }
  return lista
}

export const CambiarEstadoCheckinReserva = async (
  reservationId,
  estado,
  setLoading,
  dispatch
) => {
  const reservation = store
    .getState()
    .mainReducer.listaReservas.find(x => x.reservationId == reservationId)
  console.log(reservation)
  switch (estado) {
    case "checkin":
      reservation.checkinMade = moment().format()
      reservation.checkoutMade = null
      reservation.state = "checkin"
      break
    case "checkout":
      reservation.checkoutMade = moment().format()
      reservation.state = "checkout"
      break
    case "confirmada":
      reservation.state = "confirmada"
      reservation.checkoutMade = null
      reservation.checkinMade = null
      break
    case "cancelada":
      reservation.state = "cancelada"
      break
  }
  await CambiarEstadoReserva(reservation, setLoading, () => {}, dispatch)
}

export const CambiarEstadoReserva = async (
  ingreso,
  setLoading,
  onComplete,
  dispatch,
  components = null) => {
  const { listaReservas, preReservationList } = store.getState().mainReducer
  const reservationFound = [...listaReservas,...preReservationList].find(
    x => x.reservationId == ingreso.reservationId
  )
  const {
    nights,
    created,
    percentPayed,
    isDeudor,
    otaId,
    nightsDue,
    ...ingresoAux
  } = ingreso
  ingresoAux.customersList = ingresoAux.customersList.map(x => {const { age, ...aux } = x;return aux})
  ingresoAux.servicesList = []
  ingresoAux.components = components || reservationFound?.components
  ingreso.components = components || reservationFound?.components

  await dispatch({type: "MODIFY_RESERVATION", payload: {plainReservation: ingresoAux, fullReservation: ingreso}})
  onComplete()
}

export function CapitalFirst(string) {
  if(!string) return string
  
  return string.charAt(0).toUpperCase() + string.toLocaleLowerCase().slice(1)
}

export function Normalizar(string) {
  return string
    .toLocaleLowerCase()
    .split("'")
    .join("")
    .split("-")
    .join(" ")
    .split(" ")
    .map(x => CapitalFirst(x))
    .join(" ")
}

export function ElegirColor(estado, isDeudor) {
  switch (estado) {
    case "confirmada":
      return "#2185d0"
    case "checkin":
      if (isDeudor) {
        return "#fbbd08"
      } else {
        return "#21ba45"
      }
    case "cancelada":
      return "#db2828"
    case "checkout":
      return "#a5673f"
    case "nopresentado":
      return "#f2711c"
    case "abierta":
      return "#a333c8"
  }
}
export function ElegirColorMapa(estado, isDeudor) {
  switch (estado) {
    case "confirmada":
      return "#00aae4"
    case "checkin":
      if (isDeudor) {
        return "#ffd700"
      } else {
        return "#df6b77"
      }
    case "cancelada":
      return "#db2828"
    case "nopresentado":
      return "#db2828"
    case "checkout":
      return "#a5673f"
    default:
      return ""
  }
}

export function ElegirColorSemantic(estado, isDeudor) {
  if (estado == "checkin" && isDeudor) {
    return `yellow`
  }
  switch (estado) {
    case "confirmada":
      return "blue"
    case "abierta":
      return "purple"
    case "checkin":
      return "green"
    case "cancelada":
      return "red"
    case "nopresentado":
      return "orange"
    case "checkout":
      return "brown"
    case "preingreso":
      return "pink"
  }
}

export function MejorarIngreso(ingreso) {
  const checkoutTime = store.getState().mainReducer.checkoutTime
  /* const nochesPagas=ingreso.listaPagos.map(x=>x.noches).reduce((a,b) => a + b, 0);
      const fechaSalidaPaga=moment(ingreso.fechaIngreso).add(nochesPagas,"days").format("YYYY-MM-DD")*/
  let nights = moment(ingreso.checkoutEstimated)
    .startOf("day")
    .diff(moment(ingreso.checkinEstimated).startOf("day"), "days")

  const today = moment().format("YYYY-MM-DD")
  const checkout = moment(ingreso.checkoutEstimated).format("YYYY-MM-DD")
  const todayIsEqualOrAfterCheckout = moment(today).isSameOrAfter(checkout)
  
  //NightsUntilNow is used to calculate if the reservation is overdue
  let nightsUntilNow =
    moment()
      .startOf("day")
      .diff(moment(ingreso.checkinEstimated).startOf("day"), "days") +
    (todayIsEqualOrAfterCheckout &&
    parseInt(moment().format("HH")) <
      parseInt(moment(ingreso.checkoutEstimated).format("HH")) + 3
      ? 0
      : 1)
  nightsUntilNow = nightsUntilNow > 0 ? nightsUntilNow : 0
  // if(ingreso.customersList.find(x=>x.fullName.split('Galvan').length>1)){
  //   console.log(ingreso,nightsUntilNow,`FECHA ENTRADA: ${moment(ingreso.checkinEstimated).format('DD-MM')}`,`HORA SALIDA: ${moment(ingreso.checkoutEstimated).format('HH')}`)
  // }

  const customersList = ingreso.customersList.map(x => ({
    ...x,
    age: x.birthdate ? moment().diff(x.birthdate, "years") : null,
  }))
  var servicesList = ArreglarServicesList(ingreso.servicesList)

  //Prepare list
  const servicesNotPreingreso = servicesList.filter(
    x => x.description != "Pre-ingreso"
  )
  let unPayedServices = servicesNotPreingreso.filter(
    x => !x.canceled && x.quantity != 0
  )

  const payedNights =
    unPayedServices.length > 0
      ? Math.floor(
          unPayedServices.reduce(
            (acom, current) =>
              acom +
              (current.due == 0
                ? current.quantity
                : current.paid / current.perNight),
            0
          )
        )
      : 0

  const percentPayed = GetPercentPayed(servicesNotPreingreso)

  let nightsDue = nightsUntilNow - payedNights

  //const isDeudor=((percentPayed!=100||moment(ingreso.checkoutEstimated).add(3,"hours").diff(moment(),'minutes')<0)&&ingreso.state=="checkin");
  const isDeudor = nightsDue > 0
  const checkoutEstimated =
    ingreso.state == "preingreso"
      ? moment(ingreso.checkoutEstimated)
          .set("hour", checkoutTime - 3)
          .format()
      : ingreso.checkoutEstimated
  const startOfDay = moment().startOf("day")
  const startOfDayReservation = moment(ingreso.checkinEstimated).startOf("day")
  const numberOfDays = parseInt(startOfDay.diff(startOfDayReservation, "days"))
  const reservationNewState =
    ingreso.state == "confirmada" || ingreso.state == "nopresentado"
      ? numberOfDays > 1
        ? "nopresentado"
        : "confirmada"
      : ingreso.state
const starredCustomer=customersList.find(x=>x.customerId==ingreso.starredCustomerId)
const components = LimpiarComponentes(ingreso.components)
  return {
    ...ingreso,
    components,
    state: reservationNewState,
    starredCustomerId: starredCustomer?.customerId || customersList.reduce((a,b)=>a.age>b.age?a:b).customerId,
    nights,
    isDeudor,
    nightsDue,
    customersList,
    checkoutEstimated,
    servicesList,
    percentPayed,
  }
}

export function ChangeTypeSelectWithDuplication({currentType}){
  if(!currentType){
    return null;
  }
  const {precios, isMobile, reserva} = store.getState().mainReducer

  const onChangeType = (e, { value }) => {
    if(window.confirm(`¿Seguro desea reingresar el registro en ${value}?`)){
    store.dispatch({type:'DUPLICATE_RESERVATION_CHANGING_TYPE',payload:{reservation:reserva,type:value}})
    }
  }
  const moreThanOneRoomTypeToMerge=precios.reduce((acom,current)=>acom.includes(current.mergeRoomType)?acom:[...acom,current.mergeRoomType],[]).length>1
  const mergeRoomType=precios.find(x=>x.type==currentType)?.mergeRoomType
  if(!mergeRoomType || (mergeRoomType && !moreThanOneRoomTypeToMerge) || precios.length==1){
    return null
  }

  return <Select onChange={onChangeType} style={{marginLeft:!isMobile?"10px":"",fontSize:"12px"}} value={currentType} placeholder='Tipo' options={precios.map(x=>({ key: x.type, value: x.type, text: CapitalFirst(x.type) }))} />
}

export const PersonasEnRegistro=(ingreso)=>{
  const { precios:listaTipoPrecios } = store.getState().mainReducer
  const precio = listaTipoPrecios.find(x => x.type == ingreso.type)?.priceList
  if(!precio) return;
  const quantityObjects = precio.filter(x=>x.quantity)
  if(quantityObjects.length == 0){
    return ingreso?.customersList?.length
  }
  const json = JSON.parse(ingreso.components);
  let quantity = json? Object.keys(json).reduce((prev,current)=>(prev+(quantityObjects.find(y=>y.name==current)?.quantity||0) * (json[current] || 0)),0):0
  return quantity || ingreso?.customersList?.length
}

export function ElegirLabel(estado, nightsDue) {
  const colorSemantic = ElegirColorSemantic(estado, nightsDue > 0)
  switch (estado) {
    case "confirmada":
      return (
        <Label style={{ margin: 0 }} color={colorSemantic} horizontal>
          Reserva
        </Label>
      )
    case "abierta":
      return (
        <Label style={{ margin: 0 }} color={colorSemantic} horizontal>
          Abierta
        </Label>
      )
    case "checkin":
      return (
        <Label style={{ margin: 0 }} color={colorSemantic} horizontal>
          {nightsDue > 0 ? "Adeuda" : `Checkin`}
          {nightsDue > 0 && (
            <Label.Detail className={"deudor-label"}>{nightsDue}</Label.Detail>
          )}
        </Label>
      )
    case "cancelada":
      return (
        <Label style={{ margin: 0 }} color={colorSemantic} horizontal>
          Cancelada
        </Label>
      )
    case "nopresentado":
      return (
        <Label style={{ margin: 0 }} color={colorSemantic} horizontal>
          No presentado
        </Label>
      )
    case "checkout":
      return (
        <Label style={{ margin: 0 }} color={colorSemantic} horizontal>
          Checkout
        </Label>
      )
    case "preingreso":
      return (
        <Label style={{ margin: 0 }} color={colorSemantic} horizontal>
          Pre Ingreso
        </Label>
      )
    default:
      return (
        <Label style={{ margin: 0 }} color={"grey"} horizontal>
          {estado}
        </Label>
      )
  }
}

export function ElegirTexto(estado, nightsDue) {
  const state = {
    confirmada: "Reserva",
    abierta: "Abierta",
    checkin: nightsDue < 1 ? "Checkin" : `Adeuda ${nightsDue}`,
    cancelada: "Cancelada",
    nopresentado: "No presentado",
    checkout: "Checkout",
    preingreso: "Pre Ingreso"
  }
  return state[estado] || estado
}

export const ElegirOtaLabel = (estado) => {
  const text = {
    booking: "Booking",
    "Pagina-Web": "Pagina Web"
  }

  return (
    <Label style={{ marginLeft: "10px" }} color={"grey"} horizontal>
      {text[estado] || "Mostrador"}
    </Label>
  )
}

export const InformacionReservas = (listaReservas, fecha) => {
  const fechaMoment = moment(fecha)
  var salen = []
  var entran = []
  var estancias = []
  for (var item of listaReservas.filter(x => x.state != "cancelada")) {
    if (
      moment(item.checkoutEstimated).format("YYYY-MM-DD") ==
      fechaMoment.format("YYYY-MM-DD")
    ) {
      salen = [...salen, item]
    } else if (
      moment(item.checkinEstimated).format("YYYY-MM-DD") ==
      fechaMoment.format("YYYY-MM-DD")
    ) {
      entran = [...entran, item]
    } else if (
      fechaMoment.isBetween(item.checkinEstimated, item.checkoutEstimated)
    ) {
      estancias = [...estancias, item]
    }
  }
  return { salen, entran, estancias }
}
export const InformacionReservasMapa = (listaReservas, fecha) => {
  const fechaMoment = moment(fecha)
  var salen = []
  var entran = []
  var estancias = []
  for (var item of listaReservas) {
    if (item.checkoutMade != null || item.state == "cancelada") {
      continue
    }
    if (
      moment(item.checkoutEstimated).format("YYYY-MM-DD") ==
      fechaMoment.format("YYYY-MM-DD")
    ) {
      salen = [...salen, item]
    } else if (
      moment(item.checkinEstimated).format("YYYY-MM-DD") ==
      fechaMoment.format("YYYY-MM-DD")
    ) {
      entran = [...entran, item]
    } else if (
      fechaMoment.isBetween(item.checkinEstimated, item.checkoutEstimated)
    ) {
      estancias = [...estancias, item]
    }
  }
  return { salen, entran, estancias }
}

export const InformacionReservasActual = listaReservas => {
  return {
    salen: listaReservas.filter(
      x =>
        !x.checkoutMade &&
        x.state != "cancelada" &&
        moment(x.checkinEstimated)
          .startOf("day")
          .diff(moment().startOf("day"), "days") < 1
    ),
    entran: [],
    estancias: [],
  }
}


export const HabitacionesDisponiblesXFecha = (fechaIngreso, fechaSalida) => {
  const { tipoPrecio } = store.getState().mainReducer
  const listaFechas = ListaFechas(fechaIngreso, fechaSalida)
  let obj = []
  for (let fecha of listaFechas) {
    obj = [
      ...obj,
      {
        fecha,
        disponibilidad: HabitacionesDisponibles(
          fecha,
          moment(fecha).add(1, "days"),
          null,
          tipoPrecio
        ).length,
      },
    ]
  }
  return obj
}

export const HabitacionesDisponibles = (
  fechaIngreso,
  fechaSalida,
  listaReservas = null,
  type = null
) => {
  fechaSalida = moment(fechaSalida)
  fechaIngreso = moment(fechaIngreso)
  if (listaReservas == null) {
    listaReservas = store
      .getState()
      .mainReducer.listaReservas.filter(
        x => x.state != "cancelada" && x.state != "checkout"
      )
  }

  //Los departamentos que estan tomados para las fechas
  var listaDeptos = []
  const cantidadDias = fechaSalida.diff(fechaIngreso, "days")

  for (var i = 0; i < cantidadDias; i++) {
    const infoFecha = InformacionReservas(listaReservas, fechaIngreso)
    listaDeptos = [
      ...listaDeptos,
      ...infoFecha.entran
        .map(x => x.roomsList)
        .reduce((a, b) => [...a, ...b], []),
      ...infoFecha.estancias
        .map(x => x.roomsList)
        .reduce((a, b) => [...a, ...b], []),
    ]
    fechaIngreso.add(1, "days")
  }
  var listaReturn = []
  const departamentos = store.getState().mainReducer.listaDeptos.filter(x=>x.type==type || !type)
  for (var item of departamentos) {
    if (!listaDeptos.find(x => x.number == item.number)) {
      listaReturn = [...listaReturn, item]
    }
  }
  return listaReturn
}

export const PuedeReservar = (checkinEstimated, checkoutEstimated, reserva) => {
  //Lista de reservas sin la reserva
  const listaReservasAlterada = store
    .getState()
    .mainReducer.listaReservas.filter(
      x =>
        reserva.reservationId != x.reservationId &&
        x.state != "cancelada" &&
        x.state != "checkout"
    )
  //Habitaciones disponibles sin tener en cuenta las habitaciones alquiladas por la reserva
  const habitacionesDisponibles = HabitacionesDisponibles(
    checkinEstimated,
    checkoutEstimated,
    listaReservasAlterada
  )

  for (var room of reserva.roomsList) {
    //Si no encuentra las habitaciones es porque no hay lugar
    if (habitacionesDisponibles.find(x => x.roomId == room.roomId) == null) {
      return { resultado: false, habitacion: room }
    }
  }
  return { resultado: true }
}

export const ActualizarTodo = async (setLoading, dispatch) => {
  await ActualizarListaDeptos(() => {}, dispatch)
  await ActualizarListaReservas(setLoading, dispatch)
}

export const AgregarHabitacion = async (room, setLoading, dispatch) => {
  setLoading(true)
  const response = await API.graphql(graphqlOperation(addRoom, { room }))
  ActualizarTodo(setLoading, dispatch)
}

export const ModificarHabitacion = async (room, setLoading, dispatch) => {
  setLoading(true)
  const response = await API.graphql(graphqlOperation(modifyRoom, { room }))
  ActualizarTodo(setLoading, dispatch)
}

export const RefrescarPrecioYDispo = async dispatch => {
  dispatch(refrescarPrecioYDispo(0))
}

export const ActualizarPrecios = async dispatch => {
  const response = await API.graphql({
    query: getPrices,
    variables: {},
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })
  dispatch(setPrecios(JSON.parse(JSON.parse(response.data.getPrices))))
}

export const ModificarPrecios = async (precios, dispatch, setLoading) => {
  // Actualiza el título del documento usando la API del navegador
  const response = await API.graphql(
    graphqlOperation(modifyPrices, { prices: JSON.stringify(precios) })
  )
  dispatch(setPrecios(JSON.parse(response.data.modifyPrices)))
  setLoading(false)
}

export const LimpiarComponentes = (components) => {
  const { precios:listaTipoPrecios, tipoPrecio } = store.getState().mainReducer
  const precio = listaTipoPrecios.find(x => x.type == tipoPrecio)?.priceList
  if(!Boolean(precio)) return
  const componentesParseados = components ? JSON.parse(components) : {}
  const componentesCobraUnaVez = precio.filter(x => x.chargeOnce).map(x=>x.name)
  for (let componentName of componentesCobraUnaVez) {
    componentesParseados[componentName] = 0
  }
  return JSON.stringify(componentesParseados);
}

export const DevolverComponentesParaJSX = (
  vehiclesList,
  customersList,
  ingreso,
  servicesList
) => {
  const { precios, tipoPrecio } = store.getState().mainReducer
  const obj = {}
  let comp = DevolverComponentes({ ...ingreso, vehiclesList, customersList })

  let compAux = JSON.parse(JSON.stringify(comp))
  compAux = DevolverComponentesConServicios(compAux, servicesList)

  Object.keys(comp).forEach(element => {
    if (
      precios
        .find(x => x.type == tipoPrecio)
        .priceList.find(x => x.name == element)
    ) {
      obj[element] = compAux[element]
    }
  })
  //Sort by date desc using moment
  const servicesNotCanceled = servicesList
    .filter(x => !x.canceled)
    .sort((a, b) => moment(b.date).diff(moment(a.date), "seconds"))

  const rta =
    servicesNotCanceled.length == 0
      ? obj
      : JSON.parse(servicesNotCanceled[0].components)

  return rta
}

export const EsComponenteExtra = componenteString => {
  const { precios, tipoPrecio } = store.getState().mainReducer

  const priceList = precios.find(x => x.type == tipoPrecio).priceList
  const component = priceList.find(x => x.name == componenteString)
  return !!component && Object.keys(component).length < 3
}

export const EsLugar = componenteString => {
  const { precios, tipoPrecio } = store.getState().mainReducer

  const priceList = precios.find(x => x.type == tipoPrecio).priceList
  const component = priceList.find(x => x.name == componenteString)
  return !!component?.priceRoom
}

export const EsVehiculo = componenteString => {
  const { precios, tipoPrecio } = store.getState().mainReducer

  const priceList = precios.find(x => x.type == tipoPrecio).priceList
  const component = priceList.find(x => x.name == componenteString)
  const pricesHaveMandatoryVehicle = priceList.find(x => x.mandatoryVehicle)
  const hasQuantity = component.quantity != null
  return !!component.vehicleBased || (pricesHaveMandatoryVehicle && hasQuantity)
}

export const DevolverComponentesEnCero = () => {
  const { precios, tipoPrecio } = store.getState().mainReducer
  const obj = {}
  const priceList = precios.find(x => x.type == tipoPrecio).priceList
  for (let component of priceList) {
    obj[component.name] = 0
  }
  return obj
}

//En base a un ingreso devuelve todos sus componentes
export const DevolverComponentes = ingreso => {
  const { precios, tipoPrecio } = store.getState().mainReducer

  const prices = precios.find(x => x.type == tipoPrecio).priceList
  const customersList = ingreso.customersList.map(x => ({
    ...x,
    age: x.birthdate ? moment().diff(x.birthdate, "years") : 20,
  }))
  const vehiclesList = ingreso.vehiclesList
  const obj = {}
  let arrayValues = prices
  let arrayKeys = prices.map(x => x.name)
  const isInRange = (age, from, to) => age >= from && age <= to
  for (let key of arrayKeys) {
    obj[key] = 0
  }

  for (var customer of customersList) {
    for (let index = 0; index < arrayValues.length; index++) {
      const val = arrayValues[index]
      const key = arrayKeys[index]
      if (
        val.sexBased &&
        isInRange(customer.age, val.fromAge, val.toAge) &&
        customer.sex == val.sex
      ) {
        obj[key]++
      } else if (
        val.ageBased &&
        isInRange(customer.age, val.fromAge, val.toAge)
      ) {
        obj[key]++
      }
    }
  }

  for (var vehicle of vehiclesList) {
    for (let index = 0; index < arrayValues.length; index++) {
      const val = arrayValues[index]
      const key = arrayKeys[index]
      if (val.vehicleBased && vehicle.type == key) {
        obj[key]++
      }
    }
  }

  if (prices.filter(x => x.quantity).length > 0) {
    const hasVehicles= vehiclesList.length > 0
    let maxQuantity = prices
    .filter(x => x.quantity)
    .reduce((a, b) => (a.quantity > b.quantity ? a : b))
    let minQuantity = prices
    .filter(x => x.quantity)
    .reduce((a, b) => (a.quantity < b.quantity ? a : b))

    for (let key of Object.keys(obj)) {
      //Find greates quantity in prices

      const quantity = prices.find(x => x.name == key).quantity
      const quantityExtra = prices.find(x => x.quantityExtra)
      const pricesHaveMandatoryVehicle = prices.find(x => x.mandatoryVehicle)
      const mandatoryVehicle= prices.find(x => x.name == key).mandatoryVehicle
      if(pricesHaveMandatoryVehicle){
        if(mandatoryVehicle && !hasVehicles){
          continue;
        }
        if(!mandatoryVehicle && hasVehicles){
          continue;
        }
        if(mandatoryVehicle){
          maxQuantity = prices.filter(x => x.quantity && x.mandatoryVehicle)
          .reduce((a, b) => (a.quantity > b.quantity ? a : b));
          minQuantity = prices.filter(x => x.quantity && x.mandatoryVehicle)
          .reduce((a, b) => (a.quantity < b.quantity ? a : b));
        }
        else{
          maxQuantity = prices.filter(x => x.quantity && !x.mandatoryVehicle)
          .reduce((a, b) => (a.quantity > b.quantity ? a : b));
          minQuantity = prices.filter(x => x.quantity && !x.mandatoryVehicle)
          .reduce((a, b) => (a.quantity < b.quantity ? a : b));
        }
      }

      if (quantity && customersList.length != quantity) {
        obj[key] = 0
      } else if (customersList.length == quantity) {
        obj[key] = 1
      }
      if (
        customersList.length > maxQuantity.quantity &&
        maxQuantity.name == key
      ) {
        obj[key] = maxQuantity.quantity
        if (quantityExtra) {
          obj[quantityExtra.name] = customersList.length - maxQuantity.quantity
        }
      } else if (
        customersList.length >0 &&
        customersList.length <= minQuantity.quantity &&
        minQuantity.name == key
      ) {
        obj[key] = 1
      }
    }
  }
  if (prices.filter(x => x.priceRoom).length > 0 && ingreso.roomsList.length > 0) {

    for (let key of Object.keys(obj)) {
      const priceRoom = prices.find(x => x.name == key)?.priceRoom
      if (priceRoom) {
        const roomQuantityChoosed = ingreso.roomsList.filter(x => x.price == priceRoom).length
        if(roomQuantityChoosed){
          obj[key] = roomQuantityChoosed
        }
        else{
          delete obj[key];
        }

      }
    }
  }

  return obj
}

//Es una mejora a DevolverComponentes
export const DevolverComponentesConServicios = (
  componentesBasicos,
  servicesList
) => {
  servicesList = servicesList.filter(x => !x.canceled)
  let obj = componentesBasicos
  for (let service of servicesList) {
    obj = MergeObj(obj, JSON.parse(service.components) ?? {})
  }
  return obj
}

const MergeObj = (comp1, comp2) => {
  comp1 = { ...comp2, ...comp1 }
  for (let aux of Object.keys(comp1)) {
    if (comp2[aux]) {
      comp1[aux] = comp1[aux] < comp2[aux] ? comp2[aux] : comp1[aux]
    }
  }
  return comp1
}

//Devuelve una lista con todas las reservas que hay en cada fecha, repitiendo las reservas de quedarse mas un dia
export const ObtenerListaPagosFecha = fecha => {
  let lista = []
  const listaReservas = store
    .getState()
    .mainReducer.listaReservas.filter(x => x.state != "cancelada")
  for (let item of listaReservas) {
    if (item.servicesList.length > 0) {
      const listaPagos = item.servicesList
        .filter(x => !x.canceled)
        .map(x => {
          if (x.paymentsList.length > 0) {
            return x.paymentsList.map(y => ({
              ...y,
              reservation: item,
              descripcion: x.description,
              nombreyape: item.customersList[0].fullName,
              lugares:
                item.roomsList.length == 0
                  ? "-"
                  : item.roomsList.map(x => x.number).join(" "),
            }))
          } else {
            return []
          }
        })
        .flat()
      //console.log(listaPagos)
      lista = [
        ...lista,
        ...listaPagos.filter(
          x =>
            moment(x.date)
              .startOf("day")
              .diff(moment(fecha).startOf("day"), "days") == 0
        ),
      ]
    }
  }
  return lista
}
export const ObtenerListaPagosFechas = async (from, to, type) => {
  const obj = type ? { from, to, type } : { from, to }
  try {
    const response = await API.graphql({
      query: getPaymentList,
      variables: obj,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    return response.data.getPaymentList
  } catch (error) {
    //If no conection alert error
    if (error?.errors[0].message == "Network Error") {
      alert("No se pudo conectar con el servidor, por favor intente más tarde")
    }
  }
}

export const ObtenerListaBills  = async (from, limit) => {
  try {
    const response = await API.graphql({
      query: getBillList,
      variables: { from, limit: Math.round(limit+10) },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    console.log(response)
    return response.data.getBillList
  } catch (error) {
    console.log(error)
    //If no conection alert error
    if (error?.errors[0].message == "Network Error") {
      alert("No se pudo conectar con el servidor, por favor intente más tarde")
    }
  }
}

export const ObtenerReservasEnCadaFecha = (fechaInicio, fechaFin) => {
  const noches = moment(fechaFin).diff(fechaInicio, "days")
  const listaReservas = store.getState().mainReducer.listaReservas
  let obj = []
  let fechaActual
  for (let index = 0; index < noches; index++) {
    //El -1 es para arreglar algunos bugs que aparecen si chequeamos por horas exactas
    fechaActual = moment(fechaInicio)
      .add(index, "days")
      .add(-1, "hours")
    const listaReservasAux = listaReservas.filter(x =>
      fechaActual.isBetween(
        x.checkinEstimated,
        x.checkoutMade ? x.checkoutMade : x.checkoutEstimated,
        null,
        "[]"
      )
    )
    obj = [
      ...obj,
      {
        fechaActual: fechaActual.format("YYYY-MM-DD"),
        listaReservas: listaReservasAux,
      },
    ]
  }

  return obj
}

export const ObtenerEstadisticas = (fechaInicio, fechaFin) => {
  const listaReservasRepetidas = ObtenerReservasEnCadaFecha(
    fechaInicio,
    moment(fechaFin)
      .add(1, "days")
      .format("YYYY-MM-DD")
  )
    .map(x => x.listaReservas)
    .flat()
  const resultado = {
    mayores: listaReservasRepetidas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age > 7 || x.age == 0).length,
      0
    ),
    menores: listaReservasRepetidas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age <= 7 && x.age > 0).length,
      0
    ),
    vehiculos: listaReservasRepetidas.reduce(
      (acom, current) => acom + current.vehiclesList.length,
      0
    ),
    carpas: listaReservasRepetidas.reduce(
      (acom, current) =>
        acom +
        (current?.servicesList.length
          ? JSON.parse(current?.servicesList[0]?.components)?.carpa != undefined
            ? JSON.parse(current.servicesList[0].components).carpa
            : 0
          : 0),
      0
    ),
    registros: store
      .getState()
      .mainReducer.listaReservas.filter(x =>
        moment(x.created).isBetween(fechaInicio, fechaFin, null, "[]")
      ).length,
  }
  return resultado
}

export const ObtenerEstadisticasDiarias = fecha => {
  const fechaInicio = moment(fecha)
    .startOf("month")
    .format("YYYY-MM-DD")
  const fechaFin = moment(fecha)
    .endOf("month")
    .add(2, "days")
    .format("YYYY-MM-DD")
  const listaReservasRepetidas = ObtenerReservasEnCadaFecha(
    fechaInicio,
    fechaFin
  )
  const noches = moment(fechaFin).diff(fechaInicio, "days")
  let fechaActual
  let resultados = []
  for (let index = 0; index < noches; index++) {
    fechaActual = moment(fechaInicio).add(index, "days")
    let reservasActuales = listaReservasRepetidas.find(
      x => x.fechaActual == fechaActual.format("YYYY-MM-DD")
    )

    if (reservasActuales) {
      reservasActuales = reservasActuales.listaReservas
    } else {
      continue
    }
    resultados = [
      ...resultados,
      {
        fecha: fechaActual.format("YYYY-MM-DD"),
        mayores: reservasActuales.reduce(
          (acom, current) =>
            acom +
            current.customersList.filter(x => x.age > 7 || x.age == 0).length,
          0
        ),
        menores: reservasActuales.reduce(
          (acom, current) =>
            acom +
            current.customersList.filter(x => x.age <= 7 && x.age > 0).length,
          0
        ),
        vehiculos: reservasActuales.reduce(
          (acom, current) => acom + current.vehiclesList.length,
          0
        ),
        carpas: reservasActuales.reduce(
          (acom, current) =>
            acom +
            (current?.servicesList.length
              ? JSON.parse(current?.servicesList[0]?.components)?.carpa !=
                undefined
                ? JSON.parse(current.servicesList[0].components).carpa
                : 0
              : 0),
          0
        ),
        registros: reservasActuales.filter(
          x => moment(x.created).diff(fechaActual, "days") == 0
        ).length,
        ocupacion: reservasActuales.reduce(
          (acom, current) => acom + current.roomsList.length,
          0
        ),
      },
    ]
  }
  /*const resultado={
    mayores:listaReservasRepetidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age>7||x.age==0).length,0),
    menores:listaReservasRepetidas.reduce((acom,current)=>acom+current.customersList.filter(x=>x.age<=7&&x.age>0).length,0),
    vehiculos:listaReservasRepetidas.reduce((acom,current)=>acom+current.vehiclesList.length,0)
   }*/

  return resultados
}

//Devuelve la estadistica de los registros que estan en el camping
export const ObtenerEstadisticaActual = () => {
  const listaReservas = store
    .getState()
    .mainReducer.listaReservas.filter(x => x.state == "checkin")
  const obj = {
    mayores: listaReservas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age > 7 || x.age == 0).length,
      0
    ),
    menores: listaReservas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age <= 7 && x.age > 0).length,
      0
    ),
    vehiculos: listaReservas.reduce(
      (acom, current) => acom + current.vehiclesList.length,
      0
    ),
    registros: listaReservas.length,
    carpas: listaReservas.reduce(
      (acom, current) =>
        acom +
        (current?.servicesList.length
          ? JSON.parse(current?.servicesList[0]?.components)?.carpa != undefined
            ? JSON.parse(current.servicesList[0].components).carpa
            : 0
          : 0),
      0
    ),
    ocupacion: listaReservas.reduce(
      (acom, current) => acom + current.roomsList.length,
      0
    ),
  }
  return obj
}

//Devuelve la estadistica de los registros que ingresaron hoy
export const ObtenerEstadisticaIngresosDeHoy = () => {
  const listaReservas = store
    .getState()
    .mainReducer.listaReservas.filter(
      x =>
        x.state == "checkin" &&
        moment(x.checkinEstimated).format("YYYY-MM-DD") ==
          moment().format("YYYY-MM-DD")
    )
  const listaReservasSalidas = store
    .getState()
    .mainReducer.listaReservas.filter(
      x =>
        x.state == "checkout" &&
        moment(x.checkoutMade).format("YYYY-MM-DD") ==
          moment().format("YYYY-MM-DD")
    )
  const obj = {
    mayores: listaReservas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age > 7 || x.age == 0).length,
      0
    ),
    menores: listaReservas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age <= 7 && x.age > 0).length,
      0
    ),
    vehiculos: listaReservas.reduce(
      (acom, current) => acom + current.vehiclesList.length,
      0
    ),
    registros: listaReservas.length,
    carpas: listaReservas.reduce(
      (acom, current) =>
        acom +
        (current?.servicesList.length
          ? JSON.parse(current?.servicesList[0]?.components)?.carpa != undefined
            ? JSON.parse(current.servicesList[0].components).carpa
            : 0
          : 0),
      0
    ),
    ocupacion: listaReservas.reduce(
      (acom, current) => acom + current.roomsList.length,
      0
    ),
    mayoresSalieron: listaReservasSalidas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age > 7 || x.age == 0).length,
      0
    ),
    menoresSalieron: listaReservasSalidas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age <= 7 && x.age > 0).length,
      0
    ),
  }
  return obj
}

//Devuelve la estadistica de los registros que ingresaron hoy
export const ObtenerEstadisticaOcupacion = () => {
  const listaReservas = store
    .getState()
    .mainReducer.listaReservas.filter(
      x =>
        moment(x.checkinMade).format("YYYY-MM-DD") ==
        moment().format("YYYY-MM-DD")
    )
  const obj = {
    mayores: listaReservas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age > 7 || x.age == 0).length,
      0
    ),
    menores: listaReservas.reduce(
      (acom, current) =>
        acom +
        current.customersList.filter(x => x.age <= 7 && x.age > 0).length,
      0
    ),
    vehiculos: listaReservas.reduce(
      (acom, current) => acom + current.vehiclesList.length,
      0
    ),
    carpas: listaReservas.reduce(
      (acom, current) =>
        acom +
        (current?.servicesList.length
          ? JSON.parse(current?.servicesList[0]?.components)?.carpa != undefined
            ? JSON.parse(current.servicesList[0].components).carpa
            : 0
          : 0),
      0
    ),
    registros: listaReservas.length,
  }
  return obj
}

export const CalcularCheckoutTime = () => {
  const checkout = store.getState().mainReducer.checkoutTime
  const horaActual =
    parseInt(moment().format("h")) +
    parseInt(moment().format("a") == "pm" ? 12 : 0)
  if (typeof checkout == "number") {
    return checkout
  } else {
    for (let i of checkout) {
      if (i.from <= horaActual && i.to >= horaActual) {
        return i.value
      }
    }
  }
}

export const CalcularAfternoonCheckoutTime = () => {
  const checkout = store.getState().mainReducer.afternoonCheckoutTime  
  const horaActual =
    parseInt(moment().format("h")) +
    parseInt(moment().format("a") == "pm" ? 12 : 0)
  if (typeof checkout == "number") {
    return checkout
  } else {
    for (let i of checkout) {
      if (i.from <= horaActual && i.to >= horaActual) {
        return i.value
      }
    }
  }
}

export const TransformToLocalCurrency = (str, digits = 0) => {
  if (!str) {
    str = 0
  }
  return str.toLocaleString("es-ar", {
    style: "currency",
    currency: "ars",
    minimumFractionDigits: digits,
  })
}
export const MovimientosCaja = () => {
  const {
    cashMovements,
    paymentList,
    currentUser,
    isCashAdmin,
    isAdmin,
  } = store.getState().mainReducer
  let unionList = [...cashMovements]
  let lista = []
  if (isAdmin) {
    unionList = [
      ...unionList,
      ...(!paymentList
        ? []
        : paymentList
            .map(x => ({
              ...x,
              type: x.method,
              cashName: "abajo",
              closeCash: false,
              isWithdrawal: false,
              date: moment(x.date).add(3, "hours"),
              concept: x.description,
              canceled: x.canceled              ,
            }))),
    ]
  }
  if (!isCashAdmin) {
    unionList = unionList.filter(x => x.specificUsername == currentUser)
  }
  let saldo = 0
  for (let movement of unionList.sort((a, b) =>
    moment(a.date).diff(moment(b.date))
  )) {
    const obj = {}
    const zero = TransformToLocalCurrency(0)
    const isWithdrawal = movement.isWithdrawal
    saldo = saldo + (isWithdrawal ? -movement.amount : movement.amount)
    obj.fecha = moment(movement.date).format("DD/MM HH:mm")
    obj.entrada = isWithdrawal
      ? zero
      : TransformToLocalCurrency(movement.amount)
    obj.salida = isWithdrawal ? TransformToLocalCurrency(movement.amount) : zero
    obj.concepto = movement.concept ? Normalizar(movement.concept) : ""
    obj.saldo = TransformToLocalCurrency(saldo)
    obj.tipo = movement.type ?? "Efectivo"
    obj.usuario = movement.specificUsername
    obj.reservationId = movement.reservationId
    obj.canceled = movement.canceled
    lista = [...lista, obj]
    obj.salida = isWithdrawal ? TransformToLocalCurrency(movement.amount) : zero
  }

  return lista
}

export const chooseCurrency = (currency) => {
  return `${currency.slice(0, 2)}${"$"}`
}

export const ActualizarFromTo = dispatch => {
  const {
    cashMovements,
    lastBuildNumber,
    currentBuildNumber,
  } = store.getState().mainReducer
  let lista = cashMovements.sort((a, b) => moment(a.date).diff(moment(b.date)))
  let from, to
  if (lista && lista.length > 0) {
    const encontrado = lista.find(x => !x.closeCash)
    if (encontrado && encontrado.date) {
      from = encontrado.date
    } else {
      from = "3020-01-19"
    }
    if (lastBuildNumber != currentBuildNumber) {
      to = lista[lista.length - 1].date
    } else {
      to = "3020-01-20"
    }
    dispatch({ type: "SET_CASH_FROM_TO", payload: { from, to } })
  }
}

export const GeneratePrintTemplate = ({reservation}) => {
const campingName = store.getState().billReducer?.fantasyName || "Datos"
const type = store.getState().mainReducer?.campingUser=="Costaalegre"?"Complejo":CapitalFirst(reservation.type)
const printSpecificRoomVoucher = store.getState().mainReducer?.printSpecificRoomVoucher
const checkoutTime = store.getState().mainReducer.checkoutTime;
const starredCustomer = chooseStarredCustomer(reservation.customersList,reservation.starredCustomerId)
const componentesParseados = reservation.components?JSON.parse(reservation.components):{}
const rooms = reservation.roomsList.length!=0?reservation.roomsList.length<3?reservation.roomsList.map(x=>x.number).join(" "):reservation.roomsList.length:"No asignado";

const components = reservation.components?Object.keys(componentesParseados).sort()
                  .reduce((acom, current) => acom + (componentesParseados[current] > 0 ? `${/^\d+$/.test(current[0])?``:`${componentesParseados[current]} `}${current} ` : ""), "") : "No disponible"
const servicesList= reservation.servicesList.filter(x=>!x.canceled && x.description != "Pre-ingreso")
const total = servicesList.map(x=>x.cost).reduce((a,b) => a + parseInt(b), 0)
const deudaTotal = servicesList.map(x=>x.due).reduce((a,b) => a + parseInt(b), 0)
const pagadoTotal = servicesList.map(x=>x.paid).reduce((a,b) => a + parseInt(b), 0)

return(
  `
  <!DOCTYPE html>
  <html>
  <head>
  <style>
  @import url("https://fonts.googleapis.com/css?family=Poppins|Roboto"); 
  * { 
      box-sizing: border-box;
  } 
  
  [class^="bui-"] { 
      -webkit-box-sizing: border-box; 
      box-sizing: border-box;
  } 
  
  .bui-panel-group { 
      margin-bottom: 16px;
  } 
  
  [class*="bui-grid__column"] { 
      -webkit-box-sizing:  border-box; 
      box-sizing:  border-box;
      width: 100%; 
      padding: 8px 4px 0; 
      -ms-flex-preferred-size: 100%; 
      flex-basis: 100%;
  } 
  
  .bui-grid__column-full { 
      -ms-flex-preferred-size: 100%; 
      flex-basis: 100%; 
      width: 100%; 
      max-width: 100%;
  } 
  
    .bui-grid__column-8@medium { 
      -ms-flex-preferred-size: 66.6666666667%; 
      flex-basis: 66.6666666667%; 
      width: 66.6666666667%; 
      max-width: 66.6666666667%;
    } 
  
  .res-main-reservation-details-wrapper { 
      padding-top: 24px;
  } 
  
    .bui-grid > [class*="bui-grid__column"]  { 
      padding: 16px 8px 0;
    } 
  
  
    .bui-grid > [class*="bui-grid__column"]  { 
      padding: 24px 12px 0;
    } 
       
  
  
    .bui-grid > [class*="bui-grid__column"]  { 
      padding: 32px 16px 0;
    } 
    
  
  .bui-grid { 
      display: flex; 
      display: -ms-flexbox; 
      display: -webkit-box; 
      margin:   -8px -4px 0; 
      clear:   both;
      -webkit-box-sizing:  border-box; 
      box-sizing:  border-box;
      -webkit-box-orient: horizontal; 
      -webkit-box-direction: normal; 
      -ms-flex-flow: row wrap; 
      flex-flow: row wrap; 
      -webkit-box-pack: start; 
      -ms-flex-pack: start; 
      justify-content: flex-start;
  } 
  
   
    .bui-grid { 
      margin: -16px -8px 0;
    } 
      
  
  @media (min-width: 992px){ 
    .bui-grid { 
      margin: -24px -12px 0;
    } 
  }     
  
  @media (min-width: 1200px){ 
    .bui-grid { 
      margin: -32px -16px 0;
    } 
  }     
  
  .bui-container { 
      -webkit-box-sizing: border-box; 
      box-sizing: border-box; 
      position: relative; 
      width: 100%; 
      padding: 16px;
  } 
  
  .bui-container--center { 
      margin: 0 auto; 
      max-width: 1140px;
  } 
  
  .res-details-app { 
      padding-top: 8px;
  } 
  
  .main { 
      min-height: calc(100vh - 277px);
  } 
  
  .is_new_hnav .page-body { 
      padding: 0;
  } 
  
  #content { 
      padding: 0;
  } 
  
  .is_new_hnav #content { 
      max-width: none;
  } 
  
  #main-container { 
      padding: 0!important;
  } 
  
  body { 
      margin:   0;
      font-family:  BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif; 
      font-size:  14px; 
      line-height:  1.4; 
      background-color: #f2f2f2;
  } 
  
  html { 
      font-size: 10px;
  } 
  
  .bui-panel { 
      background-color: #fff; 
      border: 1px solid #e0e0e0; 
      padding: 24px; 
      margin-bottom: 16px; 
      border-radius: 2px;
  } 
  
  .res-reservation-overview { 
      padding-bottom: 4px;
  } 
  
  .bui-panel:last-child { 
      margin-bottom: 0;
  } 
  
  .bui-panel-group > .bui-panel  { 
      margin-bottom: 0; 
      border-radius: 0;
  } 
  
  .bui-panel-group > .bui-panel:first-child  { 
      border-radius: 2px 2px 0 0;
  } 
  
  .bui-panel-group > .bui-panel:last-child  { 
      border-radius: 0 0 2px 2px;
  } 
  
  [class^="bui-"],[class^="bui-"]:after,[class^="bui-"]:before { 
      -webkit-box-sizing: border-box; 
      box-sizing: border-box;
  } 
  
   
    .bui-grid__column-4\\@medium { 
      -ms-flex-preferred-size: 33.3333333333%; 
      flex-basis: 33.3333333333%; 
      width: 33.3333333333%; 
      max-width: 33.3333333333%;
    } 
      
  
  p { 
      margin: 0 0 10px;
  } 
  
  .res-content__label { 
      color: #6b6b6b; 
      margin-bottom: 0;
  } 
  
  .res-content__info { 
      font-size: 16px; 
      font-weight: 400; 
      line-height: 24px; 
      margin-bottom: 16px;
  } 
  
  .res-content__info--emphasized { 
      font-size: 20px; 
      font-weight: 700; 
      line-height: 28px;
  } 
  .res-content__info--title { 
      font-size: 26px !important; 
      font-weight: 700 !important; 
      line-height: 28px !important;
  } 
  .res-reservation-overview__address { 
      margin-bottom: 24px;
  } 
  
  .bui-grid--align-center { 
      -webkit-box-align: center; 
      -ms-flex-align: center; 
      align-items: center;
  } 
  
  .res-reservation-overview__extra { 
      margin-top: 0;
  } 
  
  .bui-f-font-featured  { 
      font-size: 16px; 
      font-weight: 400; 
      line-height: 1.5em;
  } 
  
   
    .bui-grid__column-full\\@medium { 
      -ms-flex-preferred-size: 100%; 
      flex-basis: 100%; 
      width: 100%; 
      max-width: 100%;
    } 
  
  
  .res-reservation-overview__extra .bui-grid__column-full  { 
      padding-top: 0;
  } 
  
  
    .bui-grid__column-6\\@medium { 
      -ms-flex-preferred-size: 50%; 
      flex-basis: 50%; 
      width: 50%; 
      max-width: 50%;
    } 
      
  
  .bui-u-inline { 
      display: inline-block!important; 
      width: auto!important;
  } 
  
  a { 
      color: #337ab7; 
      text-decoration: none;
  } 
  
  .bui-link { 
      color: #07c; 
      text-decoration: underline; 
      cursor: pointer;
  } 
  
  .bui-link--primary,.bui-link--primary:link { 
      text-decoration: none;
  } 
  
  a:hover { 
      color: #23527c; 
      text-decoration: underline;
  } 
  
  a:active,a:hover { 
      outline: 0;
  } 
  
  .bui-link:active,.bui-link:hover { 
      color: #07c; 
      text-decoration: underline;
  } 
  
  .res-content-guest-comments { 
      white-space: pre-wrap;
  } 
  
  button { 
      font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif; 
      font-size: 14px; 
      line-height: 1.4; 
      margin: 0;
  } 
  
  .bui-link--primary { 
      text-decoration: none;
  } 
  
  button.bui-link { 
      border: 0; 
      background: none; 
      padding: 0; 
      font-size: inherit;
  } 
  
  .bui-flag__flag { 
      width: 16px; 
      vertical-align: middle;
  } 
  
  .bui-flag__text { 
      display: inline-block; 
      vertical-align: middle; 
      margin-left: 2px; 
      font-size: 12px; 
      font-weight: 400; 
      line-height: 1.5em;
  } 
  
  .bui-f-font-body  { 
      font-size: 14px; 
      font-weight: 400; 
      line-height: 1.4285714286em;
  } 
  
  .res-reservation-overview__fill-grayscale-light { 
      fill: #bdbdbd;
  } 
  .right{
      float:right;
      font-size: 20px;
      font-weight: 500;
  }
  
  
  
    .bui-grid__column-8\\@medium { 
      -ms-flex-preferred-size: 66.6666666667%; 
      flex-basis: 66.6666666667%; 
      width: 66.6666666667%; 
      max-width: 66.6666666667%;
    } 
  
  
  .res-main-reservation-details-wrapper { 
      padding-top: 24px;
  } 
  
  
  
  
  
    .bui-grid { 
      margin: -16px -8px 0;
    } 
  
  
  
    .bui-grid { 
      margin: -24px -12px 0;
    } 
     
  
  
    .bui-grid { 
      margin: -32px -16px 0;
    } 
     
  
  
  
  
  
  .res-room-block__wrapper { 
      padding: 0; 
      margin: 0 0 16px;
  } 
  
  
    .bui-grid__column-4\\@medium { 
      -ms-flex-preferred-size: 33.3333333333%; 
      flex-basis: 33.3333333333%; 
      width: 33.3333333333%; 
      max-width: 33.3333333333%;
    } 
    
  
  .bui-table { 
      display: block;
      border-collapse:  separate; 
      border-spacing:  0; 
      width:  100%; 
      -webkit-box-sizing:  border-box; 
      box-sizing:  border-box; 
      border-radius:  2px;
      font-size: 14px; 
      font-weight: 400; 
      line-height: 1.4285714286em; 
  } 
  
  
    .bui-table { 
      display: table; 
      border: solid #e0e0e0; 
      border-width: 1px 1px 0; 
      background-color: #fff;
    } 
      
  
  .res-room-block__table { 
      border: none;
  } 
  
  .res-room-block-overview__label { 
      color: #6b6b6b; 
      margin-bottom: 0;
  } 
  
  .res-room-block-overview__info { 
      font-size: 16px; 
      font-weight: 400; 
      line-height: 24px; 
      margin-bottom: 16px;
  } 
  
  .bui-table__head { 
      display: none;
  } 
  
  
    .bui-table__head { 
      display: table-row-group;
    } 
    
  
  
  
  
  
  .bui-table__row { 
      display:  block;
      font-size:  14px; 
      font-weight:  400; 
      line-height:  1.4285714286em; 
      position: relative; 
      margin-bottom: 8px; 
      background-color: #fff;
  } 
  
  
    .bui-table__row { 
      display: table-row; 
      border: none; 
      margin: 0;
    } 
     
  
  .res-room-row-subtotal { 
      background: #e6e6e6;
  } 
  
  @media (min-width: 768px){ 
    .res-room-row-subtotal { 
      background: #fff;
    } 
  }     
  
  .res-room-row-total { 
      background: #f5f5f5;
  } 
  
  .bui-table__cell { 
      display:  block;
      font-size:  14px; 
      font-weight:  400; 
      line-height:  1.4285714286em; 
      padding: 16px 24px; 
      border-bottom: 1px solid #e0e0e0;
  } 
  
  
    .bui-table__cell { 
      display: table-cell; 
      vertical-align: top;
    } 
  
    .bui-table__cell--head { 
      font-size: 14px; 
      font-weight: 700; 
      line-height: 1.4285714286em; 
      text-align: left; 
      padding: 24px;
    } 
     
  
  .res-room-block__table .bui-table__cell  { 
      padding: 16px;
  } 
  
  .res-room-block__table .bui-table__cell--head  { 
      padding-top: 0;
  } 
  
  
    .res-room-block__table .bui-table__cell  { 
      padding: 8px;
    } 
   
  
  .bui-table__cell:before { 
      content: attr(data-heading); 
      display: inline-block; 
      width: 50%;
  } 
  
  
    .bui-table__cell:before { 
      display: none;
    } 
  
    .bui-table__cell--align-end { 
      text-align: right;
    } 
     
  
  .bui-table__cell--row-head { 
      font-size: 14px; 
      font-weight: 700; 
      line-height: 1.4285714286em; 
      text-align: left;
  } 
  
  
    .bui-table__cell--row-head { 
      font-size: 14px; 
      font-weight: 400; 
      line-height: 1.4285714286em;
    } 
     
  
  .bui-table__cell--row-head:before { 
      display: none;
  } 
  
  
  
  
  </style>
  <body>
  
  <div class="res-reservation-overview bui-panel snipcss-wCNCj">
      <p class="res-content__info res-content__info--title">
          <span>
            ${campingName}
          </span>
          <span class="right">
            ${moment().format('ddd DD MMM YYYY HH:mm')}
            </span>
        </p>
      <hr/>
      <div class="bui-grid">
        <div class="bui-grid__column-full bui-grid__column-4@medium">
          <div>
            <p class="res-content__label">
              <span>
                Check-in:
              </span>
            </p>
            <p class="res-content__info res-content__info--emphasized">
              <span>
              ${moment(reservation.checkinEstimated).format('ddd DD MMM YYYY')}
              </span>
            </p>
            <p class="res-content__label">
              <span>
                Check-out: (${checkoutTime}:00hs)
              </span>
            </p>
            <p class="res-content__info res-content__info--emphasized">
              <span>
              ${moment(reservation.checkoutEstimated).format('ddd DD MMM YYYY')}
              </span>
            </p>
            <p class="res-content__label">
              <span>
                Duración de la estancia:
              </span>
            </p>
            <p class="res-content__info">
              <span>
                ${reservation.nights} noche${reservation.nights > 1 ? 's' : ''}
              </span>
            </p>
            <p class="res-content__label">
              <span>
                Items:
              </span>
            </p>
            <p class="res-content__info">
              ${components || "-"}
            </p>
            <p class="res-content__label">
              <span>
               ${printSpecificRoomVoucher?"Lugares:":"Número de lugares:"}
              </span>
            </p>
            <p class="res-content__info">
              ${(printSpecificRoomVoucher ? rooms : reservation.roomsList?.length)|| "No asignado"}
            </p>
            <p class="res-content__label">
              <span>
                Precio total:
              </span>
            </p>
            <p class="res-content__info res-content__info--emphasized">
              <span>
                $${total} 
              </span>
            </p>
          </div>
        </div>
        <div class="bui-grid__column-full bui-grid__column-8@medium">
          <div>
            <address class="res-reservation-overview__address">
              <p class="res-content__label">
                <span>
                  Nombre del cliente:
                </span>
              </p>
              <div class="res-content__info res-content__info--emphasized">
                <div class="bui-u-inline">
                  <button class="bui-link bui-link--primary" data-test-id="booker-profile-entry-name-link" id="booker-profile-entry-name-link">
                    <span data-test-id="reservation-overview-name">
                      ${starredCustomer.fullName} 
                    </span>
                  </button>
                </div>
                <!-- <div class="bui-flag">
                  <img src="https://r.bstatic.com/backend_static/common/flags/16/uy.png" class="bui-flag__flag" style="height: auto;">
                  <span class="bui-flag__text">
                    Uruguay
                  </span> -->
                </div>
              </div>
              <span>
              Email: ${starredCustomer.email ?? 'No disponible'}
              </span>
              <br>
              <span>
              Telefono: ${starredCustomer.phone ?? 'No disponible'}
              </span>
              <br>
              <p>
              </p>
            </address>
            <br>
            <br>
            <div class="res-reservation-overview__extra bui-grid bui-grid--align-center">
  
              <div class="bui-grid__column-full bui-grid__column-6@medium">
                <p class="res-content__label bui-u-hidden-print">
                  <span>
                    Canal:
                  </span>
                </p>
                <p class="res-content__info bui-u-hidden-print">
                  <span>
                    ${reservation.way == "Presencial" ? "Mostrador" : CapitalFirst(reservation.way)}
                  </span>
                </p>
              </div>
              <div class="bui-grid__column-full bui-grid__column-6@medium">
                <p class="res-content__label bui-u-hidden-print">
                  <span>
                    Tipo:
                  </span>
                </p>
                <p class="res-content__info bui-u-hidden-print">
                  <span>
                  ${type}
                  </span>
                </p>
              </div>
              <div class="bui-grid__column-full bui-grid__column-6@medium">
                <p class="res-content__label">
                  <span>
                    Pagado:
                  </span>
                </p>
                <p class="res-content__info">
                  ${reservation.percentPayed}%
                </p>
              </div>
              <div class="bui-grid__column-full bui-grid__column-6@medium">
                <p class="res-content__label bui-u-hidden-print">
                  <span>
                    Creado:
                  </span>
                </p>
                <p class="res-content__info bui-u-hidden-print">
                  <span>
                  ${moment(reservation.created).format('ddd DD MMM YYYY, HH:mm')}
                  </span>
                </p>
              </div>
              <div class="bui-grid__column-full bui-grid__column-6@medium">
                <p class="res-content__label">
                  <span>
                    Id de registro:
                  </span>
                </p>
                <p class="res-content__info">
                  <span>
                  ${reservation.reservationId}
                  </span>
                </p>
              </div>
              <div class="bui-grid__column-full bui-grid__column-6@medium">
                <p class="res-content__label">
                  <span>
                    Checkin hecho:
                  </span>
                </p>
                <p class="res-content__info">
                  <span>
                  ${(reservation.checkinMade&&moment(reservation.checkinMade).format('ddd DD MMM YYYY, HH:mm')) ?? 'No'}
                  </span>
                </p>
              </div>
              
              <!-- <div class="bui-grid__column-full bui-grid__column-full@medium">
                <p class="res-content__label">
                  <span>
                    Información importante
                  </span>
                </p>
                <p class="res-content__info res-content-guest-comments">
                  ${reservation.description}
                </p>
              </div> -->
              <hr>
              
            </div>
            
          </div>
        
      </div>
      <div id="content-391ffab3-a22d-31b9-1724-c9632bc89e95" class="bui-accordion__content snipcss0-0-0-1 snipcss-X3AZH">
          <div class="bui-spacer--large snipcss0-1-1-2">
            <hr class="bui-divider snipcss0-2-2-3">
          </div>
          <div class="bui-grid snipcss0-1-1-4">
            <div class="bui-grid__column-full bui-grid__column-4@medium snipcss0-2-4-5">
              <div class="snipcss0-3-5-6">
                <p class="res-room-block-overview__label snipcss0-4-6-7">
                  <span class="snipcss0-5-7-8">
                    Nombre del huésped
                  </span>
                </p>
                <p class="res-room-block-overview__info snipcss0-4-6-9">
                  <span class="snipcss0-5-9-10">
                      ${starredCustomer.fullName}
                  </span>
                </p>
              </div>
            </div>
            <div class="bui-grid__column-full bui-grid__column-8@medium snipcss0-2-4-17 tether-target-attached-top tether-element-attached-top tether-element-attached-center tether-target-attached-center">
              <table class="res-room-block__table bui-table snipcss0-3-17-18">
                <thead class="bui-table__head snipcss0-4-18-19">
                  <tr class="bui-table__row snipcss0-5-19-20">
                    <th class="bui-table__cell bui-table__cell--head snipcss0-6-20-21">
                      <span class="snipcss0-7-21-22">
                        Fecha
                      </span>
                    </th>
                    <th class="bui-table__cell bui-table__cell--head snipcss0-6-20-23">
                      <span class="snipcss0-7-23-24">
                        Descripción
                      </span>
                    </th>
                    <th class="bui-table__cell bui-table__cell--head bui-table__cell--align-end snipcss0-6-20-25">
                      <span class="snipcss0-7-25-26">
                        Precio
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bui-table__body snipcss0-4-18-27">
                ${servicesList.map(service => `
                  <tr class="bui-table__row snipcss0-5-27-28">
                    <th scope="row" class="bui-table__cell bui-table__cell--row-head snipcss0-6-28-29">
                      ${moment(service.date).format('ddd DD MMM YYYY')}
                    </th>
                    <td class="bui-table__cell snipcss0-6-28-30">
                      <div class="snipcss0-7-30-31">
                        ${service.description}
                      </div>
                      <div class="res-fantasyflex-status snipcss0-7-30-32">
                      </div>
                    </td>
                    <td class="bui-table__cell bui-table__cell--align-end snipcss0-6-28-33">
                      <span class="snipcss0-7-33-34">
                        ${service.cost}
                      </span>
                    </td>
                  </tr>
                `).join('')}
                  <tr class="bui-table__row res-room-row-subtotal snipcss0-5-27-35">
                    <th colspan="2" scope="row" class="res-room-total-cell bui-table__cell bui-table__cell--row-head snipcss0-6-35-36">
                      <strong class="snipcss0-7-36-37">
                        <span class="snipcss0-8-37-38">
                          Subtotal
                        </span>
                      </strong>
                    </th>
                    <td class="bui-table__cell bui-table__cell--align-end snipcss0-6-35-39">
                      <strong class="snipcss0-7-39-40">
                        <span class="snipcss0-8-40-41">
                        $${total} 
                        </span>
                      </strong>
                    </td>
                  </tr>
                  <tr class="bui-table__row res-room-row-subtotal snipcss0-5-27-35">
                      <th colspan="2" scope="row" class="res-room-total-cell bui-table__cell bui-table__cell--row-head snipcss0-6-35-36">
                        <strong class="snipcss0-7-36-37">
                          <span class="snipcss0-8-37-38">
                            Pagado
                          </span>
                        </strong>
                      </th>
                      <td class="bui-table__cell bui-table__cell--align-end snipcss0-6-35-39">
                        <strong class="snipcss0-7-39-40">
                          <span class="snipcss0-8-40-41">
                            $${pagadoTotal}
                          </span>
                        </strong>
                      </td>
                    </tr>
                  <tr class="bui-table__row res-room-row-total snipcss0-5-27-42">
                    <th scope="row" colspan="2" class="res-room-total-cell bui-table__cell bui-table__cell--row-head snipcss0-6-42-43">
                      <strong class="snipcss0-7-43-44">
                        <span class="snipcss0-8-44-45">
                          Resta pagar
                        </span>
                      </strong>
                    </th>
                    <td class="bui-table__cell bui-table__cell--align-end snipcss0-6-42-46">
                      <strong class="snipcss0-7-46-47">
                        <span class="snipcss0-8-47-48">
                        $${deudaTotal}
                        </span>
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!---->
        </div>
        
      </div>
      
    </div>
  </body>
  <html>  
  `
)}

export const beforePrint = async () => {
  const dispatch = store.dispatch
  dispatch({ type: "SET_PRINTER_PRINTING", payload: true })
  if (!qz.websocket.isActive()) {
    await connectPrinter()
  }
}

export const afterPrint = async () => {
  const dispatch = store.dispatch
  dispatch({ type: "SET_PRINTER_PRINTING", payload: false })
}

export const billlToCreditNote = bill => {
  return "Nota de credito " + bill.split(" ")[1]
}
export const chooseStarredCustomer = (customersList,starredCustomerId) => {
  if (!customersList) {
    return {}
  }
  const choosedCustomer=customersList?.find(x=>x.customerId == starredCustomerId)||customersList.find(
    x =>
      x.dni ==
      customersList.reduce((a, b) =>
        a.dni < b.dni ? a : b
      ).dni
  )||customersList[0];
  return choosedCustomer;
}

export const printPOS = async (payload, type) => {
  await beforePrint()
  const { printerName, margings,ticketExtraInfo } = store.getState().printerReducer.printer
  let config = qz.configs.create(printerName, {
    margins: margings
  })
  let template = ""
  if (type === "checkin") {
    template = CheckinSignature(payload)
  }
  if (type === "checkout") {
    const choosedCustomer=chooseStarredCustomer(payload.customersList,payload.starredCustomerId);
    template = CheckoutSignature(payload,choosedCustomer)
  }
  if (type === "ticketx") {
    let billingInfo = store.getState().billReducer
    const choosedCustomer=chooseStarredCustomer(payload.reservation.customersList,payload.reservation.starredCustomerId);

    if(ticketExtraInfo){
      for (let info of ticketExtraInfo) {
        if(info.type === 'QR'){
          info.valueQR = await QRCode.toDataURL(info.value);
        }
      }
    }
    const payment =  payload.paymentsList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    }).at(0)

    if(!payment){
      return;
    }
    
    template = TicketX(
      payload.date,
      payment.method,
      [
        {
          name: payload.name,
          quantity: payment.quantity,
          price: payment.amount,
          total: payment.amount,
          description: payload.description,
        },
      ],
      choosedCustomer,
      payload.payExpirationDateTime,
      billingInfo.fantasyName,
      ticketExtraInfo || []
    )
  }
  // if type match Factura
  if (type.match(/Factura/g)) {
    let {
      bill,
      comprobanteNro,
      cae,
      dateExpireCae,
      listProduct,
      afipQR,
      payCondition,
      IVACondition,
      customer
    } = payload
    let billingInfo = store.getState().billReducer
    const products = listProduct.map(x => ({
      name: x.name,
      quantity: x.quantity,
      price: x.price,
      total: x.total,
      description: x.description,
    }))

    if(type === "Factura A"){
    template = TicketFacturaA(
      billingInfo,
      comprobanteNro,
      bill.dateBill,
      payCondition,
      IVACondition,
      products,
      cae,
      dateExpireCae,
      afipQR,
      customer
    )
    }
    if(type === "Factura B"){
      template = TicketFacturaB(
        billingInfo,
        comprobanteNro,
        bill.dateBill,
        payCondition,
        products,
        cae,
        dateExpireCae,
        afipQR,
        customer
      )
    }
    if(type === "Factura C"){
      template = TicketFacturaC(
        billingInfo,
        comprobanteNro,
        bill.dateBill,
        payCondition,
        products,
        cae,
        dateExpireCae,
        afipQR,
        customer
      )
    }
  }
  try {
    console.log(template)
      await qz.print(config, [
        {
          type: "pixel",
          format: "html",
          flavor: "plain",
          data: template,
        },
      ])
  } catch (error) {
    console.log(error)
    alert("Hubo un error y no se pudo imprimir")
  }

  await afterPrint()
}

export const connectPrinter = async () => {
  const dispatch = store.dispatch
  const printer = store.getState().printerReducer.printer
  dispatch({ type: "SET_PRINTER_CONNECTING", payload: true })
  const host = printer.host
  const port = printer.port
  qz.websocket.setErrorCallbacks(() => {
    qz.websocket.disconnect()
  })
  window.onbeforeunload = function() {
    qz.websocket.disconnect()
  }
  try {
    const response = await qz.websocket.connect({
      host: host ? host : "localhost",
      usingSecure: true,
      keepAlive: true,
      port: { secure: [port] },
    })
    qz.websocket.setErrorCallbacks(err => {
      dispatch({ type: "SET_PRINTER_CONNECTED", payload: false })
    })

    console.log("PRINTER CONECTED")
    await dispatch({ type: "SET_PRINTER_CONNECTED", payload: true })
  } catch (error) {
    await dispatch({ type: "SET_PRINTER_CONNECTED", payload: false })
  }
  dispatch({ type: "SET_PRINTER_CONNECTING", payload: false })
}

export const isConsumidorFinal = (totalPayed, payCondition) => {
  const {
    finalConsumerLimit,
    finalConsumerLimitElectronic,
  } = store.getState().billReducer
  if (payCondition == "Efectivo" || payCondition == "Contado") {
    return totalPayed < finalConsumerLimit
  } else {
    return totalPayed < finalConsumerLimitElectronic
  }
}
export const chooseCustomer = customersList => {
  return customersList.sort((a, b) => {
    return moment(a.birthdate).diff(moment(b.birthdate))
  })[0]
}

export const consumdorFinalObject = () => {
  return {
    id: -1,
    fullName: "Consumidor Final",
    age: "",
    isConsumidorFinal: true,
  }
}
export const naObject = () => {
  return {
    id: -2,
    fullName: "N/A",
    age: "",
    isConsumidorFinal: true,
  }
}

//giving two random coords of a rectangle, returns the 4 points of the rectangle
//coordsList example [2493,366,2595,474]
export const getRectanglePoints = coordsList => {
  let [x1, y1, x2, y2] = coordsList
  if (x1 > x2) {
    const aux = x1
    x1 = x2
    x2 = aux
  }
  if (y1 > y2) {
    const aux = y1
    y1 = y2
    y2 = aux
  }
  return [x1, y1, x2, y1, x2, y2, x1, y2]
}

export const findTopRightRectCorner = ({ coords }) => {
  const newCoords = getRectanglePoints(coords)
  const x = newCoords.reduce((acc, cur) => (cur > acc ? cur : acc))
  const y = newCoords.reduce((acc, cur) => (cur < acc ? cur : acc))
  return [x, y]
}

export const findTopRightPolyCorner = ({ coords }) => {
  //find max element in coords
  const max = coords.reduce((acc, cur) => (cur > acc ? cur : acc))
  const min = coords.reduce((acc, cur) => (cur < acc ? cur : acc))
  let topRightCorner = [max, min]
  return topRightCorner
}

export const nightsAndPaymentForCurrentReservation = () => {
  const { reserva } = store.getState().mainReducer
  const { checkinEstimated, servicesList } = reserva

  const servicesNotPreingreso = servicesList.filter(
    x => x.description != "Pre-ingreso"
  )
  let unPayedServices = servicesNotPreingreso.filter(
    x => !x.canceled && x.quantity != 0
  )

  const payedNights =
    unPayedServices.length > 0
      ? Math.floor(
          unPayedServices.reduce(
            (acom, current) =>
              acom +
              (current.due == 0
                ? current.quantity
                : current.paid / current.perNight),
            0
          )
        )
      : 0
  let checkin = moment()
  let nights = 1
  if (moment(checkinEstimated).isSameOrAfter(moment(), "day")) {
    checkin = moment(checkinEstimated)
    nights = payedNights
  } else {
    checkin = moment()
    nights = moment(checkinEstimated)
      .add(payedNights, "days")
      .diff(checkin.format('YYYY-MM-DD'), "days")
  }
  nights = nights > 0 ? nights : 1
  return {
    nights,
    checkin: checkin.format("YYYY-MM-DD"),
  }
}

//Compare components with current reservation components and filter the ones that are more than the current reservation
export const ReturnComponentsExtra = components => {
  const { reserva } = store.getState().mainReducer
  const componentsReservation = reserva.components
    ? JSON.parse(reserva.components)
    : null

  if (!componentsReservation) {
    return {}
  }
  const returnObj = {}
  Object.keys(components).forEach(key => {
    if (componentsReservation[key] < components[key]) {
      returnObj[key] = components[key] - componentsReservation[key]
    }
  })
  return returnObj
}


export const CopyImagenFromHtml = async (html, width, imagenId) => {
  if(!imagenMap[imagenId]){
    const node = document.createElement('div')
    node.crossorigin="anonymous"
    node.innerHTML = html
    node.id = 'image-node'
    node.style.width = `${width}px`
    node.style.padding = "25px"
    node.style.backgroundColor = '#fff !important'
    node.style.color = '#000000 !important'

    document.body.appendChild(node)
    const imageNode = document.getElementById('image-node')
    const blob = await htmlToImage.toBlob(imageNode,{backgroundColor:"#fff",style:{color:"black"},pixelRatio:1,preferredFontFormat:"svg"})   
    document.body.removeChild(node)
    imagenMap[imagenId] = blob
    let data = [new window.ClipboardItem({ [blob.type]: blob })];
    navigator.clipboard.write(data).then(() => {
      NotificationManager.success('Ticket copiado con exito!');
    }).catch(err => {
      alert("Se execedió el tiempo de copiado, intente de nuevo")
    })
  }
  else{  
    const blob = imagenMap[imagenId]
    let data = [new window.ClipboardItem({ [blob.type]: blob })];
    navigator.clipboard.write(data).then(() => {
      NotificationManager.success('Ticket copiado con exito!');
    }, (err) => {
      console.log(err)
    })
  }
}

export const CopyTicketXToClipboard =async (payload) => {
  const dispatch = store.dispatch
  dispatch({ type: "IS_COPYING_TICKET", payload: true })
  try{
    const choosedCustomer = chooseStarredCustomer(payload.reservation.customersList,payload.reservation.starredCustomerId);
    const billingInfo = store.getState().billReducer
    const {printer} = store.getState().printerReducer
    if(printer.ticketExtraInfo){
      for (let info of printer.ticketExtraInfo) {
        if(info.type === 'QR'){
          info.valueQR = await QRCode.toDataURL(info.value);
        }
      }
    }
    
    // find last payment made by date
    const payment = payload.paymentsList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    }).at(0)
    const template = TicketX(
      payload.date,
      payment.method,
      [
        {
          name: payload.name,
          quantity: payment.quantity,
          price: payment.amount,
          total: payment.amount,
          description: payload.description,
        },
      ],
      choosedCustomer,
      payload.payExpirationDateTime,
      billingInfo.fantasyName,
      printer.ticketExtraInfo || []
    )
    await CopyImagenFromHtml(template, 400,payment.paymentId)
  }
  catch(e){
    console.log(e)
    alert("Error al copiar el ticket")
  }
  dispatch({ type: "IS_COPYING_TICKET", payload: false })
}


export const CreateSearchCards = (reservationList, searchText) => {
  return reservationList.map((x, i) => {
    let customerFound = x.customersList.find(y =>
        y.fullName.toLowerCase().includes(searchText) ||
        y.dni?.toLowerCase().includes(searchText))
      
    let ingresoAux
    if (customerFound) {
      ingresoAux = {
        ...x,
        customersList: [
          customerFound,
          ...x.customersList.filter(x => customerFound.customerId != x.customerId),
        ],
      }
    } else {
      ingresoAux = x
    }
    let title = (
      <GetCard
        ingreso={MejorarIngreso(ingresoAux)}
        i={i}
        key={i}
      ></GetCard>
    )
    let description = ""
    return {
      title,
      description,
    }
  })
}

export const getDetailedPaymentsByDate = (servicesList,checkin,checkout,components,nightsDue, isCheckinState) => {
  const { preciosXFecha, tipoPrecio, precios:priceStruct } = store.getState().mainReducer

  const servicesNotPreingreso = servicesList.filter(x => x.description != "Pre-ingreso")
  const unPayedServices = servicesNotPreingreso.filter(x => !x.canceled && x.quantity != 0)

  const payedNights =
    unPayedServices.length > 0
      ? Math.floor(
          unPayedServices.reduce(
            (acom, current) =>
              acom +
              (current.due == 0
                ? current.quantity
                : current.paid / current.perNight),
            0
          )
        ) 
      : 0 

  const totalNight = unPayedServices.length > 0 ? unPayedServices.reduce((acom, current) => acom + current.quantity, 0) : 0

  const dateList = ListaFechas(checkin, checkout)
  dateList.pop()

  // prices
  const keys = Object.keys(components).sort()
  const chargeOnceKeys = priceStruct.find(x => x.type == tipoPrecio).priceList.filter(x => x.chargeOnce).map(x => x.name)
  const keysWithoutChargeOnce = keys.filter(x => !chargeOnceKeys.includes(x))
  
  const dateDescription = dateList.map((x,i) => ({
      date: x,
      isPaid: i < payedNights,
      isDue: i >= payedNights && i < totalNight,
      price: keysWithoutChargeOnce.reduce((acom2, comp) => acom2 + ((preciosXFecha.find(y => y.type == tipoPrecio).precios.find(y => y.name == comp)?.list.find(y => y.fecha == x)?.precio ?? 0) * components[comp]), 0),
    }))

  let acom = 0
  if(isCheckinState && nightsDue > 0){
    for (let date of dateList) {
      if(!dateDescription.find(x => x.date == date).isPaid && acom < nightsDue){
        dateDescription.find(x => x.date == date).isDue = true
        acom++
      }
    }
  }

  return dateDescription
}

const getRoundedValueByCourrency=(currency)=>{
  switch(currency){
    case 'UYU':
      return 1;
    case 'USD':
      return 1;
    case 'BRL':
      return 1;
    default:
      return 10;
  } 
}
export const roundToNearestZero = (number) => {
  const roundDiscount = getRoundedValueByCourrency(store.getState().mainReducer.defaultCurrency)
  return Math.round(number / roundDiscount) * roundDiscount
}


export const printCleaningInAndOut = async ({entran, salen, date}) => {
  const { printerName, margings } = store.getState().printerReducer.printer
  const html = CleaningReportInAndOut({entran, salen, time:CapitalFirst(moment(date).format("dddd DD/MM/YYYY"))});
  let config = qz.configs.create(printerName, {
    margins: margings
  })

  await qz.print(config, [
    {
      type: "pixel",
      format: "html",
      flavor: "plain",
      data: html,
    },
  ])
}

export const printCleaning = async ({label, listado, date}) => {
  const { printerName, margings } = store.getState().printerReducer.printer
  const html = CleaningReport({label, listado, time:CapitalFirst(moment(date).format("dddd DD/MM/YYYY"))});
  let config = qz.configs.create(printerName, {
    margins: margings
  })

  await qz.print(config, [
    {
      type: "pixel",
      format: "html",
      flavor: "plain",
      data: html,
    },
  ])
}

export const printCashWithdraw = async ({withdrawType, concept, amount}) => {
  const { printerName, margings } = store.getState().printerReducer.printer
  const currentTime = moment().format('ddd DD MMM HH:mm:ss')
  const html = CashWithdrawReport({withdrawType, concept, amount, time:currentTime});
  let config = qz.configs.create(printerName, {
    margins: margings
  })
  await qz.print(config, [
    {
      type: "pixel",
      format: "html",
      flavor: "plain",
      data: html,
    },
  ])
}
