import React, { useState, useEffect, useMemo } from "react"
import moment from 'moment'
import { Col, Row, Input, InputGroupText, InputGroup, InputGroupAddon, Container, Button as ButtonR } from 'reactstrap';
import { ListaFechas,nightsAndPaymentForCurrentReservation,AgregarYPagarServicios,CambiarEstadoReserva,chooseStarredCustomer,getDetailedPaymentsByDate, CalcularAfternoonCheckoutTime,CalcularCheckoutTime } from "../../../Funciones/utils"
import DateTimelinePicker from "../extras/dateTimelinepicker"
import { connect } from 'react-redux'
import { Button, Input as SemanticInput, Divider, Icon, Popup } from 'semantic-ui-react'
import { Collapse } from 'react-collapse';
import { useSelector,useDispatch } from 'react-redux'
import ConfirmarPago from './confirmarPago'
import {roundToNearestZero} from '../../../Funciones/utils'

const ButtonsAddComponents=({componentesExtra,componentes,mostrarComponentesExtra})=>{
    const [open,setOpen]=useState(false)
    const [forma,setForma]=useState(false)
    const [makeBill,setMakeBill]=useState(false)
    const {preciosXFecha, tipoPrecio,reserva,paymentLoading,isMobile } = useSelector(x => x.mainReducer)
    const customerList=useSelector(x=>x.mainReducer.reserva.customersList)
    const starredCustomerId=useSelector(x=>x.mainReducer.reserva.starredCustomerId)
    const dispatch = useDispatch()
    const description=useMemo(()=>Object.keys(componentesExtra).reduce((str,key)=>{
        if (componentesExtra[key]>0){
            return str+`${componentesExtra[key]} ${key} `
        }
        return str
    }
    ,""),[componentesExtra])
    const setLoading=(loading)=>dispatch({type:"SET_PAYMENT_LOADING",payload:loading})
    const {nights,checkin}=nightsAndPaymentForCurrentReservation()
    const listaFechas = ListaFechas(checkin, moment(checkin).add(nights-1, 'days'))
    const keys = Object.keys(componentesExtra).sort()
    const precio = listaFechas.reduce((acom, fecha) => acom + keys.reduce((acom2, comp) => acom2 + ((preciosXFecha.find(x => x.type == tipoPrecio).precios.find(x => x.name == comp)?.list.find(x => x.fecha == fecha)?.precio ?? 0) * componentesExtra[comp]), 0), 0)
    if(customerList.length==0){
        return null
    }
    const Guardar=async (setLoadingAux)=>{
        const reservaAux={...reserva,checkoutEstimated:moment(reserva.checkoutEstimated).format()}
        await CambiarEstadoReserva(reservaAux,setLoadingAux,()=>{},dispatch,JSON.stringify(componentes))
    }
    return (
    <>

    <ConfirmarPago noches={nights} 
    makeBill={makeBill}
    setMakeBill={setMakeBill}
    nombreCuenta={[chooseStarredCustomer(customerList,starredCustomerId).fullName]}
    onPay={async()=>{
        
        const servicio={
            components: JSON.stringify(componentesExtra),
            cost: precio,
            date: moment().format(),
            description: `Extra ${nights>1?`${nights} noches`:`${nights} noche`} ${description}`,
            name: "extra",
            paymentsList: [],
            quantity: 0,
        }
        if (forma=="") {
          alert("falta agregar forma de pago")
        }
        else{
          setLoading(true)
          await Guardar(()=>{})
          const reservaAux={...reserva}
          reservaAux.components=JSON.stringify(componentes)
          setTimeout(() => {
             AgregarYPagarServicios(reservaAux.reservationId,servicio,dispatch,setLoading,0,{amount:precio,isRefund:false,date:moment().format(),method:forma},false);
          }, 800);
          setOpen(false)
        }
    }}
    loading={paymentLoading}
    descripcion={description} open={open} 
    setOpen={setOpen} precio={precio} forma={forma} customersList={[]} 
    setForma={setForma} />

    <Container>
        <Row style={{flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
        <Button.Group>
            {mostrarComponentesExtra&&<>
            <Button size={isMobile?'massive':'large'}  color="teal" onClick={()=>setOpen(true)}
              content={`Agregar ${description} $${precio}`} />
            {!isMobile&&<Button.Or text="o" />}
            </>}
            <Button size={isMobile?'massive':'large'}  labelPosition='left' icon='left save' content={"Guardar"} onClick={Guardar.bind(this,setLoading)} />
        </Button.Group>
            {/* <Button
                color='teal'
                
                content={`${description}  $${precio} (${nights>1?`${nights} noches`:`${nights} noche`})`}
                onClick={()=>setOpen(!open)}
                label={{ basic: true, color: 'teal', pointing: 'left', content: 'Pagar',icon:'credit card' }}
                labelPosition='right'
                style={{margin:'0.5em'}}
            />
            <Button
            color='teal'
            content={`${description} `}
            label={{ basic: true, color: 'teal', pointing: 'left', content: 'Guardar',icon:'save' }}
            labelPosition='right'
            style={{margin:'0.5em'}}
        /> */}
        </Row>

    </Container>
    </>
    )
}

export const Precios = ({hasDiscount, precio, setCosto,setHasDiscount, setPrecio, fechaInicio, fechaFin, setDescripcion, setComponentes, componentes, noches, setDiscount}) => {
    const { isMobile, preciosXFecha,reserva, tipoPrecio, precios:priceStruct } = useSelector(x => x.mainReducer)
    const orderRankPrice = priceStruct?.find(x => x.type == tipoPrecio)?.priceList.reduce((acom, comp) => ({...acom,[comp.name]:comp.orderRank ?? 100}), {})
    useEffect(() => {
        for (const name in componentes) {
            if(componentes[name] > 0){
                const componentWithDiscount = priceStruct?.find(x => x.type == tipoPrecio)?.priceList?.find(x => x.name == name)?.totalPriceDiscount
                if(componentWithDiscount){
                    setDiscount(componentWithDiscount)
                    setCosto(roundToNearestZero(Math.floor(precio * (1 - (componentWithDiscount / 100)))))
                    setHasDiscount(true)
                }
            }
        }
    },[reserva]) 

    useEffect(() => {
        const listaFechas = ListaFechas(fechaInicio, moment(fechaFin).add(noches==0?0:-1, 'days'))
        const keys = Object.keys(componentes).sort()
        const chargeOnceKeys = priceStruct.find(x => x.type == tipoPrecio).priceList.filter(x => x.chargeOnce).map(x => x.name)
        const keysWithoutChargeOnce = keys.filter(x => !chargeOnceKeys.includes(x))

        const precio = 
        listaFechas.reduce((acom, fecha) => acom + keysWithoutChargeOnce.reduce((acom2, comp) => acom2 + ((preciosXFecha.find(x => x.type == tipoPrecio).precios.find(x => x.name == comp)?.list.find(x => x.fecha == fecha)?.precio ?? 0) * componentes[comp]), 0), 0)+
        chargeOnceKeys.reduce((acom2, comp) => acom2 + ((preciosXFecha.find(x => x.type == tipoPrecio).precios.find(x => x.name == comp)?.list.find(x => x.fecha == listaFechas.at(0))?.precio ?? 0) * (componentes[comp]|| 0)), 0)

        setPrecio(parseInt(precio))
        setDescripcion(keys.reduce((acom, current) => acom + (componentes[current] > 0 ? `${/^\d+$/.test(current[0])?``:`${componentes[current]} `}${current} ` : ""), ""))
    }, [componentes, noches, fechaInicio])
    const componentesAux = JSON.parse(JSON.stringify(componentes));

    const handleAddComponent = (name) => {
        const componentWithDiscount = priceStruct?.find(x => x.type == tipoPrecio).priceList?.find(x => x.name == name)?.totalPriceDiscount
        if (componentWithDiscount){
            setDiscount(componentWithDiscount)
            setCosto(roundToNearestZero(Math.floor(precio * (1 - (componentWithDiscount / 100)))))
            setHasDiscount(true)
        }
        componentesAux[name] = componentesAux[name] + 1
        setComponentes(componentesAux)
    }
    const handleRemoveComponent = (name) => {
        const componentWithDiscount = priceStruct?.find(x => x.type == tipoPrecio).priceList?.find(x => x.name == name)?.totalPriceDiscount
            
        if (componentesAux[name] === 0) return

        if(componentWithDiscount && componentesAux[name] === 1 && hasDiscount){
            setCosto(precio)
            setDiscount(0)
            setHasDiscount(false)
        }
        componentesAux[name] = componentesAux[name] - 1
        setComponentes(componentesAux)
    }
    return (
        <React.Fragment>
            <Container>
                <Row>
                    {Object.keys(componentes).sort((a,b)=>orderRankPrice[a]-orderRankPrice[b]).map((x, i) => {
                        const componentWithDiscount = priceStruct?.find(y => y.type == tipoPrecio)?.priceList?.find(y => y.name == x)?.totalPriceDiscount
                        const price = componentWithDiscount?`-`:`$${preciosXFecha.find(y => y.type == tipoPrecio).precios.find(y => y.name == x)?.list.find(y => y.fecha == fechaInicio)?.precio ?? 0}`
                        const priceTitle = `${x.toUpperCase()} ${componentWithDiscount&&`(${componentWithDiscount}%)` || ''}`
                        return(
                        <Col key={i} xs={12} md={3} style={{ backgroundColor: componentesAux[x] != 0 ? '#00b5ad' : '#fff', borderRadius: '5%',border:'2px solid white' }}>{
                            <React.Fragment>
                                <Col xs={12} style={{ textAlign: 'center', size: '16px', fontWeight: 'bold', color: componentesAux[x] != 0 ? '#fff' : 'black' }}>{priceTitle}<br />{price}</Col>
                                <Col xs={12}>
                                    <div className="d-flex justify-content-center">
                                        <SemanticInput placeholder='Cantidad'>
                                            <Icon onClick={handleRemoveComponent.bind(this,x)} name='minus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginRight: isMobile ? '20px' : '10px', color: componentesAux[x] != 0 ? '#fff' : 'black' }} link position={'left'} />
                                            <input style={{ marginBottom: '10px', textAlign: 'center', width: '100px', fontSize: '16px' }} value={componentesAux[x]} onChange={(e) => { if (e.target.value > 0) { componentesAux[x] = e.target.value; setComponentes(componentesAux); } }} />
                                            <Icon onClick={handleAddComponent.bind(this,x)} name='plus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginLeft: isMobile ? '20px' : '10px', color: componentesAux[x] != 0 ? '#fff' : 'black' }} link />
                                        </SemanticInput>
                                    </div>
                                </Col>
                            </React.Fragment>
                        }
                        </Col>
                        )
                    })}
                </Row>
            </Container>
        </React.Fragment>
    )
}

export const PreciosComponent = ({mostrarComponentesExtra,mostrarExtraGuardar,componentesExtra,vistaCompleta,setHora,hora,listaHorarios,modificarFechaIngreso, fechaIngreso,setFechaIngreso,componentes,setComponentes,reserva,costo, setCosto,setFechaSalida,servicesList,setServicio,ingreso, precios, isMobile, calcularPrecio }) => {
    const [tipo, setTipo] = useState("noches")
    const [precio, setPrecio] = useState(0)
    const [quantity, setQuantity] = useState(1)
    const [descuento, setDescuento] = useState(0)
    const [descripcion, setDescripcion] = useState("")
    const [descripcionPrecios, setDescripcionPrecios] = useState("")
    const [hasDiscount, setHasDiscount] = useState(false);
    const [abrirComponentes, setAbrirComponentes] = useState(true);
    const serviciosLimpios=servicesList.filter(x =>!x.canceled);
    const discountFunction = useSelector(x => x.mainReducer.discountFunction)
    const afternoonCheckoutTime = useSelector(x => x.mainReducer.afternoonCheckoutTime)
    const defaultCurrency = useSelector(x => x.mainReducer.defaultCurrency)
    const checkoutTime = useSelector(x => x.mainReducer.checkoutTime)
    const primerPago=serviciosLimpios.length == 0||
    (serviciosLimpios.length == 1&&serviciosLimpios[0].name=="preingreso");
    useEffect(() => {
        if (primerPago) {
            setQuantity(ingreso.nights)
            setTipo('noches')
            setAbrirComponentes(true);
        }
        else if (isMobile) {
            //setFechaSalidaAnterior(ingreso.checkoutEstimated)
            setAbrirComponentes(false);
        }
        setFechaIngreso(moment(ingreso.checkinEstimated).format("YYYY-MM-DD"))
    }, [reserva])

    useEffect(() => {
        setCosto(roundToNearestZero(parseInt(precio * (hasDiscount ? (1 - (descuento / 100)) : 1))))
    }, [quantity,precio])
    

    useEffect(() => {
        if (quantity < 0) {
            //alert("Las noches tienen que ser positivas")
            setQuantity(0)
        }
        if (tipo == "noches" || tipo == "") {
            setDescripcion(`${quantity==0?'Pasar el dia':` ${quantity} ${(quantity == 1) ? "noche" : "noches"}`} ${descripcionPrecios}${descuento != 0 ? `[${descuento}% Desc]` : ""}`)
        }
        //alert('cambio ingreso')
    }, [quantity, reserva, descripcionPrecios, descuento])

    useEffect(() => {
        if (costo < 0) {
            //alert("El importe tiene que ser positivo")
            setCosto(0)
        }
    }, [costo])

    useEffect(() => {
        if (ingreso.nuevaReserva&&setHora&&quantity==0) {
            setHora(`${afternoonCheckoutTime}:00`)
        }
    }, [quantity])

    useEffect(() => {
        if (calcularPrecio) {
            setQuantity(GetNoches(moment(fechaIngreso).add(1, 'days').format('YYYY-MM-DD')))
        }
    }, [reserva, fechaIngreso])

    useEffect(() => {
        setServicio({ cost: costo, quantity:quantity, name: tipo, description: descripcion, components: JSON.stringify(componentes) ,paymentsList:[{amount:costo,method:'efectivo',isRefund:false,date:moment().format()}]})
    },[costo,quantity,descripcion,componentes])

    useEffect(() => {
        setFechaSalida(moment(primerPago ? fechaIngreso : ingreso.checkoutEstimated).add(quantity, 'days').format('YYYY-MM-DD'))
    },[quantity,fechaIngreso,ingreso])

    useEffect(() => {
        if(Boolean(discountFunction)){
            var func = new Function("nights ", discountFunction);
            const discountAcom = func(quantity);
            setDescuento(discountAcom)
            setCosto(roundToNearestZero(Math.floor(precio * (1 - (discountAcom / 100)))))
            setHasDiscount(discountAcom != 0)
        }
    },[quantity])
    const detailPaymentByDate = useMemo(()=>getDetailedPaymentsByDate(reserva.servicesList,reserva.checkinEstimated,moment(reserva.checkinEstimated).add(200,"days"),componentes||{}, reserva.nightsDue, reserva.state == 'checkin'), [reserva.servicesList,reserva.checkinEstimated,componentes,reserva.nightsDue,reserva.state])
    const payedDates = detailPaymentByDate.filter(x=>x.isPaid).map(x=>x.date)
    const deltaCheckin = moment(ingreso.checkinEstimated).add(payedDates.length,"day").format('YYYY-MM-DD')
    const fSalida = moment(primerPago ? fechaIngreso : deltaCheckin).add(quantity, 'days').format('YYYY-MM-DD');
    const deltaEntrada = moment(primerPago ? fechaIngreso : deltaCheckin).format('YYYY-MM-DD');
    const GetNoches = (fSalida) =>moment(fSalida).diff(moment(deltaEntrada).format('YYYY-MM-DD'), "days")
    const unpayedDates = detailPaymentByDate.filter(x=>x.isDue).map(x=>x.date)
    const pricePerDate = detailPaymentByDate.reduce((a,b)=>({...a,[b.date]:b.price}),{})
    const selectedDates = ListaFechas(deltaEntrada, fSalida)
    selectedDates.pop()
    
    // Media estadia
    const isMediaEstadia = parseInt(hora) >= afternoonCheckoutTime;
    const checkSetHour = ()=>setHora(!isMediaEstadia ? `${afternoonCheckoutTime}:00`:`${checkoutTime<10?`0${checkoutTime}`:checkoutTime}:00`)
    return (
        <React.Fragment>
            <Collapse isOpened={abrirComponentes}>
                {componentes && <Precios hasDiscount={hasDiscount} precio={precio} setCosto={setCosto} setHasDiscount={setHasDiscount} discount={descuento} setDiscount={setDescuento} fechaInicio={deltaEntrada} noches={quantity} fechaFin={fSalida} ingreso={ingreso} precios={precios} setPrecio={setPrecio} setDescripcion={setDescripcionPrecios} setComponentes={setComponentes}
                    componentes={componentes} />}
            </Collapse>
            
         
            {abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle up' size='small' /></Divider>}
            {!abrirComponentes && <Divider horizontal><Icon onClick={() => { setAbrirComponentes(!abrirComponentes) }} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle down' size='small' /></Divider>}
            {mostrarExtraGuardar && <ButtonsAddComponents componentesExtra={componentesExtra} mostrarComponentesExtra={mostrarComponentesExtra} componentes={componentes}/>}
            <Row>
                {vistaCompleta&&<Col xs={12} md={{ offset: 1, size: 3 }}>
                    <h3 style={{ textAlign: 'center' }}>{tipo == 'noches' ? 'Agregar noches' : 'Cantidad'}</h3>
                    <Col xs={12}>
                        <div className="d-flex justify-content-center">
                            <SemanticInput placeholder='Cantidad'>
                                <Icon onClick={() => {
                                    if (quantity > 0) {
                                        setQuantity(parseInt(quantity) - 1)
                                    }
                                }} name='minus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginRight: isMobile ? '20px' : '10px', color: "black" }} link position={'left'} />
                                <input type="number" pattern="[0-9]*" style={{ marginBottom: '10px', textAlign: 'center', width: '120px', fontWeight: '800', fontSize: "18px" }} value={quantity} onChange={(e) => { setQuantity(e.target.value || 0) }} />
                                <Icon onClick={() => { setQuantity(parseInt(quantity) + 1) }} name='plus' style={{ fontSize: isMobile ? '28px' : '20px', marginTop: '10px', marginLeft: isMobile ? '20px' : '10px', color: "black" }} link />
                            </SemanticInput>
                        </div>
                    </Col>

                </Col>}
                <Col xs={12} md={4} >
                    <h3>Descripcion</h3><Input type="textarea" value={descripcion} onChange={(e) => { setDescripcion(e.target.value) }}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <Row style={{ marginBottom: "10px", marginTop: "10px" }}>
                        <Col xs={5}> <h3>Valor</h3></Col>
                        {<Col xs={7}> <h3 style={{ float: "right" }}>${parseInt((precio * (hasDiscount ? (1 - (descuento / 100)) : 1) / (quantity == 0 ? 1 : quantity)))} x noche</h3></Col>}
                    </Row>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input type="number" pattern="[0-9]*" style={{ fontSize: "26px", fontWeight: 800 }} value={costo} onChange={(e) => {
                            if (hasDiscount) {
                                const descuent = 100 - Math.ceil(100 * e.target.value / (precio));
                                setDescuento(descuent < 0 ? 0 : descuent);
                            }
                            setCosto(parseInt(e.target.value))
                        }
                        } />
                        <InputGroupAddon addonType="append">
                            <ButtonR 
                                id={"buttonDisc"} style={{ float: "right" }} onClick={() => {
                                    setHasDiscount(!hasDiscount)
                                    const num = descuento == 0 ? 10 : 0;
                                    setDescuento(num);
                                    const descuent = num > 100 ? 100 : num;
                                    setCosto(roundToNearestZero(parseInt(precio * (1 - (descuent / 100)))))
                                }} color="info" size={"lg"}
                            >%</ButtonR>
                        </InputGroupAddon>
                        {descuento != 0 &&
                            <Input type="number" pattern="[0-9]*" style={{ fontSize: "26px", fontWeight: 800 }} value={descuento} onChange={(e) => {
                                const descuent = e.target.value > 100 ? 100 : e.target.value;
                                setCosto(roundToNearestZero(parseInt(precio * (1 - (descuent / 100)))))
                                setDescuento(descuent)
                            }
                            } />
                        }
                    </InputGroup>
                </Col>
            </Row>
            <br></br>
            <DateTimelinePicker defaultCurrency={defaultCurrency} selectedDates={selectedDates} pricePerDate={pricePerDate} payedDates={payedDates} unpayedDates={unpayedDates} startDate={detailPaymentByDate.at(0).date} endDate={detailPaymentByDate.slice(0,isMobile?4:14).at(-1).date}/>
            <br></br>
            <Row>
                {vistaCompleta&&<Col xs={12} md={{ size: 3, offset: 2 }}> <h3>Pagar desde</h3><Input disabled={!calcularPrecio&&!modificarFechaIngreso} type="date" value={deltaEntrada} onChange={(e) => {setFechaSalida(moment(e.target.value).add(1, 'days').format('YYYY-MM-DD')); setFechaIngreso(e.target.value)}}></Input></Col>}
                {vistaCompleta&&<Col xs={12} md={{ size: 3 }}> <h3>Pagar hasta</h3><Input type="date" value={fSalida} onChange={(e) => {
                    setQuantity(GetNoches(e.target.value))
                }}></Input>
                </Col>}
                {hora&&<Col xs={12} md={{ size: 2}}> <h3>Hora de salida</h3><Input type="select" color value={hora} onChange={(e) => setHora(e.target.value)}>{listaHorarios.map((x,i) => <option key={i}>{x}</option>)}</Input></Col>}
                <Col xs={12} md={{ size: 2 }} style={{alignSelf:'end', cursor:"pointer"}}> 
                    <Popup
                    trigger={<Icon onClick={checkSetHour} color={isMediaEstadia?"teal":"grey"} size='large' name='clock' circular />}
                    content='Media estadia'
                    position='bottom center'
                    />
                </Col>
            </Row>

        </React.Fragment>

    )
}



export default connect(state => ({reserva:state.mainReducer.reserva,listaDeptos: state.mainReducer.listaDeptos, listaReservas: state.mainReducer.listaReservas, refrescarDispoYPrecios: state.mainReducer.refrescarDispoYPrecios, precios: state.mainReducer.precios, isMobile: state.mainReducer.isMobile }), null)(PreciosComponent)

