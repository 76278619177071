import React from "react"
import { takeLatest, put, select, takeLeading, delay, take, fork, cancel } from "redux-saga/effects"
import moment from "moment"
import { API, graphqlOperation } from "aws-amplify"
import { NotificationManager } from 'react-notifications';
import {
  reservationList,
  getStatusList,
  roomsList,
  getAllPrices,
  getPricesv2,
  getAllCashMovements,
  lastBuild,
  lastMovement,
  reservation,
  search as searchReservations,
  getUserInfo,
  getCheckoutList,
  getMonthStatistics,
  billsByReservation,
  getPromotions
} from "../graphql/queries"
import {
  addPrice,
  addCashMovement,
  makeBill,
  modifyPriceLastChange,
  upsertPromotion as upsertPromotionMutation,
  deletePromotion as deletePromotionMutation,
} from "../graphql/mutations"
import {
  ListaFechas,
  MejorarIngreso,
  CalcularCheckoutTime,
  ObtenerListaPagosFechas,
  Normalizar,
  connectPrinter,
  printPOS,
  billlToCreditNote,
  ObtenerListaBills,
  AgregarServicioAReserva,
  AgregarPagoAReserva,
  CancelarServicio,
  CreateSearchCards
} from "../Funciones/utils"
import networkFirst from "../components/servicios/cache/networkFirst"
import { queueCache } from "../components/servicios/cache";
import { GetReservationList, GetLastChange, GetLastPriceChange, GetBannedCustomer, GetBanList } from "../components/servicios/get"
import { setNewReservation,setModifyReservation, setAddService, setAddPayment, setCancelService, setNewBan, setModifyBan, setDeleteBan, setModifyUserGroup } from "../components/servicios/set"
import { v4 as uuidv4 } from 'uuid';
import joinCacheWithReservation from "../components/servicios/joinWithCache"

export const ArreglarServicesList = servicesList => {
  servicesList = servicesList.map(y => ({
    ...y,
    paymentsList: y.paymentsList ? y.paymentsList : [],
  }))
  servicesList = servicesList.map(x => {
    return {
      ...x,
      perNight: x.quantity == 0 ? x.cost : Math.round(x.cost / x.quantity),
      paid: x.paymentsList
        .filter(x => !x.isRefund)
        .reduce((a, b) => a + b.amount, 0),
      due:x.cost -x.paymentsList.filter(x => !x.isRefund).reduce((a, b) => a + parseInt(b.amount), 0) +
      x.paymentsList.filter(x => x.isRefund).reduce((a, b) => a + parseInt(b.amount), 0),
    }
  })

  return servicesList
}

export const GetPercentPayed = servicesList => {
  servicesList = servicesList.filter(x => !x.canceled)
  //Calcular porcentaje pagado
  let percentPayed = 0
  let costoTotal =
    servicesList.length != 0
      ? servicesList.map(x => x.cost).reduce((a, b) => a + b)
      : 0
  let pagado =
    servicesList.length != 0
      ? servicesList
          .map(x =>
            x.paymentsList.map(y => y.amount).reduce((a, b) => a + b, 0)
          )
          .reduce((a, b) => a + b)
      : 0
  percentPayed = pagado / costoTotal
  percentPayed = Math.round(percentPayed * 100)
  if (costoTotal == 0) {
    percentPayed = 100
  }
  //Si no hay nada pagado que diga que no pago nada
  if (servicesList.length == 0) {
    percentPayed = 0
  }
  if (percentPayed > 100) {
    percentPayed = 100
  }
  return percentPayed
}

//   const { newReservationsQueue, modifyReservationsQueue, servicesQueue, cancelServicesQueue, paymentsQueue, vehiclesQueue } = yield select(x=>x.queueReducer)
function* loadQueues() {
  console.time("loadQueues")
  yield queueCache.initAllQueues();
  console.timeEnd("loadQueues")
}

function* reloadAll() {
  yield put({ type: "MAIN_LOADING", payload: true })
  yield* reloadRooms()
  yield put({ type: "MAIN_LOADING", payload: false })
  // yield* reloadReservations()
}

function* reloadReservations(payload) {
  const {
    lastChange: lastChangeAux,
    tipoPrecio: type,
    loadingBar,
    pageListPreReservation,
    roomsTypesChoosed
  } = yield select(x => x.mainReducer)
  if (roomsTypesChoosed.length == 0 || loadingBar) {
    yield put({ type: "SET_LOADING_BAR", payload: false })
    return ""
  }
  yield put({ type: "SET_LOADING_BAR", payload: true })

  const changeType = payload?.changeType
  try {

    //Corroboramos que haya cambios antes de actualizar
    let lastChangeDynamo = yield GetLastChange();
    if (lastChangeDynamo != lastChangeAux || changeType) {
      yield put({type: "SET_LAST_CHANGE",payload: lastChangeDynamo})
      try {
        const listaReservas = yield GetReservationList(roomsTypesChoosed);
        const listaReservasConCache = joinCacheWithReservation(listaReservas,type);
        yield put({ type: "LISTA_RESERVAS", payload: listaReservasConCache })
        yield put({ type: "RELOAD_CASH_MOVEMENT" })
        yield put({ type: "RELOAD_STATISTICS_BY_DATE" })
        yield put({ type: "RELOAD_PRE_RESERVATION", payload: {page:pageListPreReservation} })

      } catch (error) {
        console.log("Failed to fetch reservations:", error)
        //alert("Error al cargar reservas, revise su conexion a internet");
      }
    }
    yield put({ type: "SET_LOADING_BAR", payload: false })
  } catch (error) {
    try {
      console.log(`Error ${error}`)
      console.log(`Error ${JSON.stringify(error)}`)
    } catch (error) {
      console.log(`Error ${error}`)
    }
  }
}

function* reloadReservationList({payload}) {
  const {listaGeneralReservas, precios } = yield select(
    x => x.mainReducer
  )
  if (payload && listaGeneralReservas) {
    const precioElegido = precios.find(x => x.type == payload)
    if (precioElegido) {
      yield put({ type: "MAIN_LOADING", payload: true })
      yield* reloadReservations({ changeType: true })
      yield put({ type: "MAIN_LOADING", payload: false })
      yield put({ type: "CHANGE_PRICE_TYPE_SUCCESS" })
    }
  }
}

function* reloadDeptosList() {
  const { tipoPrecio, listaGeneralDeptos, roomsTypesChoosed } = yield select(x => x.mainReducer)
  if (tipoPrecio && listaGeneralDeptos) {
    yield put({
      type: "SET_LISTA_DEPTOS",
      payload: listaGeneralDeptos.filter(x => roomsTypesChoosed.includes(x.type)),
    })
  }
}

function* reloadCheckout() {
  const { tipoPrecio, precios } = yield select(x => x.mainReducer)

  if (precios && tipoPrecio && precios.length > 0) {
    const currentPrice = precios.find(x => x.type == tipoPrecio)
    yield put({ type: "SET_CHECKOUT_TIME", payload: currentPrice.checkoutTime })
    if(currentPrice.afternoonCheckoutTime ){
      yield put({ type: "SET_AFTERNOON_CHECKOUT_TIME", payload: currentPrice.afternoonCheckoutTime })
    }
    yield put({
      type: "SET_PRE_RESERVATION",
      payload: currentPrice.preReservation,
    })
    yield put({ type: "SET_WITHOUT_PLACE", payload: currentPrice.withoutPlace })
    yield put({ type: "SET_DIA_PLAYA", payload: currentPrice.diaPlaya })
    yield put({
      type: "SET_IS_PRE_RESERTVATION",
      payload: currentPrice.isPreReservation,
    })
    if (currentPrice.userToken) {
      yield put({
        type: "SET_API_BILL_SESSION",
        payload: {
          userToken: currentPrice.userToken,
          apiKey: currentPrice.apiKey,
          apiToken: currentPrice.apiToken,
          pointSell: currentPrice.pointSell,
          billTypes: currentPrice.billTypes,
        },
      })
    }
    //yield put({type:'SET_OTAS',payload:currentPrice.otas??[]});
  }
}

function* reloadRooms() {
  //Actualiza el título del documento usando la API del navegador
  const response = yield networkFirst("reloadRooms", async () => {
  return await API.graphql({
    query: roomsList,
    variables: {},
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })})

  const listaDeptos = response.data.roomsList
  yield put({ type: "SET_LISTA_GENERAL_DEPTOPS", payload: listaDeptos })
}

function* listaReservas({ payload }) {
  
  if (payload == undefined) {
    const listaReservas = yield select(x => x.mainReducer.listaReservas)
    payload = listaReservas
  }
  const payloadAux = payload.map(x => MejorarIngreso(x))

  yield put({ type: "SET_LISTA_GENERAL_RESERVAS", payload: payloadAux })
  yield put({ type: "SET_SEARCH_RESULT",  payload: []})
}

//Convierte precios de lista a objeto para buscar mas rapido
function* reloadPricesByDate({ payload: { typeList, from, to } }) {
  let acom = 0
  let typePriceList = []
  let otaList = []
  let promiseList = []
  let otaItems = []
  for (let type of typeList) {
    if (type.otas && type.otas.length > 0) {
      for (let ota of type.otas) {
        const newType = JSON.parse(JSON.stringify(type))
        newType.type = `${type.type}-${ota.type}`
        newType.name = ota.name
        otaItems = [...otaItems, newType]
      }
    }
  }

  const typeAux = [...typeList, ...otaItems]
  for (let type of typeAux) {
    
    const fetchPrices=networkFirst(`fetchPrices-${type.type}`, async () => {
      return await API.graphql(graphqlOperation(getAllPrices, { type: type.type, from, to })
    )})
      
    promiseList = [
      ...promiseList,
      fetchPrices
    ]
  }

  const responseList = (yield Promise.all(promiseList)).map(
    x => x.data.getAllPrices
  )
  for (let response of responseList) {
    const type = typeAux[acom].type.split("-")[0]
    const realType = typeAux[acom].type

    const priceList = response
    const obj = {}
    const availability = {}
    const autoUpdate = {}
    const minStay = {}
    const maxStay = {}
    for (let priceItem of priceList) {
      const p = {}
      const { date, price } = priceItem
      if (price) {
        const priceParsed = JSON.parse(price)
        for (const value of Object.keys(priceParsed)) {
          p[value] = priceParsed[value]
        }
        obj[date] = p
      }

      availability[date] = priceItem.availability
      autoUpdate[date] = priceItem.autoUpdateAvailability
      minStay[date] = priceItem.minStay
      maxStay[date] = priceItem.maxStay
    }
    const listaFechas = ListaFechas(from, to)
    const precios = yield select(x => x.mainReducer.precios)
    //Manana arreglar esta parte
    const priceListAux = precios.find(y => y.type == type).priceList
    const isOnline = precios.find(y => y.type == type).isOnline
    const ret = priceListAux.map(x => {
      return {
        name: x.name,
        list: listaFechas.map(y => ({
          fecha: y,
          precio: obj[y] ? (obj[y][x.name] ? obj[y][x.name] : 0) : 0,
        })),
      }
    })
    const availabilityRet = listaFechas.map(x => ({
      fecha: x,
      disponibilidad: availability[x] ?? 0,
    }))
    const autoAjustarRet = listaFechas.map(x => ({
      fecha: x,
      autoAjustar: autoUpdate[x] ?? 0,
    }))
    const estadiaMinimaRet = listaFechas.map(x => ({
      fecha: x,
      estadiaMinima: minStay[x] ?? 0,
    }))
    const estadiaMaximaRet = listaFechas.map(x => ({
      fecha: x,
      estadiaMaxima: maxStay[x] ?? 0,
    }))
    if (type == realType) {
      typePriceList = [
        ...typePriceList,
        {
          precios: ret,
          disponibilidad: availabilityRet,
          type: realType,
          isOnline,
          isOta: false,
          autoAjustar: autoAjustarRet,
          estadiaMinima: estadiaMinimaRet,
          estadiaMaxima: estadiaMaximaRet
        },
      ]
    } else {
      const name = Normalizar(typeAux[acom].name)
      otaList = [
        ...otaList,
        {
          precios: ret,
          disponibilidad: availabilityRet,
          type: realType,
          name,
          isOnline,
          isOta: true,
          autoAjustar: autoAjustarRet,
          estadiaMinima: estadiaMinimaRet,
          estadiaMaxima: estadiaMaximaRet
        },
      ]
    }
    acom++
  }

  yield put({ type: "PRECIOS_X_FECHA", payload: typePriceList })
  yield put({ type: "PRECIOS_X_FECHA_OTA", payload: otaList })
  //yield put({type:'DISPONIBILIDAD_X_FECHA',payload:availabilityRet});
}

//Reloads only if lastpricechange is different
function* reloadPriceList() {
  const { lastPriceChange } = yield select(x => x.mainReducer)
  const priceLastChangeResponse = yield GetLastPriceChange();

  if (priceLastChangeResponse != lastPriceChange) {
    yield put({
      type: "SET_LAST_PRICE_CHANGE",
      payload: priceLastChangeResponse,
    })
    const daysFromNowToElevenMonths = moment()
      .add(11, "months")
      .endOf("month")
      .diff(moment(), "days")
    yield reloadPricesByDate({
      payload: {
        typeList: yield select(x => x.mainReducer.precios),
        from: moment()
          .add(-60, "days")
          .format("YYYY-MM-DD"),
        to: moment()
          .add(daysFromNowToElevenMonths, "days")
          .format("YYYY-MM-DD"),
      },
    })
  }
}

//Convierte precios de lista a objeto para buscar mas rapido
function* initPrices({ payload: { from, to } }) {
  yield put({ type: "MAIN_LOADING", payload: true })
  try{
    const response = yield networkFirst("getPricesv2",async () => {
      return await API.graphql({
        query: getPricesv2,
        variables: {},
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
    } )
    
    const prices = JSON.parse(response.data.getPricesv2)
    const json = JSON.parse(prices.json)
    yield put({ type: "SET_PRECIOS", payload: json })
    yield put({ type: "CHANGE_PRICE_TYPE", payload: json[0].type })
    yield put({ type: "SET_BAN_STATUS", payload: {userGroup: prices.userGroup} })
    yield* loadQueues();
    yield* reloadAll();
    yield* reloadPricesByDate({ payload: { typeList: json, from, to } });
    yield* reloadCheckout();
    sendAllQueues();
  }
  catch(error){
    // Auth.signOut()
    console.log(error)
  }

  yield put({ type: "MAIN_LOADING", payload: false })

}

//Convierte precios de lista a objeto para buscar mas rapido
function* modifyAutoUpdate({
  payload: { from, to, autoUpdateAvailability,minStay,maxStay, tipoPrecio },
}) {
  yield put({ type: "PRICES_LOADING", payload: true })
  const { preciosXFecha, preciosXFechaOta } = yield select(x => x.mainReducer)
  const preciosXFechaTotal = [...preciosXFecha, ...preciosXFechaOta]

  try {
    let listaPromesas = []
    const listaFechas = ListaFechas(from, to)
    for (let fecha of listaFechas) {
      const prices = {}
      for (let precio of preciosXFechaTotal.find(x => x.type == tipoPrecio)
        .precios) {
        const pAux = precio.list.find(x => x.fecha == fecha)?.precio
        if (pAux != undefined && pAux != 0) {
          prices[precio.name] = pAux
        }
      }
      let availabilityAux = preciosXFechaTotal
        .find(x => x.type == tipoPrecio)
        .disponibilidad.find(x => x.fecha == fecha).disponibilidad

      let autoUpdateAvailabilityAux = autoUpdateAvailability?autoUpdateAvailability:preciosXFechaTotal
      .find(x => x.type == tipoPrecio)
      .autoAjustar.find(x => x.fecha == fecha).autoAjustar;

      let minStayAux = minStay?minStay:preciosXFechaTotal
        .find(x => x.type == tipoPrecio)
        .estadiaMinima.find(x => x.fecha == fecha).estadiaMinima

      let maxStayAux = maxStay?maxStay:preciosXFechaTotal
        .find(x => x.type == tipoPrecio)
        .estadiaMaxima.find(x => x.fecha == fecha).estadiaMaxima

      
      const response = API.graphql({
        query: addPrice,
        variables: {
          type: tipoPrecio,
          availability:availabilityAux,
          autoUpdateAvailability: autoUpdateAvailabilityAux,
          minStay: minStayAux,
          maxStay: maxStayAux,
          date: fecha,
          price: JSON.stringify(prices),
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
      listaPromesas = [...listaPromesas, response]
    }

    const rta = yield Promise.all(listaPromesas)
    //Recarga la nueva disponibilidad
    const newAvailabilities = rta.map(x => x.data.addPrice)
    for (let newAvailability of newAvailabilities) {
      preciosXFechaTotal
        .find(x => x.type == tipoPrecio)
        .autoAjustar.find(x => x.fecha == newAvailability.date).autoAjustar =
        newAvailability.autoUpdateAvailability

      preciosXFechaTotal
        .find(x => x.type == tipoPrecio)
        .estadiaMinima.find(x => x.fecha == newAvailability.date).estadiaMinima =
        newAvailability.minStay

      preciosXFechaTotal
        .find(x => x.type == tipoPrecio)
        .estadiaMaxima.find(x => x.fecha == newAvailability.date).estadiaMaxima =
        newAvailability.maxStay

    }
    yield API.graphql(graphqlOperation(modifyPriceLastChange))
  } catch (error) {
    if (error?.errors[0].message == "Network Error") {
      alert("Error: No hay conexion a internet")
    } else {
      alert("Error al modificar auto ajuste")
    }
  }
  yield put({ type: "PRICES_LOADING", payload: false })
}

//Convierte precios de lista a objeto para buscar mas rapido
function* modifyAvailability({
  payload: { from, to, availability, tipoPrecio },
}) {
  yield put({ type: "PRICES_LOADING", payload: true })
  const { preciosXFecha, preciosXFechaOta } = yield select(x => x.mainReducer)
  const preciosXFechaTotal = [...preciosXFecha, ...preciosXFechaOta]
  const disponibilidadXFechaAux = JSON.parse(
    JSON.stringify(
      preciosXFechaTotal.find(x => x.type == tipoPrecio).disponibilidad
    )
  )
  try {
    let listaPromesas = []
    const listaFechas = ListaFechas(from, to)
    for (let fecha of listaFechas) {
      const prices = {}
      for (let precio of preciosXFechaTotal.find(x => x.type == tipoPrecio)
        .precios) {
        const pAux = precio.list.find(x => x.fecha == fecha)?.precio
        if (pAux != undefined && pAux != 0) {
          prices[precio.name] = pAux
        }
      }

      const minStay = preciosXFechaTotal.find(x => x.type == tipoPrecio)
      .estadiaMinima.find(x => x.fecha == fecha).estadiaMinima
      const maxStay = preciosXFechaTotal.find(x => x.type == tipoPrecio)
      .estadiaMaxima.find(x => x.fecha == fecha).estadiaMaxima
      const autoUpdateAvailability = preciosXFechaTotal.find(x => x.type == tipoPrecio)
      .autoAjustar.find(x => x.fecha == fecha).autoAjustar
      const response = API.graphql({
        query: addPrice,
        variables: {
          type: tipoPrecio,
          availability,
          autoUpdateAvailability,
          date: fecha,
          minStay,
          maxStay,
          autoUpdateAvailability,
          price: JSON.stringify(prices),
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
      listaPromesas = [...listaPromesas, response]
    }

    const rta = yield Promise.all(listaPromesas)
    //Recarga la nueva disponibilidad
    const newAvailabilities = rta.map(x => x.data.addPrice)
    for (let newAvailability of newAvailabilities) {
      preciosXFechaTotal
        .find(x => x.type == tipoPrecio)
        .disponibilidad.find(
          x => x.fecha == newAvailability.date
        ).disponibilidad = newAvailability.availability
    }
    yield put({
      type: "DISPONIBILIDAD_X_FECHA",
      payload: disponibilidadXFechaAux,
    })
    yield API.graphql(graphqlOperation(modifyPriceLastChange))
  } catch (error) {
    if (error?.errors[0].message == "Network Error") {
      alert("Error: No hay conexion a internet")
    } else {
      alert("Error al modificar disponibilidad")
    }
  }
  yield put({ type: "PRICES_LOADING", payload: false })
}

//Convierte precios de lista a objeto para buscar mas rapido
function* modifyPrices({ payload: { from, to, item, price, tipoPrecio } }) {
  yield put({ type: "PRICES_LOADING", payload: true })
  const { preciosXFecha, preciosXFechaOta } = yield select(x => x.mainReducer)
  const isOta = tipoPrecio.split("-").length != 1
  const preciosXFechaTotal = isOta ? preciosXFechaOta : preciosXFecha
  const preciosXFechaAux = JSON.parse(JSON.stringify(preciosXFechaTotal))
  try {
    let listaPromesas = []
    const listaFechas = ListaFechas(from, to)
    for (let fecha of listaFechas) {
      const prices = {}
      for (let precio of preciosXFechaAux.find(
        x => x.type.trim() == tipoPrecio.trim()
      ).precios) {
        if (precio.name != item) {
          const pAux = precio.list.find(x => x.fecha == fecha)?.precio
          if (pAux != undefined && pAux != 0) {
            prices[precio.name] = pAux
          }
        }
      }
      prices[item] = parseInt(price)
      const availability = preciosXFechaAux
        .find(x => x.type == tipoPrecio)
        .disponibilidad.find(x => x.fecha == fecha).disponibilidad
      const autoajustarExist = preciosXFechaAux.find(x => x.type == tipoPrecio)
        .autoAjustar
      const autoUpdateAvailability = autoajustarExist
        ? preciosXFechaAux
            .find(x => x.type == tipoPrecio)
            .autoAjustar.find(x => x.fecha == fecha).autoAjustar
        : 0
      const minStay = preciosXFechaAux.find(x => x.type == tipoPrecio)
      .estadiaMinima.find(x => x.fecha == fecha).estadiaMinima
      const maxStay = preciosXFechaAux.find(x => x.type == tipoPrecio)
      .estadiaMaxima.find(x => x.fecha == fecha).estadiaMaxima
      const response = API.graphql({
        query: addPrice,
        variables: {
          type: tipoPrecio,
          availability,
          autoUpdateAvailability,
          minStay,
          maxStay,
          price: JSON.stringify(prices),
          date: fecha,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
      listaPromesas = [...listaPromesas, response]
    }
    const rta = yield Promise.all(listaPromesas)
    const newPrices = rta.map(x => x.data.addPrice)
    for (let newPrice of newPrices) {
      preciosXFechaAux
        .find(x => x.type == tipoPrecio)
        .precios.find(x => x.name == item)
        .list.find(x => x.fecha == newPrice.date).precio = JSON.parse(
        newPrice.price
      )[item]
    }
    if (isOta) {
      yield put({ type: "PRECIOS_X_FECHA_OTA", payload: preciosXFechaAux })
    } else {
      yield put({ type: "PRECIOS_X_FECHA", payload: preciosXFechaAux })
    }
    yield put({ type: "PRICES_LOADING", payload: true })
    yield API.graphql(graphqlOperation(modifyPriceLastChange))
  } catch (error) {
    if (error?.errors[0].message == "Network Error") {
      alert("Error: No hay conexion a internet")
    } else {
      alert("Error al modificar precios")
    }
  }
  yield put({ type: "PRICES_LOADING", payload: false })
}



function* initCashMovements() {
  try {
    yield put({ type: "SET_CASH_MOVEMENT_LOADING", payload: true })

    const { currentCashName } = yield select(x => x.mainReducer)
    yield reloadBuildNumber({
      payload: { cashName: currentCashName, init: true },
    })
    const { currentBuildNumber } = yield select(x => x.mainReducer)

    const response = yield API.graphql({
      query: getAllCashMovements,
      variables: { buildNumber: currentBuildNumber, cashName: currentCashName }, //
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })

    const listaMovimientos = response.data.getAllCashMovements

    yield put({ type: "SET_CASH_MOVEMENTS", payload: listaMovimientos })
    yield put({ type: "SET_CASH_MOVEMENT_LOADING", payload: false })
  } catch (error) {
    console.log(error)
  }
}

function* reloadCashMovements() {
  yield put({ type: "SET_CASH_MOVEMENT_LOADING", payload: true })

  const { currentBuildNumber, currentCashName } = yield select(
    x => x.mainReducer
  )
  try {
    const response = yield API.graphql({
      query: getAllCashMovements,
      variables: { buildNumber: currentBuildNumber, cashName: currentCashName }, //
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    const listaMovimientos = response.data.getAllCashMovements

    yield reloadBuildNumber({ payload: { cashName: currentCashName } })
    yield put({ type: "SET_CASH_MOVEMENTS", payload: listaMovimientos })
    yield put({ type: "SET_CASH_MOVEMENT_LOADING", payload: false })
  } catch (error) {
    console.log("FAILED TO RELOAD_CASH_MOVEMENT", error)
  }
}

function* reloadReservationListScheduler({ payload: { startDate, endDate } }) {
  yield put({ type: "MAIN_LOADING", payload: true })
  const type = yield select(x => x.mainReducer.tipoPrecio)
  const listaFechas = ListaFechas(startDate, endDate, 4)
  const listaFechasLess = [...listaFechas]
  listaFechasLess.pop()
  const listaPromesas = listaFechasLess.map((x, i) =>
    API.graphql({
      query: reservationList,
      variables: { type, from: x, to: listaFechas[i + 1] }, //
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  )
  const queries = yield Promise.all(listaPromesas)
  const list = queries
    .reduce((acom, current) => [...acom, ...current.data.reservationList], [])
    .reduce(
      (acom, current) =>
        acom.find(x => x.reservationId == current.reservationId)
          ? acom
          : [...acom, current],
      []
    )

  yield put({
    type: "ADD_LIST_TO_MAIN_RESERVATION_LIST",
    payload: list.filter(x=>x.way!='preingreso').map(x => MejorarIngreso(x)),
  })
  //yield put({type:'MODAL_RESERVATION_LIST_SCHEDULER',payload:false})
  yield put({ type: "MAIN_LOADING", payload: false })
}

function* addCashMovements({
  payload: {
    saldo,
    type,
    closeCash,
    cashName,
    amount,
    isWithdrawal,
    concept,
    actorName,
    onComplete,
  },
}) {
  yield put({ type: "SET_CASH_MOVEMENT_LOADING", payload: true })
  try {
    const { lastBuildNumber, tipoPagos } = yield select(x => x.mainReducer)
    yield reloadBuildNumber({ payload: { cashName } })
    const { lastBuildNumber: lastBuildNumberAux, isCashClose } = yield select(
      x => x.mainReducer
    )
    if (lastBuildNumber != lastBuildNumberAux) {
      alert("No se pudo insertar debido a que esta caja esta cerrada")
    } else if (!closeCash && isCashClose) {
      alert("La caja esta cerrada, abrila para poder insertar transacciones")
    } else {
      const buildNumber =
        lastBuildNumber + (closeCash ? (isCashClose ? 0 : 1) : 0)
      //Si se cierra la caja
      if (closeCash) {
        yield Promise.all(
          Object.keys(saldo)
            .filter(x => saldo[x] != undefined && saldo[x] > 0)
            .map((type) =>
              API.graphql({
                query: addCashMovement,
                variables: {
                  buildNumber: lastBuildNumber,
                  cashName,
                  amount: parseInt(saldo[type]),
                  type,
                  closeCash: false,
                  isWithdrawal: true,
                  isMoneyLeft: false,
                  concept: `${type} final`,
                }, //
                authMode: "AMAZON_COGNITO_USER_POOLS",
              })
            )
        )

        yield API.graphql({
          query: addCashMovement,
          variables: {
            buildNumber: lastBuildNumber,
            cashName,
            amount:parseInt(amount),
            closeCash: true,
            isMoneyLeft: true,
            isWithdrawal,
            concept: `${tipoPagos[0].tipo} al cerrar la caja`,
          }, //
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })

        yield API.graphql({
          query: addCashMovement,
          variables: {
            buildNumber: lastBuildNumber,
            cashName,
            amount: 0,
            closeCash: true,
            isMoneyLeft: false,
            isWithdrawal,
            concept: `Caja cerrada por ${actorName}`,
          }, //
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
      }

      //Si cerraron la caja
      if (closeCash) {
        const { tipoPagos } = yield select(x => x.mainReducer)
        yield API.graphql({
          query: addCashMovement,
          variables: {
            buildNumber: buildNumber,
            cashName,
            type: tipoPagos[0].tipo,
            isMoneyLeft: false,
            amount: parseInt(amount),
            closeCash: false,
            isWithdrawal: false,
            concept: `Caja abierta por ${actorName}`,
          }, //
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
      } else {
        yield API.graphql({
          query: addCashMovement,
          variables: {
            buildNumber: buildNumber,
            cashName,
            type,
            isMoneyLeft: false,
            amount: parseInt(amount),
            closeCash: false,
            isWithdrawal,
            concept,
          }, //
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
      }

      yield put({ type: "SET_CASH_MOVEMENT_LOADING", payload: false })
      onComplete()
      //yield API.graphql(graphqlOperation(modifyLastChange))
    }

    yield put({ type: "INIT_CASH_MOVEMENT", payload: false })
  } catch (error) {
    yield put({ type: "SET_CASH_MOVEMENT_LOADING", payload: false })
    alert(
      "No se pudo cargar el movimiento de caja, revisa que estes conectado a internet"
    )
  }
}

function* reloadBuildNumber({ payload: { cashName, init } }) {
  try {
    const response = yield Promise.all([
      API.graphql({
        query: lastBuild,
        variables: { cashName }, //
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }),
      API.graphql({
        query: lastMovement,
        variables: { cashName }, //
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }),
    ])
    yield put({
      type: "SET_LAST_BUILD",
      payload: response[0].data.lastBuild.buildNumber,
    })
    yield put({
      type: "SET_CASH_CLOSE",
      payload: response[1].data.lastMovement.closeCash,
    })
    if (init) {
      yield put({
        type: "SET_CURRENT_BUILD_NUMBER",
        payload: response[0].data.lastBuild.buildNumber,
      })
    }
  } catch (error) {
    console.log("FAILED TO RELOAD_BUILD_NUMBER", error)
  }
}

function* seeker({ payload: { searchThing } }) {
  yield put({ type: "SET_SEARCH_LOADING", payload: true })
  const reservationList = yield select(x => x.mainReducer.listaReservas)
  try {
    const found = reservationList.filter(x =>
      x.customersList.find(y =>
        y.fullName.toLowerCase().includes(searchThing) ||
        y.dni?.toLowerCase().includes(searchThing)
      ) ||
      x.vehiclesList.find(y =>
        y.plate?.toLowerCase().includes(searchThing))
    ).slice(0,9)
    yield put({
      type: "SET_SEARCH_RESULT",
      payload: CreateSearchCards(found,searchThing),
    })

    if(found.length < 7){
      const response = yield API.graphql({
          query: searchReservations,
          variables: { search: searchThing }, //
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })

      const { search } = response.data;
      yield put({
        type: "SET_SEARCH_RESULT", 
        payload: CreateSearchCards([...found,...search.filter(x=>!found.find(y=>y.reservationId===x.reservationId)).reverse()],searchThing),
      })
  }

  } catch (error) {
    console.log(error)
  }
  yield put({ type: "SET_SEARCH_LOADING", payload: false })
}

function* newReservation() {
  const { changeDateTime, diaPlaya } = yield select(x => x.mainReducer)
  const checkoutEstimated = moment()
    .add(-changeDateTime, "hours")
    .add(diaPlaya ? 0 : 1, "days")
    .set({
      hour: CalcularCheckoutTime() - 3,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .format()
  const checkinEstimated = moment()
    .add(-changeDateTime, "hours")
    .set({
      hour: CalcularCheckoutTime() - 3,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .format()
  //const habitacionesDisponibles=HabitacionesDisponibles(checkinEstimated,checkoutEstimated)
  const nuevaReserva = {
    guests: 2,
    children: 0,
    checkoutEstimated,
    checkinEstimated,
    servicesList: [],
    customersList: [],
    vehiclesList: [],
    nuevaReserva: true,
    nights: diaPlaya ? 0 : 1,
    state: "",
    way: "Presencial",
    roomsList: [],
  }
  yield put({ type: "MODAL_RESERVA", payload: nuevaReserva })
  yield put({ type: "SET_MODAL_RESERVA", payload: true })
}

function* reloadStatisticsByDate() {
  const { statisticsDate, statisticsMonth } = yield select(x => x.mainReducer)
  yield put({ type: "SET_STATISTICS_BY_DATE_LOADING", payload: true })
  try {
    if (statisticsMonth && statisticsDate) {
      const found = statisticsMonth.find(x => x.date == statisticsDate)
      if (found) {
        yield put({ type: "SET_STATISTICS_BY_DATE", payload: found })
      } else {
        yield reloadStatisticsByMonth()
        const { statisticsMonth } = yield select(x => x.mainReducer)
        const rta = statisticsMonth.find(x => x.date == statisticsDate)
        yield put({ type: "SET_STATISTICS_BY_DATE", payload: rta })
      }
    }
  } catch (error) {
    console.log(error)
  }
  yield put({ type: "SET_STATISTICS_BY_DATE_LOADING", payload: false })
}

function* reloadStatisticsByMonth(changeType) {
  const { tipoPrecio, statisticsDate, generalStatisticsByDate } = yield select(
    x => x.mainReducer
  )
  yield put({ type: "SET_STATISTICS_BY_MONTH_LOADING", payload: true })
  try {
    if (tipoPrecio && statisticsDate) {
      if (
        changeType ||
        !generalStatisticsByDate ||
        moment(statisticsDate).format("MM/YYYY") !=
          moment(generalStatisticsByDate.date).format("MM/YYYY")
      ) {
        const response = yield API.graphql({
          query: getMonthStatistics,
          variables: {
            from: moment(statisticsDate).startOf("month"),
            to: moment(statisticsDate).endOf("month"),
            type: tipoPrecio,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
        const month = JSON.parse(response.data.getMonthStatistics)
        yield put({ type: "SET_STATISTICS_MONTH", payload: month })
      }
      const { statisticsMonth } = yield select(x => x.mainReducer)
      yield put({
        type: "SET_STATISTICS_BY_DATE",
        payload: statisticsMonth.find(x => x.date == statisticsDate),
      })
    }
  } catch (error) {
    console.log("Failed to reload statistics by month", error)
    //Network error alert
    if (error?.errors[0].message == "Network Error") {
      // alert("Error: No hay conexion a internet")
    }
  }
  yield put({ type: "SET_STATISTICS_BY_MONTH_LOADING", payload: false })
}

function* pageListing({ payload }) {
  yield put({ type: "SET_LISTING_LOADING", payload: true })
  yield put({ type: "SET_PAGE_LIST", payload })
  yield* reloadCheckoutList()
  yield put({ type: "SET_LISTING_LOADING", payload: false })
}

function* reloadCheckoutList() {
  const { pageList, regularCustomer } = yield select(x => x.mainReducer)
  yield put({ type: "SET_LISTING_LOADING", payload: true })
  try {
    const response = yield API.graphql({
      query: getCheckoutList,
      variables: {
        page: pageList,
        regularCustomer: regularCustomer ? false : true,
      }, //
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    yield put({
      type: "SET_CHECKOUT_LIST",
      payload: response.data.getCheckoutList,
    })
  } catch (error) {
    console.log(error)
  }
  yield put({ type: "SET_LISTING_LOADING", payload: false })
}

function* reloadPreReservation({payload:{page}}) {
  yield put({ type: "SET_LISTING_LOADING", payload: true })
  yield put({ type: "SET_PAGE_LIST_PRE_RESERVATION", payload: page })
  try {
    const response = yield API.graphql({
      query: getStatusList,
      variables: { page: page, status: "preingreso" }, //
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    yield put({
      type: "PRE_RESERVATION_LIST",
      payload: response.data.getStatusList,
    })
  } catch (error) {
    console.log(error)
  }
  yield put({ type: "SET_LISTING_LOADING", payload: false })
}

function* preReservation({ payload }) {
  yield put({
    type: "SET_PRE_RESERVATION_LIST",
    payload: payload
      .map(x => MejorarIngreso(x))
      .sort((a, b) => moment(b.created).diff(moment(a.created))),
  })
}

function* reloadCanceledReservationList({payload:{page}}) {
  yield put({ type: "SET_LISTING_LOADING", payload: true })
  yield put({ type: "SET_PAGE_LIST_CANCELLED", payload: page })
  try {
    const response = yield API.graphql({
      query: getStatusList,
      variables: { page: page, status: "cancelada" }, //
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
    yield put({
      type: "CANCELLED_RESERVATION_LIST",
      payload: response.data.getStatusList.map(x=>MejorarIngreso(x)),
    })
  } catch (error) {
    console.log(error)
  }
  yield put({ type: "SET_LISTING_LOADING", payload: false })
}

function* reloadPaymentListStatistics({ from, to }) {
  const { paymentListDay,isAdmin} = yield select(x => x.mainReducer)
  const { toggleBilling } = yield select(x => x.billReducer)
  if (paymentListDay&&isAdmin) {
    yield put({ type: "SET_PAYMENT_STATISTICS_LOADING", payload: true })
    const realFrom  = from ?? paymentListDay
    const pagos = (yield ObtenerListaPagosFechas(
      moment(from ?? paymentListDay)
        .startOf("day")
        .format(),
      moment(to ?? paymentListDay)
        .endOf("day")
        .format()
    )).map(x => ({ ...x, date: moment(x.date).add(3, "hour") }))

    // Agregamos las facturas a los pagos
    const realFromAtNight = moment(to || realFrom).endOf("day").add(3,"hour").format()
    const bills = toggleBilling?yield ObtenerListaBills(realFromAtNight,pagos.length):[]
    const pagosFacturas = pagos.map(x => ({ ...x, isBilled: bills.find(y => y.listProduct.find(z => z.paymentId == x.serviceId || z.paymentId == x.paymentId)) }))
    
    yield put({ type: "SET_PAYMENT_LIST_STATISTICS", payload: pagosFacturas })
    yield put({ type: "SET_PAYMENT_STATISTICS_LOADING", payload: false })
  }
}

function* openReservation({ payload }) {
  const listaGeneralReservas = yield select(
    x => x.mainReducer.listaGeneralReservas
  )
  const reservationFound = listaGeneralReservas.find(
    x => x.reservationId == payload
  )
  if (reservationFound) {
    yield put({ type: "MODAL_RESERVA", payload: reservationFound })
    yield put({ type: "SET_MODAL_RESERVA", payload: true })
  } else {
    yield put({ type: "MAIN_LOADING", payload: true })
    try {
      //reservation
      const response = yield API.graphql({
        query: reservation,
        variables: { reservationId: payload }, //
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
      if (response.data.reservation.length == 1) {
        const reservation = response.data.reservation[0]
        yield put({
          type: "MODAL_RESERVA",
          payload: MejorarIngreso(reservation),
        })
        yield put({ type: "SET_MODAL_RESERVA", payload: true })
      }
      yield put({ type: "MAIN_LOADING", payload: false })
    } catch (error) {
      alert("Error al cargar la reserva")
    }
  }
}

function* reloadBillList({ payload }) {
  yield put({ type: "SET_LOADING_BILL", payload: true })

  try {
    const response = yield API.graphql(
      graphqlOperation(billsByReservation, { reservationId: payload })
    )
    yield put({
      type: "SET_BILL_LIST",
      payload: [...response.data.billsByReservation],
    })
  } catch (error) {
    console.log(error)
  }
  yield put({ type: "SET_LOADING_BILL", payload: false })
}

function* duplicateReservation({ payload }) {
  const { checkoutTime } = yield select(x => x.mainReducer)
  const checkoutEstimated = moment()
    .add(1, "days")
    .set({ hour: checkoutTime - 3, minute: 0, second: 0, millisecond: 0 })
    .format()
  const checkinEstimated = moment()
    .set({ hour: checkoutTime - 3, minute: 0, second: 0, millisecond: 0 })
    .format()
  const vehiclesList = [...payload.vehiclesList].map(x => ({
    ...x,
    vehicleId: uuidv4(),
  }))
  const customersList = [...payload.customersList].map(x => ({
    ...x,
    customerId: uuidv4(),
  }))
  const nuevaReserva = {
    guests: 2,
    children: 0,
    starredCustomer:payload.starredCustomer,
    checkoutEstimated,
    checkinEstimated,
    servicesList: [],
    customersList,
    vehiclesList,
    nuevaReserva: true,
    nights: 1,
    state: "",
    way: "Presencial",
    roomsList: [],
  }
  yield put({ type: "MODAL_RESERVA", payload: nuevaReserva })
}

function* uploadBill() {
  const canBillRes = yield canBill()
  if (canBillRes) {
    yield put({ type: "SET_LOADING_BILL", payload: true })
    const { printer, togglePrinter } = yield select(x => x.printerReducer)
    const sellPoint = yield select(x => x.billReducer?.sellPoint)
    const state = yield select(x => x.billReducer)
    const billList = yield select(x => x.billReducer.billList)
    const documentNumber = state.customer.isConsumidorFinal
      ? 0
      : state.customer.dni
    const billAux = {
      ...state.billInfo,
      listProduct: state.itemsList,
      name: state.customer.fullName,
      email: state.customer.email,
      documentNumber: documentNumber,
      reservationId: state.reserva.reservationId,
      pointSell: sellPoint,
    }
    delete billAux.total
    const userToken = "a"
    const apiKey = "a"
    const apiToken = "a"
    try {
        const response = yield API.graphql(
          graphqlOperation(makeBill, {
            userToken,
            apiKey,
            apiToken,
            bill: billAux,
          })
        )
        if (togglePrinter) {
          if (printer.isConnected && printer.printTicket) {
            const makeBill = JSON.parse(response.data.makeBill)
            const {documentNumber,name}=makeBill
            
            yield printPOS(
              { ... makeBill, billAux,customer: {documentNumber,name} },
              billAux.typeBill
            )
          }
          if (response.data.makeBill) {
            yield put({type:'SET_PAYMENTS_LIST',payload:[]});

            yield put({
              type: "SET_BILL_LIST",
              payload: [...billList, JSON.parse(response.data.makeBill)],
            })
          }
      }
      yield put({ type: "SET_LOADING_BILL", payload: false })
    } catch (error) {
      console.log(error)
      yield put({ type: "SET_LOADING_BILL", payload: false })
      alert(
        billAux.cancelBillNumber
          ? `Error al crear nota de credito`
          : `Error al crear la factura`
      )
    }
  }
}

function* cancelBill({ payload: cancelBillNumber }) {
  const { billList } = yield select(x => x.billReducer)

  const billToCancelAux = billList.find(
    x => x.comprobanteNro == cancelBillNumber
  )
  if (billToCancelAux) {
    let billToCancel = { ...billToCancelAux }

    yield put({ type: "SET_LOADING_BILL", payload: true })
    const userToken = "a"
    const apiKey = "a"
    const apiToken = "a"
    billToCancel.typeBill = billlToCreditNote(billToCancel.typeBill)
    delete billToCancel.billLink
    delete billToCancel.specificUsername
    delete billToCancel.date
    delete billToCancel.afipQR
    delete billToCancel.afipBarCode
    delete billToCancel.cae
    delete billToCancel.dateExpireCae
    billToCancel.cancelBillNumber = billToCancel.comprobanteNro
    billToCancel.dateBill = moment().format("YYYY-MM-DD")
    delete billToCancel.comprobanteNro
    try {
      const response = yield API.graphql(
        graphqlOperation(makeBill, {
          userToken,
          apiKey,
          apiToken,
          bill: billToCancel,
        })
      )
      if (response.data.makeBill) {
        yield put({
          type: "SET_BILL_LIST",
          payload: [...billList, JSON.parse(response.data.makeBill)],
        })
      }
      yield put({ type: "SET_LOADING_BILL", payload: false })
    } catch (error) {
      yield put({ type: "SET_LOADING_BILL", payload: false })
      alert("Error al cancelar la factura")
    }
  }
}

function* loadUserConfig() {
  const response= yield networkFirst("userInfo", API.graphql.bind(API, (graphqlOperation(getUserInfo))))
  const userInfo= JSON.parse(response.data.getUserInfo)
    if (userInfo) {
      yield put({ type: "SET_BILLING_INFO", payload: userInfo.billingInfo })
      yield put({ type: "INIT_PRINTER", payload: userInfo.printerInfo })
      yield put({ type: "SET_TOGGLE_PRINTER", payload: userInfo.togglePrinter })
      yield put({ type: "SET_DEFAULT_CURRENCY", payload: userInfo.defaultCurrency || "ARS" })
      yield put({ type: "SET_TOGGLE_BILLING", payload: userInfo.toggleBilling })
      yield put({ type: "SET_SUBSCRIPTION_ENDS", payload: userInfo.subscriptionEnds })
      yield put({ type: "SET_DISCOUNT_FUNCTION", payload: userInfo.discountFunction })
      yield put({ type: "SET_START_CALENDAR_DAY", payload: userInfo.startCalendarDay })
      yield put({ type: "SET_COLOR_BLIND", payload: userInfo.isColorBlind })
      yield put({ type: "SET_PRINT_SPECIFIC_ROOM_VOUCHER", payload: userInfo.printSpecificRoomVoucher })

      if (userInfo.togglePrinter) {
        yield* connectPrinter()
      }
      return response
    } else {
      return null
    }
  }
  
function* canBill() {
  const { billInfo } = yield select(x => x.billReducer)
  console.log("🚀 ~ file: sagas.js:1378 ~ function*canBill ~ billInfo:", billInfo)
  //Check if all billInfo fields are set and dateFrom and to are valid dates and from is before to and bring alert for all posible errors
  if (
    billInfo.typeBill &&
    billInfo.payCondition &&
    billInfo.dateFrom &&
    billInfo.dateTo &&
    billInfo.total > 0
  ) {
    if (moment(billInfo.dateFrom).isSameOrBefore(moment(billInfo.dateTo))) {
      return true
    } else {
      alert("La fecha de inicio debe ser menor o igual a la de fin")
    }
  } else {
    alert("Debe completar todos los datos de la factura")
  }
  return false
}

function* cuitInfo({ payload }) {
  yield put({ type: "SET_LOADING_CUIT_SEARCH", payload: true })
  try {
    const cuit = payload.split("-").join("")
    const response = yield fetch(
      `https://afip.tangofactura.com/Rest/GetContribuyenteFull?cuit=${cuit}`
    )
    const data = yield response.json()
    if (data.errorGetData) {
      alert(data.errorMessage)
    } else {
      const fullName=data.Contribuyente.nombre.split(" ").map(x=>x.charAt(0).toUpperCase()+x.slice(1).toLowerCase()).join(" ")
      yield put({
        type: "SET_CUSTOMER",
        payload: {
          id: -1,
          fullName: fullName,
          dni: cuit,
          age: "",
          isConsumidorFinal: false,
          searchedCuit: true,
        },
      })
    }
  } catch (error) {
    console.log(error)
  }

  yield put({ type: "SET_LOADING_CUIT_SEARCH", payload: false })
}

function* starredCustomer({ payload }) {
  try {
    const reserva=yield select (x=>x.mainReducer.reserva)
    const customersList=yield select (x=>x.customersReducer.customersList)
    const nuevaReserva={...reserva,customersList,starredCustomerId:payload}
    yield put ({type:"MODAL_RESERVA",payload:nuevaReserva})
  } catch (error) {
    console.log(error)
  }
}

function* addReservation({ payload }) {
  const reservation = payload
  yield queueCache.saveReservation(reservation)

  const listaReservas = yield select (x=>x.mainReducer.listaReservas)
  const improvedReservation = {...MejorarIngreso(reservation)}
  yield put({type: "MODAL_RESERVA",payload: improvedReservation})
  yield put({type: "LISTA_RESERVAS",payload: [...listaReservas, improvedReservation]})
  
  const payment = reservation.servicesList[0].paymentsList[0]
  // Billing and printing
  yield *checkBilling(payment)
  yield *checkPrinting(reservation,true)

  // Notification
  NotificationManager.success('Ingreso agregado con exito!');

  // Queue
  yield *sendNewReservationQueue()
}

function* sendNewReservationQueue() {
  const isOnline = yield select(x => x.internetStatusReducer.isOnline)
  if (isOnline) {
    const queue = yield select(x => x.queueReducer.newReservationsQueue)
    const promiseList = queue.map(x => setNewReservation(x))

    const responseList = yield Promise.allSettled(promiseList);
    const fulFilledList = responseList.filter(x => x.value)
    const newQueue = queue.filter(x => !fulFilledList.map(y => y.value.reservationId).includes(x.reservationId))
    yield queueCache.deleteFromQueue(fulFilledList.map(x => x.value.id))
    
    yield put({ type: "SET_RESERVATION_QUEUE", payload: newQueue })
  }
}

function* modifyReservation({ payload }) {
  const {fullReservation, plainReservation} = {...payload}
  yield queueCache.modifyReservation(plainReservation)
  const reservationList = yield select(x=>x.mainReducer.listaReservas)
  const filterReservationList = reservationList.filter(x => x.reservationId != fullReservation.reservationId)

  const preReservationList = yield select(x=>x.mainReducer.preReservationList)
  const filterPreReservationList = preReservationList.filter(x => x.reservationId != fullReservation.reservationId)

  // if the reservation is in the preReservationList
  if(preReservationList.find(x => x.reservationId == fullReservation.reservationId)){
    if(fullReservation.state == "preingreso"){
      yield put({type: "SET_PRE_RESERVATION_LIST",payload: [...filterPreReservationList, fullReservation]})
    }
    else{
      yield put({type: "SET_PRE_RESERVATION_LIST",payload: [...filterPreReservationList]})
      yield put({type: "LISTA_RESERVAS",payload: [...filterReservationList, fullReservation]})
    }
  }
  else{
    yield put({type: "LISTA_RESERVAS",payload: [...filterReservationList, fullReservation]})
  }


  yield put({type: "MODAL_RESERVA",payload: MejorarIngreso(fullReservation)})

  // notification
  NotificationManager.success('Ingreso modificado con exito!');

  // checkPrinting
  yield *sendModifyReservationQueue()
  }

function* modifyReservationCheckoutTime({ payload }) {
  const {reservationId, checkoutTime} = payload
  const reservationList = yield select(x=>x.mainReducer.listaReservas)
  const preReservationList = yield select(x=>x.mainReducer.preReservationList)

  const reservation = {...[...reservationList,...preReservationList].find(x => x.reservationId == reservationId)}
  const checkoutEstimated = moment(reservation.checkoutEstimated).set({hour: checkoutTime.split(':')[0], minute: 0, second: 0, millisecond: 0 }).add(-3, 'hours').format()
  const modifyReservation = {...reservation, checkoutEstimated}
  yield queueCache.modifyReservation(modifyReservation)
  const filterReservationList = reservationList.filter(x => x.reservationId != reservationId)
  const filterPreReservationList = preReservationList.filter(x => x.reservationId != reservationId)

  // if the reservation is in the preReservationList
  if(preReservationList.find(x => x.reservationId == reservationId)){
    if(modifyReservation.state == "preingreso"){
      yield put({type: "SET_PRE_RESERVATION_LIST",payload: [...filterPreReservationList, modifyReservation]})
    }
    else{
      yield put({type: "SET_PRE_RESERVATION_LIST",payload: [...filterPreReservationList]})
      yield put({type: "LISTA_RESERVAS",payload: [...filterReservationList, modifyReservation]})
    }
  }
  else{
    yield put({type: "LISTA_RESERVAS",payload: [...filterReservationList, modifyReservation]})
  }

  yield put({type: "MODAL_RESERVA",payload: MejorarIngreso(modifyReservation)})
  yield *sendModifyReservationQueue()

}

function* sendModifyReservationQueue() {
  const isOnline = yield select(x => x.internetStatusReducer.isOnline)
  if (isOnline) {
    const queue = yield select(x => x.queueReducer.modifyReservationsQueue)
    const promiseList = queue.map(x => setModifyReservation(x))
    
    const responseList = yield Promise.allSettled(promiseList);
    const fulFilledList = responseList.filter(x => x.value)
    const newQueue = queue.filter(x => !fulFilledList.map(y => y.value.reservationId).includes(x.reservationId))
    yield queueCache.deleteFromQueue(fulFilledList.map(x => x.value.id))
    yield put({ type: "SET_MODIFY_RESERVATION_QUEUE", payload: newQueue })
  }
}


function* settleModifyReservation(newReservation){
  const { modifyReservationsQueue } = yield select(x => x.queueReducer)
  const filteredReservations = modifyReservationsQueue.filter(x => x.reservationId == newReservation.reservationId)
  if (filteredReservations.length > 0) {
    const reservation = filteredReservations[0]
    const checkoutEstimated = newReservation.checkoutEstimated
    const newModifyReservation = {...reservation, checkoutEstimated}
    yield queueCache.modifyReservation(newModifyReservation)
  }
}

function* addService ({payload}) {
  const {reservationId, newService, newPayment, addDays} = payload
  newService.serviceId = uuidv4()
  newService.date = moment().format();
  if(newPayment){
    newPayment.paymentId = uuidv4()
    newPayment.date = moment().format();
  }
  yield queueCache.saveService(payload)

  const reservationList = yield select (x=>x.mainReducer.listaReservas)
  const preReservationList = yield select (x=>x.mainReducer.preReservationList)

  const listaReservas = [...reservationList, ...preReservationList]
  const reservation = listaReservas.find(x => x.reservationId === reservationId)
  const newReservation = AgregarServicioAReserva(reservation, newService, [newPayment].filter(x=>x), addDays)
  yield settleModifyReservation(newReservation);

  if(preReservationList.find(x => x.reservationId == newReservation.reservationId)){
    const filterPreReservationList = preReservationList.filter(x => x.reservationId != newReservation.reservationId)
    yield put({type: "SET_PRE_RESERVATION_LIST",payload: [...filterPreReservationList, MejorarIngreso(newReservation)]})
  }
  else{
    const filterReservationList = reservationList.filter(x => x.reservationId != reservationId)
    yield put({type: "LISTA_RESERVAS",payload: [...filterReservationList, newReservation]})
  }

  yield put({type: "MODAL_RESERVA",payload: MejorarIngreso(newReservation)})

  // notification
  NotificationManager.success('Servicio agregado con exito!');

  // Billing and printing
  if(newPayment)
    {
      yield *checkBilling(newPayment)
      yield *checkPrinting(newReservation, false)

    }
  // Queue
  yield *sendAddServiceQueue()
}

function* sendAddServiceQueue() {
  const isOnline = yield select(x => x.internetStatusReducer.isOnline)
  if (isOnline) {
    const queue = yield select(x => x.queueReducer.servicesQueue)    
    const promiseList = queue.map(x => setAddService(x.reservationId, x.newService, x.newPayment, x.addDays, x.id))
    const responseList = yield Promise.allSettled(promiseList);
    const fulFilledList = responseList.filter(x => x.value)
    const newQueue = queue.filter(x => !fulFilledList.map(y => y.value.reservationId).includes(x.reservationId))
    yield queueCache.deleteFromQueue(fulFilledList.map(x => x.value.id))
    yield put({ type: "SET_SERVICE_QUEUE", payload: newQueue })
  }
}

function* addPayment ({payload}) {
  const {reservationId,serviceId, newPayment} = payload
  newPayment.paymentId = uuidv4()
  newPayment.date = moment().format();
  yield queueCache.savePayment(payload)
  const reservationList = yield select (x=>x.mainReducer.listaReservas)
  const preReservationList = yield select (x=>x.mainReducer.preReservationList)

  const listaReservas = [...reservationList, ...preReservationList]
  const reservation = listaReservas.find(x => x.reservationId === reservationId)
  const newReservation = AgregarPagoAReserva(reservation, serviceId, newPayment)
  
  if(preReservationList.find(x => x.reservationId == newReservation.reservationId)){
    const filterPreReservationList = preReservationList.filter(x => x.reservationId != newReservation.reservationId)
    yield put({type: "SET_PRE_RESERVATION_LIST",payload: [...filterPreReservationList, MejorarIngreso(newReservation)]})
  }
  else{
    const filterReservationList = reservationList.filter(x => x.reservationId != reservationId)
    yield put({type: "LISTA_RESERVAS",payload: [...filterReservationList, newReservation]})
  }

  yield put({type: "MODAL_RESERVA",payload: MejorarIngreso(newReservation)})

  // notification
  NotificationManager.success('Pago agregado con exito!');
  // checkPrinting
  yield *sendAddPaymentQueue()
}
  
function* sendAddPaymentQueue() {
  const isOnline = yield select(x => x.internetStatusReducer.isOnline)
  if (isOnline) {
    const queue = yield select(x => x.queueReducer.paymentsQueue)
    const promiseList = queue.map(x => setAddPayment(x.reservationId, x.serviceId, x.newPayment, x.id))
    const responseList = yield Promise.allSettled(promiseList);
    
    const fulFilledList = responseList.filter(x => x.value)
    const newQueue = queue.filter(x => !fulFilledList.map(y => y.value.reservationId).includes(x.reservationId))
    yield queueCache.deleteFromQueue(fulFilledList.map(x => x.value.id))
    yield put({ type: "SET_PAYMENT_QUEUE", payload: newQueue })
  }
}

function* cancelService ({payload}) {
  const {reservationId,serviceId, isCanceled} = payload
  yield queueCache.saveCancelService(payload)

  const reservationList = yield select (x=>x.mainReducer.listaReservas)
  const preReservationList = yield select (x=>x.mainReducer.preReservationList)

  const listaReservas = [...reservationList, ...preReservationList]
  const reservation = listaReservas.find(x => x.reservationId === reservationId)
  const newReservation = CancelarServicio(reservation, serviceId, isCanceled)
  if(preReservationList.find(x => x.reservationId == newReservation.reservationId)){
    const filterPreReservationList = preReservationList.filter(x => x.reservationId != newReservation.reservationId)
    yield put({type: "SET_PRE_RESERVATION_LIST",payload: [...filterPreReservationList, MejorarIngreso(newReservation)]})
  }
  else{
    const filterReservationList = reservationList.filter(x => x.reservationId != reservationId)
    yield put({type: "LISTA_RESERVAS",payload: [...filterReservationList, newReservation]})
  }

  yield put({type: "MODAL_RESERVA",payload: MejorarIngreso(newReservation)})
  // notification
  NotificationManager.success('Servicio cancelado con exito!');
  yield *sendCancelServiceQueue()
}

function* sendCancelServiceQueue() {
  const isOnline = yield select(x => x.internetStatusReducer.isOnline)
  if (isOnline) {
    const queue = yield select(x => x.queueReducer.cancelServicesQueue)
    const promiseList = queue.map(x => setCancelService(x.reservationId, x.serviceId, x.isCanceled, x.id))
    const responseList = yield Promise.allSettled(promiseList);
    const fulFilledList = responseList.filter(x => x.value)

    const newQueue = queue.filter(x => !fulFilledList.map(y => y.value.reservationId).includes(x.reservationId))
    yield queueCache.deleteFromQueue(fulFilledList.map(x => x.value.id))
    
    yield put({ type: "SET_CANCEL_SERVICE_QUEUE", payload: newQueue })
  }
}

function* checkBilling(payment) {
    const { toggleBilling, makeBill } = yield select(x => x.billReducer)
  
    if (toggleBilling && makeBill) {
      yield put({type: "SET_PAYMENT_TO_BILL_NOW",payload: payment})
    }
}

  function* checkPrinting(reservation, isNewReservation){
    const { printer, togglePrinter } = yield select(x => x.printerReducer)
    const { toggleBilling, makeBill } = yield select(x => x.billReducer)

    if (togglePrinter && printer.isConnected) {
      if (isNewReservation && printer.printCheckin) {
        yield printPOS(reservation, "checkin")
      }
      if (isNewReservation && printer.printCheckout) {
        yield printPOS(reservation, "checkout")
      }
      if (printer.printTicket && !makeBill) {
        yield printPOS({...reservation.servicesList.at(-1),payExpirationDateTime:reservation.checkoutEstimated,reservation}, "ticketx")
      }
    }
  }
  
  function* sendAllQueues(){
    const queues = yield select(x => x.queueReducer)
    const pendingChanges = queues.newReservationsQueue.length + queues.cancelServicesQueue.length + queues.paymentsQueue.length + queues.servicesQueue.length + queues.modifyReservationsQueue.length;
    if(pendingChanges > 0){
      yield put({type: "SET_SYNC_LOCAL_CHANGES",payload: true})
      yield *sendNewReservationQueue()
      yield delay(2000)
      yield *sendAddServiceQueue()
      yield delay(2000)
      yield *sendModifyReservationQueue()
      yield delay(2000)
      yield *sendAddPaymentQueue()
      yield delay(2000)
      yield *sendCancelServiceQueue()
      yield put({type: "SET_SYNC_LOCAL_CHANGES",payload: false})
    }
  }

  function* massiveCheckout({payload:{massiveCheckoutList, onComplete,dispatch}}){
    const { listaReservas, preReservationList } = yield select(x => x.mainReducer)
    yield put({type: "SET_LISTING_LOADING",payload: true})
    try {
      for(let reservationId of massiveCheckoutList){
        // yield CambiarEstadoCheckinReserva(reservationId,'checkout',()=>{},dispatch);
        const reservationFound = [...listaReservas,...preReservationList].find(x => x.reservationId == reservationId)
        const {
          nights,
          created,
          percentPayed,
          isDeudor,
          otaId,
          nightsDue,
          ...ingresoAux
        } = reservationFound
        ingresoAux.customersList = ingresoAux.customersList.map(x => {const { age, ...aux } = x;return aux})
        ingresoAux.servicesList = []
        ingresoAux.checkoutMade = moment().format()
        ingresoAux.state = "checkout"
        yield modifyReservation({payload: {plainReservation: ingresoAux, fullReservation: {...reservationFound,state:"checkout", checkoutMade: moment().format()}}})
      }
      onComplete()
    }
    catch (error) {
      console.log(error)
      alert("Error al realizar el checkout masivo. Por favor reinicia la aplicación")
    }

    yield put({type: "SET_LISTING_LOADING",payload: false})
}


function* createBan({payload}){
    const { listaReservas:reservationList, preReservationList } = yield select(x => x.mainReducer)

    const listaReservas = [...reservationList, ...preReservationList]
    yield put({type: "SET_BAN_STATUS",payload: {loading:true}})
    yield setNewBan(payload)
    yield put({type: "SET_BAN_STATUS",payload: {openCreateBanModal:false,loading:false}})
    const reservationFound = listaReservas.find(x => x.reservationId === payload.reservationId)

    if(reservationFound){
      const newReservation = {...reservationFound,customersList: reservationFound.customersList.map(x => payload.customersList.map(y=>y.customerId).includes(x.customerId) ? {...x, banId: payload.banId} : x)}
      yield put({type: "LISTA_RESERVAS",payload: listaReservas.map(x => x.reservationId == payload.reservationId ? newReservation : x)})
      yield put({type: "MODAL_RESERVA",payload: MejorarIngreso(newReservation)})
    }
    else{
      yield put({type: "SET_MODAL_RESERVA",payload: false})
    }

    NotificationManager.success('Prohibición creada con exito!');

  }

function* modifyBan({payload}){
  yield put({type: "SET_BAN_STATUS",payload: {loading:true}})
  yield setModifyBan(payload)
  const banList = yield select(x => x.banReducer.banList)
  yield put({type: "SET_BAN_STATUS",payload: {openModifyBanModal:false,loading:false,banList: banList.map(x => x.banId == payload.banId ? payload : x)}})
  NotificationManager.success('Prohibición modificada con exito!');
}

function* deleteBan({payload}){
  yield put({type: "SET_BAN_STATUS", payload: {loading:true}})
  yield setDeleteBan(payload)
  const banList = yield select(x => x.banReducer.banList)
  const updatedBanList = banList.filter(x => x.banId !== payload)
  yield put({type: "SET_BAN_STATUS", payload: {openModifyBanModal:false, loading:false, banList: updatedBanList}})
  NotificationManager.success('Prohibición eliminada con éxito!');
}

function* fetchBannedCustomer({payload}){
    const userGroup = yield select(x => x.banReducer.userGroup)
    const banFound = yield GetBannedCustomer({dni:payload.dni, userGroup:userGroup})

    if(banFound){
      yield put({type: "SET_BAN_STATUS",payload: {banFound,foundBanModal:true}})
    }

}

function* fetchBanList({payload}){
  const {page, pageSize} = payload
  yield put({type: "SET_BAN_STATUS", payload: {loading:true,page,pageSize}})

  const banList = yield GetBanList({page, pageSize})
  yield put({type: "SET_BAN_STATUS",payload: {banList,loading:false}})
}

function* onUpdateCustomer({payload}){
    const lastCustomerUpdate  = yield select(x => x.customersReducer?.customer)
    // Ban check
    if(payload.dni &&
      payload.dni.length > 5 && 
      payload.dni != lastCustomerUpdate.dni){
      cancel()
      yield fork(fetchBannedCustomer,{payload})
    }
    
    yield put({type: "UPDATE_CUSTOMER", payload})

}

function* modifyUserGroup({payload}){
  const success = yield setModifyUserGroup(payload)
  yield put({type: "SET_BAN_STATUS", payload: {userGroup:payload}})
  if(success){
    NotificationManager.success('Estado modificado con exito!');
  }
  else {
    NotificationManager.error('Error al modificar el estado');
  }
}

function* modalReserva({payload}){
  const { tipoPrecio } = yield select(x => x.mainReducer)
  const payloadType = payload.roomsList.length > 0 ? payload.roomsList[0].type : tipoPrecio;

  if(payloadType != tipoPrecio) {
    yield put({type: "CHANGE_PRICE_TYPE",payload: payloadType})
  }

  yield put({type: "SET_RESERVA",payload: payload})
}

function* changePriceType({payload}){
  const { roomsTypesChoosed, precios } = yield select(x => x.mainReducer)
  const precioActual = precios.find(x=>x.type==payload)
  const newRoomsTypesChoosed = precioActual.mergeRoomType?precios.filter(x=>x.mergeRoomType==precioActual.mergeRoomType).map(x=>x.type):[payload]
  
  yield put({ type: "SET_ROOMS_TYPES_CHOOSED", payload: newRoomsTypesChoosed})
  yield put({ type: "SET_TIPO_PRECIO", payload})
  yield put({ type: "RELOAD_CHECKOUT_TIME" });
  if(!roomsTypesChoosed.includes(payload)){
    yield put({ type: "LISTA_RESERVAS_BY_TYPE", payload})
  }
  yield put({ type: "LISTA_DEPTOS_BY_TYPE" });
  yield put({ type: "RELOAD_STATISTICS_BY_DATE" });
  yield put({ type: "RELOAD_PAYMENT_LIST_STATISTICS" });
  yield put({ type: "RELOAD_STATISTICS_BY_MONTH", payload: true });
}

function* changeReservationType({payload}){
  const {reservationId, type} = payload
  const reservationList = yield select(x=>x.mainReducer.listaReservas)
  const preReservationList = yield select(x=>x.mainReducer.preReservationList)

  const reservation = {...[...reservationList,...preReservationList].find(x => x.reservationId == reservationId)}
  const modifyReservation = {...reservation, type: type}
  yield queueCache.modifyReservation(modifyReservation)
  const filterReservationList = reservationList.filter(x => x.reservationId != reservationId)
  const filterPreReservationList = preReservationList.filter(x => x.reservationId != reservationId)

  // if the reservation is in the preReservationList
  if(preReservationList.find(x => x.reservationId == reservationId)){
    if(modifyReservation.state == "preingreso"){
      yield put({type: "SET_PRE_RESERVATION_LIST",payload: [...filterPreReservationList, modifyReservation]})
    }
    else{
      yield put({type: "SET_PRE_RESERVATION_LIST",payload: [...filterPreReservationList]})
      yield put({type: "LISTA_RESERVAS",payload: [...filterReservationList, modifyReservation]})
    }
  }
  else{
    yield put({type: "LISTA_RESERVAS",payload: [...filterReservationList, modifyReservation]})
  }

  yield put({type: "MODAL_RESERVA",payload: MejorarIngreso(modifyReservation)})
  yield *sendModifyReservationQueue()

}

function* duplicateReservationChangingType({payload}){
  const {reservation, type} = payload
  yield put({type:'CHANGE_PRICE_TYPE',payload:type})
  yield take('CHANGE_PRICE_TYPE_SUCCESS')
  yield put({type:'DUPLICATE_RESERVATION',payload:reservation})
}

function* reloadPromotions(){
  const promotions = yield API.graphql({
    query: getPromotions,
    variables: {},
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })
  yield put({type: "SET_PROMOTION",payload: {promotionsList:promotions.data.getPromotions.map(x=>JSON.parse(x))}})
}

function* upsertPromotion({payload}){
  yield API.graphql({
    query: upsertPromotionMutation,
    variables: {promotion:JSON.stringify(payload), datetime:payload.created},
    authMode: "AMAZON_COGNITO_USER_POOLS"
  })
  yield put({type:"SET_PROMOTION",payload:{selectedPromotion:{},openPromotionModifyModal:false, openPromotionsNewModal:false}})
  yield put({type: "RELOAD_PROMOTIONS"})
}

function* deletePromotion({payload}){
  yield API.graphql({
    query: deletePromotionMutation,
    variables: {datetime:payload},
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })
  yield put({type: "RELOAD_PROMOTIONS"})
}

// use them in parallel
export default function* rootSaga() {
  // reservation
  yield takeLeading("MODAL_RESERVA", modalReserva)
  yield takeLeading("ADD_RESERVATION", addReservation)
  yield takeLeading("MODIFY_RESERVATION", modifyReservation)
  yield takeLeading("ADD_SERVICE_TO_RESERVATION", addService)
  yield takeLeading("ADD_PAYMENT_TO_SERVICE", addPayment)
  yield takeLeading("CANCEL_SERVICE", cancelService)
  yield takeLeading("MODIFY_RESERVATION_CHECKOUT_TIME", modifyReservationCheckoutTime)
  yield takeLeading("CHANGE_RESERVATION_TYPE", changeReservationType)
  yield takeLeading("DUPLICATE_RESERVATION_CHANGING_TYPE", duplicateReservationChangingType)
  yield takeLeading("RELOAD_CANCELLED_RESERVATION", reloadCanceledReservationList)

  // queue
  yield takeLeading("SEND_QUEUE", sendNewReservationQueue)
  yield takeLeading("SEND_ALL_QUEUES", sendAllQueues)

  // generic reload
  yield takeLeading("RELOAD_RESERVATIONS", reloadReservations)
  yield takeLeading("RELOAD_ROOMS", reloadRooms)
  yield takeLeading("RELOAD_ALL", reloadAll)
  yield takeLatest("LISTA_RESERVAS", listaReservas)

  //Se usa cuando se cambia el tipo de habitacion camping/dormis
  yield takeLatest("LISTA_RESERVAS_BY_TYPE", reloadReservationList)
  yield takeLatest("LISTA_DEPTOS_BY_TYPE", reloadDeptosList)
  yield takeLatest("RELOAD_CHECKOUT_TIME", reloadCheckout)
  yield takeLatest("RELOAD_LAST_BUILD_TIME", reloadBuildNumber)

  //Prices
  yield takeLatest("INIT_PRICES", initPrices)
  yield takeLatest("RELOAD_PRICE_LIST", reloadPriceList)
  yield takeLatest("INIT_CASH_MOVEMENT", initCashMovements)
  yield takeLatest("RELOAD_CASH_MOVEMENT", reloadCashMovements)
  yield takeLatest("CHANGE_PRICE_TYPE", changePriceType)
  //Avaiability
  yield takeLatest("MODIFY_AVAILABILITY", modifyAvailability)
  yield takeLatest("MODIFY_PRICES", modifyPrices)
  yield takeLatest("ADD_CASH_MOVEMENT", addCashMovements)
  yield takeLatest("MODIFY_AUTOUPDATE", modifyAutoUpdate)

  //Manana intentar apretar el boton de facturrar y ver que pasa

  //Bill
  yield takeLatest("MAKE_BILL", uploadBill)
  yield takeLatest("CANCEL_BILL", cancelBill)
  yield takeLatest("CUIT_INFO", cuitInfo)

  //Statistics
  yield takeLatest("RELOAD_STATISTICS_BY_DATE", reloadStatisticsByDate)
  yield takeLatest("RELOAD_STATISTICS_BY_MONTH", reloadStatisticsByMonth)
  yield takeLatest("RELOAD_PAYMENT_LIST_STATISTICS",reloadPaymentListStatistics)

  //Pre Reservation
  yield takeLatest("RELOAD_PRE_RESERVATION", reloadPreReservation)
  yield takeLatest("PRE_RESERVATION_LIST", preReservation)

  //Reservations
  yield takeLatest("OPEN_RESERVATION", openReservation)
  yield takeLatest("RELOAD_RESERVATION_BILL", reloadBillList)

  //Search
  yield takeLatest("SEARCH", seeker)

  //States
  yield takeLatest("NEW_RESERVATION", newReservation)

  //Promotions
  yield takeLatest("RELOAD_PROMOTIONS", reloadPromotions)
  yield takeLatest("UPSERT_PROMOTION", upsertPromotion)
  yield takeLatest("DELETE_PROMOTION", deletePromotion)

  //Ban
  yield takeLatest("CREATE_BAN", createBan)
  yield takeLatest("MODIFY_BAN", modifyBan)
  yield takeLatest("DELETE_BAN", deleteBan)
  yield takeLatest("FETCH_BANNED_CUSTOMER", fetchBannedCustomer)
  yield takeLatest("FETCH_BAN_LIST", fetchBanList)
  yield takeLatest("MODIFY_USER_GROUP", modifyUserGroup)

  //On events
  yield takeLatest("ON_UPDATE_CUSTOMER", onUpdateCustomer)

  //Listings
  yield takeLatest("PAGE_LISTINGS", pageListing)
  yield takeLatest("RELOAD_CHECKOUT_LIST", reloadCheckoutList)

  //Load user config info
  yield takeLatest("LOAD_USER_CONFIG", loadUserConfig)

  //Utils
  yield takeLatest("DUPLICATE_RESERVATION", duplicateReservation)

  yield takeLatest("STARRED_CUSTOMER", starredCustomer)

  //Scheduler
  yield takeLatest("RELOAD_RESERVATION_LIST_SCHEDULER",reloadReservationListScheduler)

  //Massive Checkout
  yield takeLatest("MASSIVE_CHECKOUT", massiveCheckout)
}
