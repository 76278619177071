import React,{useState,useEffect,useRef, useMemo} from 'react'
import moment from 'moment'
import ImageMapper from 'react-image-mapper';
import {BuscarReserva,ElegirColorMapa,InformacionReservasActual,findTopRightPolyCorner,findTopRightRectCorner, HabitacionesDisponibles} from '../../../Funciones/utils';
import {connect} from 'react-redux'
import {Segment,Radio,Header} from 'semantic-ui-react'
import LugaresSuperpuestos from './lugaresSuperpuestos';
//Actions
import setReserva from '../../../redux/actions/setReserva'
import setModal from '../../../redux/actions/setModalReserva'


const Mapa=({isAdmin,isColorBlind,tipoPrecio,diaPlaya,changeDateTime,isFirstMobile:isMobile,mapInfo,listaDeptos,fecha,listaReservas,dispatch})=>{
const [imgWidth,setImgWidth]=useState()
const [imgNaturalWidth,setImgNaturalWidth]=useState(1)
const [map,setMap]=useState('')
const [repeatedPlace,setRepeatedPlace]=useState([])
const [showRepeated,setShowRepeated]=useState(false)
const imgRef=useRef();
const [update, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);

useEffect(()=>{
    if (mapInfo!=undefined&&imgWidth!=undefined) {
        let even=false;
        let info=InformacionReservasActual(listaReservas.filter(x=>x.state=='checkin'||x.state=="confirmada"),fecha);
        const rW=imgWidth/imgNaturalWidth;
        info=[...info.entran,...info.estancias,...info.salen]
        const idList=info.map(x=>x.roomsList.map(y=>y.roomId)).flat(2);
        const placesRepeated=listaDeptos.map(x=>({
            number:x.number,
            id:x.roomId,
            length:idList.filter(y=>y==x.roomId).length,
        })).filter(x=>x.length>1)
        const mapInfoAux=JSON.parse(JSON.stringify(mapInfo))
   
        let count=0;
        mapInfoAux.areas.forEach(x=>{
            if (mapInfo.even&&count%mapInfo.even==0) {
                even=!even;
            }
            x.coords=x.coords.map(y=>y*rW)
            const reservaEncontada=info?info.find(y=>y.roomsList.find(z=>z.number==x.name))?info.find(y=>y.roomsList.find(z=>z.number==x.name)):"":"";
            x.preFillColor= (reservaEncontada)?ElegirColorMapa(reservaEncontada.state,reservaEncontada.isDeudor):even?"#11ed2a":"#0ebd22";
            x.fillColor= (reservaEncontada)?ElegirColorMapa(reservaEncontada.state,reservaEncontada.isDeudor):even?"#11ed2a":"#0ebd22";
            x.reservationId=reservaEncontada&&reservaEncontada.reservationId;
            if (x.shape=="rect"){
                x.showAt=x.showAt?.map(y=>(y*rW))||[(x.coords[0]+x.coords[2])/2,(x.coords[1]+x.coords[3])/2]
                x.topRightCorner=x.showAt&&findTopRightRectCorner(x);

            }
            else{
                x.showAt=x.showAt.map(y=>(y*rW))
                x.topRightCorner=findTopRightPolyCorner(x);
            }
            
            x.topRightCorner[0]=x.topRightCorner[0]-(isMobile?10:15*rW);
            x.topRightCorner[1]=x.topRightCorner[1]+(isMobile?0:10*rW);
            count=count+1;
        })
        setRepeatedPlace(placesRepeated.map(x=>({
            showAt:mapInfoAux.areas.find(y=>y.name==x.number)?.topRightCorner,
            show:x.length,
            number:x.number
        })))
        setMap(mapInfoAux);
        setTimeout(()=>{forceUpdate()},1000) 
    }
    
},[listaReservas,mapInfo,imgWidth])
const onImgLoad=({current:img}) =>{
    setImgWidth(img?.img?.width);
    setImgNaturalWidth(img?.img?.naturalWidth)
}
const habitacioinesOcupadas=  useMemo(()=>listaReservas.filter(x=>x.state=="checkin").map(x=>x.roomsList).flat(2).map(x=>x.roomId).length,[listaReservas])
const colorList= [{name:"verde",value:"#0ebd22",condition:"libre"}, {name:"amarillo",value:"#ffd700",condition:"deudor"}, {name:"rojo",value:"#df6b77",condition:"ocupado"},{name:"azul",value:"#00aae4",condition:"reserva"}]
return(
  <React.Fragment>
      <LugaresSuperpuestos/>
     {mapInfo.mapLink&&<Segment>
     <div style={{gridTemplateColumns:'1fr 1fr 1fr 1fr',display:'grid',gridGap:'10px'}}>
     {isColorBlind&&colorList.map(x=>
     (
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <div style={{width:'20px',height:'20px',backgroundColor:x.value}}/>
                <Header style={{marginTop:"5px"}} as='h4'>{`${x.condition} (${x.name})`}</Header>
        </div>

        ))}
                </div>

         <div style={{flexDirection:'column',alignItems:`center`,display:`flex`,flexGrow:'9999'}}>
         {isAdmin&&`Lugares ocupados: ${habitacioinesOcupadas}, lugares libres: ${listaDeptos.length-habitacioinesOcupadas}, ocupación ${Math.round((habitacioinesOcupadas/listaDeptos.length)*100)}%`}
             {/* <Radio toggle checked={showRepeated} onChange={(a)=>{setShowRepeated(!showRepeated)}} label={"Mostrar superpuestos"}/> */}
             <div style={{position:'relative'}}>
                <ImageMapper src={mapInfo.mapLink} map={map||{areas:[]}} width={isMobile?750:1300}
                    ref={imgRef}
                    onLoad={() =>{onImgLoad(imgRef)} }
                    onClick={area => {
                        if (area.reservationId) {
                            const foundPlace=repeatedPlace.find(x=>x.number==area.name)
                            if (foundPlace) {
                                dispatch({payload:foundPlace,type:"CHOOSED_PLACE"})
                            }
                            else{
                                dispatch(setReserva(BuscarReserva(area.reservationId)));
                                dispatch(setModal(true))
                            }
                        }
                        else{
                            const nuevaReserva= {
                                "guests": 2,
                                "children": 0,
                                "checkoutEstimated": moment().add(-changeDateTime,'hours').add(1,'days').startOf('day').add(8,'hours').format(),
                                "checkinEstimated": moment().add(-changeDateTime,'hours').format(`YYYY-MM-DD`),
                                "state": "",
                                servicesList:[],
                                customersList:[],
                                vehiclesList:[],
                                nights:diaPlaya?0:1,
                                nuevaReserva:true,
                                "way": "Presencial",
                                "roomsList":[listaDeptos.find(x=>x.number==area.name)],
                            }
                            dispatch(setReserva(nuevaReserva));
                            dispatch(setModal(true));
                        }
                        }}
                    onMouseEnter={() =>{} }
                    onMouseLeave={() =>{} }
                    onMouseMove={() =>{} }
                    onImageClick={() =>{} }
                    onImageMouseMove={() =>{} }
                />
                {map.areas&&map.areas.map((x,i)=><span key={i} className="tooltip"
                    style={{ top: `${x.showAt[1]-15+(isMobile?5:0)}px`, left: `${x.showAt[0]+((parseInt(x.show)<10)?5:0)-15+(isMobile?5:0)}px`,opacity:1,pointerEvents:'none',fontSize:isMobile?x.fontSize/1.5:x.fontSize,fontWeight:'bold'}}>
                    { x.show}
                </span>)}
                {showRepeated&&repeatedPlace&&repeatedPlace.map((x,i)=><span key={i} 
                className="tooltip polygon"
                    style={{ top: `${x.showAt[1]-15+(isMobile?10:5)}px`, left: `${x.showAt[0]+((parseInt(x.show)<10)?5:0)-15+(isMobile?10:-10)}px`,opacity:1,pointerEvents:'none',width:isMobile?"1.2rem":"2rem",lineHeight:isMobile?"1.2rem":"2rem",fontSize:isMobile?"12px":"18px",fontWeight:'bold'}}>
                    { x.show}
                </span>)}
            </div>
        </div>

    </Segment>}

  </React.Fragment>
    
)
}

export default connect(state=>({isAdmin:state.mainReducer.isAdmin,isColorBlind:state.mainReducer.isColorBlind,tipoPrecio:state.mainReducer.tipoPrecio,diaPlaya:state.mainReducer.diaPlaya,changeDateTime:state.mainReducer.changeDateTime,isFirstMobile:state.mainReducer.isFirstMobile,mapLink:state.mainReducer.mapLink,mapInfo:state.mainReducer.mapInfo,listaDeptos:state.mainReducer.listaDeptos,listaReservas:state.mainReducer.listaReservas,isMobile:state.mainReducer.isMobile}),null)(Mapa)
