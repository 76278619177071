import React, { useState, useRef, useEffect, lazy, Suspense } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Auth } from "aws-amplify";
//import {TinyDatePicker} from 'tiny-date-picker/dist/date-range-picker'
import DateRangePicker from "../components/dormis/extras/dateRangePicker";
import Scheduler from "../components/dormis/main/scheduler";
import Modal from "../components/dormis/modal/";
import DisplayFechaModal from "../components/dormis/main/displayFecha";
import { Label, Button } from "semantic-ui-react";
import { Col } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import logo from "../../static/logo.svg";
import logomini from "../../static/logo-mini.svg";
import {ModalRangoFechas} from "../components/dormis/rangoFechas/modalRangoFechas"
import {
  CalcularCheckoutTime,
  ActualizarMapInfo,
  CapitalFirst
} from "../Funciones/utils";
import {
  Menu,
  Icon,
  Progress,
  Segment,
  Search,
} from "semantic-ui-react";
import StepWizard from "react-step-wizard";
import TablaRegistro from "../components/dormis/listado/tablaRegistro";
import Precios from "../components/dormis/precio/precioDormis";
import { Collapse } from "react-collapse";
import Caja from "../components/dormis/caja/caja";
import Estadisticas from "../components/dormis/estadisticas/main";
import Mapa from "../components/dormis/mapa/mapa";
import TipoMenu from "../components/dormis/extras/tipoMenu";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import useOnlineStatus from "@rehooks/online-status";

//ACTIONS
import setReserva from "../redux/actions/setReserva";
import setModal from "../redux/actions/setModalReserva";
import setIsMobile from "../redux/actions/setIsMobile";
import setRegularCustomer from "../redux/actions/setRegularCustomer";

// Lazy load
const Prohibidos = lazy(() => import('../components/dormis/prohibidos'));
const Promociones= lazy(() => import('../components/dormis/promociones'));
const EjecutarActualizacion = async (dispatch) => {
  const daysFromNowToElevenMonths = moment()
    .add(11, "months")
    .endOf("month")
    .diff(moment(), "days");
  dispatch({
    type: "INIT_PRICES",
    payload: {
      from: moment()
        .add(-60, "days")
        .format("YYYY-MM-DD"),
      to: moment()
        .add(daysFromNowToElevenMonths, "days")
        .format("YYYY-MM-DD"),
    },
  });
  dispatch({ type: "LOAD_USER_CONFIG" });
  ActualizarMapInfo(dispatch);
};

const Calendario = ({
  dispatch,
  withoutPlace,
  fecha,
  listaReservas,
  setLoading,
}) => {
  const [fechaInicio, setFechaInicio] = useState(null);
  const [cantidadDias, setCantidadDias] = useState(230);
  const [rangoFechasOpened, setRangoFechasOpened] = useState(false);
  const queues = useSelector((state) => state.queueReducer);
  const {syncingLocalChanges} = useSelector((state) => state.internetStatusReducer);
  const startRef = useRef();
  const endRef = useRef();
  const pendingChanges = queues.newReservationsQueue.length + queues.cancelServicesQueue.length + queues.paymentsQueue.length + queues.servicesQueue.length + queues.modifyReservationsQueue.length;
  return (
    <React.Fragment>
     <ModalRangoFechas open={rangoFechasOpened} setOpen={setRangoFechasOpened} setFechaInicio={setFechaInicio} setCantidadDias={setCantidadDias} fechaInicio={fechaInicio} cantidadDias={cantidadDias} setLoading={setLoading} dispatch={dispatch} listaReservas={listaReservas} withoutPlace={withoutPlace} />
      <Menu pointing secondary>
        <Menu.Item
          position="right"
          name="Fechas especificas"
          icon="calendar alternate outline"
          active={rangoFechasOpened}
          onClick={() => {
            setRangoFechasOpened(!rangoFechasOpened);
          }}
        />
        <Menu.Item
          name="Cambios pendientes"
          active={false}
        >
          {/* add tooltip on hover */}
          <div style={{marginBottom:"-5px"}} aria-label={syncingLocalChanges?"Sincronizando cambios":Boolean(pendingChanges)?`Quedan ${pendingChanges} cambios por sincronizar`:"Todo sincronizado!"} data-microtip-position="left" role="tooltip">
            {Boolean(pendingChanges) &&<Label style={{marginRight: "5px"}} color="red">{pendingChanges}</Label>}
            <Icon style={{marginBottom:"3px"}} name={syncingLocalChanges?"spinner":Boolean(pendingChanges)?"clock outline":"check"} color={syncingLocalChanges?"grey":(Boolean(pendingChanges))?"red":"green"} />
          </div>
        </Menu.Item>          
      </Menu>
      {
        <Scheduler
          withoutPlace={withoutPlace}
          listaReservas={listaReservas.filter((x) => x.state != "cancelada")}
          setLoading={setLoading}
          fechaInicio={fechaInicio}
          cantidadDias={cantidadDias}
        />
      }
      <Modal />
      <DisplayFechaModal fecha={fecha} mostrarFull={true} />
    </React.Fragment>
  );
};

const Index = () => {
  const [mainMenu, setMainMenu] = useState("calendario");
  const [stepWizard, setStepWizard] = useState({});
  const [collapse, setCollapse] = useState(false);
  const [stateLabel, setStateLabel] = useState("");
  const [search, setSearch] = useState();
  const withoutPlace = useSelector((state) => state.mainReducer.withoutPlace);
  const currentBuildNumber = useSelector(
    (state) => state.mainReducer.currentBuildNumber
  );
  const statisticsDate = useSelector(
    (state) => state.mainReducer.statisticsDate
  );
  const listaGeneralReservas = useSelector(
    (state) => state.mainReducer.listaGeneralReservas
  );
  const listaGeneralDeptos = useSelector(
    (state) => state.mainReducer.listaGeneralDeptos
  );
  const sidebarOpened = useSelector((state) => state.mainReducer.sidebarOpened);
  const searchResult = useSelector((state) => state.mainReducer.searchResult);
  const searchLoading = useSelector((state) => state.mainReducer.searchLoading);
  const paymentListDay = useSelector(
    (state) => state.mainReducer.paymentListDay
  );
  const diaPlaya = useSelector((state) => state.mainReducer.diaPlaya);
  const changeDateTime = useSelector(
    (state) => state.mainReducer.changeDateTime
  );
  const loadingBar = useSelector((state) => state.mainReducer.loadingBar);
  const isAdmin = useSelector((state) => state.mainReducer.isAdmin);
  const tipoPrecio = useSelector((state) => state.mainReducer.tipoPrecio);
  const precios = useSelector((state) => state.mainReducer.precios);
  const mergeRoomType=precios.find(x=>x.type==tipoPrecio)?.mergeRoomType
  const loading = useSelector((state) => state.mainReducer.mainLoading);
  const currentUser = useSelector((state) => state.mainReducer.currentUser);
  const fecha = useSelector((state) => state.mainReducer.fecha);
  const listaReservas = useSelector((state) => state.mainReducer.listaReservas);
  const isMobile = useSelector((state) => state.mainReducer.isMobile);
  const mapLink = useSelector((state) => state.mainReducer.mapInfo.mapLink);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Escucha los cambios de tamaño de la ventana
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Limpiar listener al desmontar componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // const queues = useSelector((state) => state.queueReducer);
  const subscriptionEnds = useSelector(
    (state) => state.mainReducer.subscriptionEnds
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const mobile = window.innerWidth < 600;
    dispatch(setIsMobile(mobile));
    const val = localStorage.getItem("regularCustomer");
    dispatch(setRegularCustomer(val));
    EjecutarActualizacion(dispatch);
    dispatch({
      type: "SET_STATISTICS_DATE",
      payload: moment().format("YYYY-MM-DD"),
    });
    const reloadTime = () => {
      setTimeout(() => {
        reloadTime();
        dispatch({ type: "RELOAD_RESERVATIONS" });
        dispatch({ type: "RELOAD_PRICE_LIST" });
        dispatch({ type: "SEND_ALL_QUEUES" });

      }, 120000);
    };
    reloadTime();
  }, []);

  useEffect(() => {
    dispatch({ type: "INIT_CASH_MOVEMENT" });
  }, []);

  useEffect(() => {
    dispatch({ type: "RELOAD_STATISTICS_BY_MONTH" });
  }, [statisticsDate]);
  useEffect(() => {
    const actualizarApp = () => {
      /*ActualizarListaReservas(()=>{},dispatch)
      RefrescarPrecioYDispo(dispatch)*/
    };
    window.addEventListener("webkitvisibilitychange", actualizarApp);
    return () => {
      window.removeEventListener("webkitvisibilitychange", actualizarApp);
    };
  }, []);
  
  useEffect(() => {
    dispatch({ type: "LISTA_DEPTOS_BY_TYPE" });
  }, [listaGeneralDeptos]);

  useEffect(() => {
    dispatch({ type: "PAGE_LISTINGS", payload: 0 });
    dispatch({ type: "RELOAD_PRE_RESERVATION", payload: {page:0} });
    dispatch({ type: "RELOAD_CANCELLED_RESERVATION", payload: {page:0} });
  }, []);


  useEffect(() => {
    //Cuando se actualiza el precio, si no tiene mapa cambiar a calendario
    if (mainMenu == "mapa" && (tipoPrecio?.toLowerCase() != "camping" || mergeRoomType?.toLowerCase() != "camping")) {
      stepWizard.goToStep(1);
      setMainMenu("calendario");
    }
  }, [tipoPrecio]);

  useEffect(() => {
    dispatch({ type: "RELOAD_PAYMENT_LIST_STATISTICS" });
  }, [paymentListDay]);
  const isOnline = useOnlineStatus();

  useEffect(() => {
    const recheckMobile = () => {
      const mobile = window.innerWidth < 600;
      dispatch(setIsMobile(mobile));
    };
    window.addEventListener("resize", recheckMobile, true);

    const mobile = window.innerWidth < 600;
    dispatch({ payload: mobile, type: "SET_FIRST_MOBILE" });
    return () => {
      window.removeEventListener("resize", recheckMobile);
    };
  }, []);

  useEffect(() => {
    Auth.currentSession().then((x) => {
      dispatch({ type: "SET_SESSION", payload: x });
      dispatch({
        type: "SET_ADMIN",
        payload:
          x?.accessToken?.payload["cognito:groups"].filter(
            (x) => x == "superUser"
          ).length != 0,
      });
      dispatch({
        type: "SET_CAMPING_USER",
        payload: x?.accessToken?.payload["cognito:groups"]
          .find((x) => x.split("-").length == 2)
          .split("-")[1],
      });
      dispatch({
        type: "SET_CURRENT_USER",
        payload: x.accessToken.payload.username,
      });
    });
    dispatch({ type: "TEST", payload: "bool" });
  }, []);

  useEffect(() => {
    dispatch({ type: "RELOAD_CASH_MOVEMENT" });
  }, [currentBuildNumber]);

  useEffect(() => {
    if (search != undefined && search.trim() != "" && search.length > 2) {
      dispatch({ type: "SEARCH", payload: { searchThing: search } });
    }
  }, [search]);

  const setLoading = (bool) => {
    dispatch({ type: "MAIN_LOADING", payload: bool });
  };
  const nuevaReserva = () => {
    dispatch({ type: "NEW_RESERVATION" });
  };
  const abrirPrecios = () => {
    const checkoutEstimated = moment()
      .add(-changeDateTime, "hours")
      .add(1, "days")
      .set({
        hour: CalcularCheckoutTime() - 3,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .format();
    const checkinEstimated = moment()
      .add(-changeDateTime, "hours")
      .set({
        hour: CalcularCheckoutTime() - 3,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .format();
    const nuevaReserva = {
      guests: 2,
      children: 0,
      checkoutEstimated,
      checkinEstimated,
      servicesList: [],
      customersList: [],
      vehiclesList: [],
      nuevaReserva: true,
      calcularPrecio: true,
      nights: diaPlaya ? 0 : 1,
      state: "",
      way: "Presencial",
      roomsList: [],
    };
    dispatch(setReserva(nuevaReserva));
    dispatch(setModal(true));
  };

  const isSubscriptionEnding = subscriptionEnds&&moment(subscriptionEnds).isBefore(moment());
  const finallyEndSubscription=moment(subscriptionEnds).add(6,'day');
  const mobileIconSize=isMobile?"large":"";

  if(finallyEndSubscription.isBefore(moment())){
    alert("Su suscripción ha finalizado, por favor contactenos para renovarla");
    Auth.signOut();
  }
  const goTo = (menuItem) => {
    if(menuItem=='calendario'){
      stepWizard.goToStep(1);
    }
    else if(menuItem=='listado'){
      stepWizard.goToStep(2);
    }
    else if(menuItem=='mapa'){
      stepWizard.goToStep(3);
    }
    else if(menuItem=='caja'){
      stepWizard.goToStep(4);
    }
    else if(menuItem=='estadisticas'){
      stepWizard.goToStep(5);
    }
    else if(menuItem=='precios'){
      stepWizard.goToStep(6);
    }
    else if(menuItem=='prohibidos'){
      stepWizard.goToStep(7);
    }
    else if(menuItem=='promociones'){
      stepWizard.goToStep(8);
    }
    setMainMenu(menuItem);
  }
  const goToReservationState = (state) => {
    setMainMenu("listado");
    stepWizard.goToStep(2);
    setStateLabel(state);
  };
  const onCollapse = () => {
    setCollapse(!collapse);
  };
  const showMap = mapLink && (tipoPrecio?.toLowerCase() == "camping" || mergeRoomType?.toLowerCase() == "camping") 
  const tabCalendarioActiveClass = mainMenu == "calendario" && "menu-item-selected";
  const tabListadoActiveClass = mainMenu == "listado" && "menu-item-selected";
  const tabMapaActiveClass = mainMenu == "mapa" && "menu-item-selected";
  const tabCajaActiveClass = mainMenu == "caja" && "menu-item-selected";
  const tabEstadisticasActiveClass = mainMenu == "estadisticas" && "menu-item-selected";
  const tabPreciosActiveClass = mainMenu == "precios" && "menu-item-selected";
  const tabProhibidosActiveClass = mainMenu == "prohibidos" && "menu-item-selected";
  const tabPromocionesActiveClass = mainMenu == "promociones" && "menu-item-selected";
  const isCollapse = collapse || isMobile
  return (
    <React.Fragment>
      <LoadingOverlay active={loading} spinner text="Buscando">
        
        <div style={{display: "flex", gap:"1rem",zIndex:'99'}}>
          
          {/* Sidebar */}
          {sidebarOpened &&<div className="transition-sidebar" style={{display:"flex", overflow:"auto", textAlign:'left',color:"#fff", justifyContent:"space-between" ,flexDirection:"column",position:"fixed" ,width: !isCollapse?windowWidth<1000?`230px`:`270px`:`120px`,top:"0px", left:"0px",bottom:"0px", backgroundColor: "#f4f4f4", padding: "20px", backgroundColor:"#070d5c", fontSize:"1.1em", zIndex:"99"}}>
            <div>
              {!isCollapse && <img src={logo} style={{width: "100%", padding:"2em", marginBottom:"5px"}}/>}
              {isCollapse && <img src={logomini} style={{maxWidth: "80px"}}/>}
              {!isCollapse &&<div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginBottom:"1em"}}>
                <span style={{fontSize:"1em"}}>{`Bienvenido ${CapitalFirst(currentUser)}!`}</span>
              </div>}
              <button onClick={nuevaReserva} style={{ 
                width: "100%", 
                padding: "0.5rem 1.4rem", 
                display: "flex", 
                fontSize: "1rem", 
                justifyContent: "space-evenly",
                backgroundColor: "#091279", 
                color: "#fff", 
                border: "2px solid #1020dc", 
                borderRadius: "5px",
                boxSizing: "border-box",
                alignContent:"center",
                alignItems:"center"
            }}>
              <span style={{fontSize: "1.9em", marginBottom:"0.1em"}}>+</span>
              {!isCollapse &&<span style={{fontSize: "1.2em"}}>Nuevo Registro</span>}
            </button>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
            <div style={{display:"grid", marginTop:"1.5em", alignItems:isCollapse&&`center`}} >
              <div className={`menu-item ${tabCalendarioActiveClass}`} onClick={goTo.bind(this,"calendario")}> 
                <Icon name="calendar alternate outline" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                {!isCollapse && "Calendario"}
              </div>
              <div className={`menu-item ${tabListadoActiveClass}`} onClick={goTo.bind(this,"listado")}>
                <Icon name="list" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                {!isCollapse && "Listado"}
              </div>
              {showMap &&
                <div className={`menu-item ${tabMapaActiveClass}`} onClick={goTo.bind(this,"mapa")}>
                  <Icon name="map outline" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                  {!isCollapse && "Mapa"}
                </div>
              }
              <div className={`menu-item ${tabCajaActiveClass}`} onClick={goTo.bind(this,"caja")}>
                <Icon name="privacy" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                {!isCollapse && "Caja"}
              </div>
              {isAdmin && <div className={`menu-item ${tabEstadisticasActiveClass}`} onClick={goTo.bind(this,"estadisticas")}>
                <Icon name="chart bar outline" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                {!isCollapse && "Estadísticas"}
              </div>}
              <div className={`menu-item ${tabPreciosActiveClass}`} onClick={goTo.bind(this,"precios")}>
                <Icon name="dollar" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                {!isCollapse && "Precios"}
              </div>
              <div className={`menu-item ${tabProhibidosActiveClass}`} onClick={goTo.bind(this,"prohibidos")}>
                <Icon name="ban" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                {!isCollapse && "Prohibidos"}
              </div>
              <div className={`menu-item ${tabPromocionesActiveClass}`} onClick={goTo.bind(this,"promociones")}>
                <Icon name="percent" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                {!isCollapse && "Promociones"}
              </div>
              <div className={`menu-item`} onClick={abrirPrecios.bind(this)}>
                <Icon name="calculator" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                {!isCollapse && "Calculadora"}
              </div>
            </div>
            </div>
            </div>
            <div>
            {!isMobile && <div className={`menu-item`} onClick={onCollapse}>
            {!isCollapse &&<Icon name="angle left" size={mobileIconSize} style={{marginRight:"0.5em"}}/>}
            {isCollapse &&<Icon name="angle right" size={mobileIconSize} style={{marginRight:"0.5em"}}/>}

                  {!isCollapse && "Ocultar"}
              </div>}
              <div className={`menu-item`} onClick={()=>{ 
                if (window.confirm("¿Seguro que desea cerrar sesion?")) {
                  localStorage.setItem("listaGeneralReservas", null);
                  Auth.signOut();
                }}}>
                  <Icon name="sign-out" size={mobileIconSize} style={{marginRight:"0.5em"}}/>
                  {!isCollapse && "Salir"}
              </div>
            </div>
          </div>}
          {/* Main Content */}
          <div className="transition-mainmenu" style={{padding: "20px", paddingRight:!sidebarOpened?"0px":"", paddingLeft:!sidebarOpened?"0px":!isCollapse?windowWidth<1000?`230px`:`270px`:`120px`, width:"100%"}}>
            <Col
              className="transition" 
              style={{ padding: !sidebarOpened ? 0 : "", minHeight: "100vh" }}
              xs={12}
            >
              <TipoMenu
                isMobile={isMobile}
                sidebarOpened={sidebarOpened}
              />
              {isMobile && (
                <Search
                  noResultsMessage={"No se encontraron registros"}
                  loading={searchLoading}
                  aligned={"center"}
                  style={{ margin: "auto" }}
                  size={isMobile ? mobileIconSize : ""}
                  minCharacters={"3"}
                  onSearchChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  results={searchResult}
                  value={search}
                />
              )}

              <Segment>
                <Col xs={12}>
                  <Label
                    color="blue"
                    horizontal
                    style={{ cursor: "pointer" }}
                    onClick={() => goToReservationState("fllegar")}
                  >
                    Reserva
                  </Label>
                  <Label
                    color="green"
                    horizontal
                    style={{ cursor: "pointer" }}
                    onClick={() => goToReservationState("alojados")}
                  >
                    Checkin
                  </Label>
                  <Label
                    color="yellow"
                    horizontal
                    style={{ cursor: "pointer" }}
                    onClick={() => goToReservationState("adeudados")}
                  >
                    Adeudado
                  </Label>
                  <Label
                    color="brown"
                    horizontal
                    style={{ cursor: "pointer" }}
                    onClick={() => goToReservationState("todas")}
                  >
                    Checkout
                  </Label>
                </Col>

                <Progress
                  success={!loadingBar&&isOnline}
                  style={{ marginBottom: "10px" }}
                  percent={loadingBar ? 50 : 100}
                  active={loadingBar}
                ></Progress>
                {isSubscriptionEnding && (
                  <Label
                    style={{ textAlign: "center" }}
                    color="red"
                    size={"large"}
                  >
                    ¡ATENCIÓN! Tu suscripción vence en {finallyEndSubscription.diff(moment(),'days')} dias, {finallyEndSubscription.diff(moment(),'hours')%24} horas y {finallyEndSubscription.diff(moment(),'minutes')%60} minutos.
                    Por favor comunicate con nosotros para regularizar el pago.

                  </Label>
                )}
                {//Center text that says no internet
                !isOnline && (
                  <Label
                    style={{ textAlign: "center" }}
                    color="grey"
                    size={"large"}
                  >
                    No hay conexión a internet
                  </Label>
                )}
                {/* {console.log(listaReservas.find(x=>x.roomsList.find(y=>y.number=="5-34")))} */}
                <StepWizard initialStep={1} instance={(e) => setStepWizard(e)}>
                  <Calendario
                    isMobile={isMobile}
                    checkoutTime={CalcularCheckoutTime()}
                    dispatch={dispatch}
                    listaReservas={listaReservas}
                    fecha={fecha}
                    setLoading={setLoading}
                    withoutPlace={withoutPlace}
                  />
                  <TablaRegistro
                    dispatch={dispatch}
                    listaReservas={listaReservas}
                    stateLabel={stateLabel}
                  />
                  <Mapa fecha={moment().format("YYYY-MM-DD")} />
                  <Caja />
                  <Estadisticas />
                  <Precios setLoading={setLoading} />
                  <div>
                    {mainMenu === "prohibidos" &&
                    <Suspense fallback={<div>Cargando...</div>}>
                      <Prohibidos />
                    </Suspense>
                    }
                  </div>
                  <div>
                    {mainMenu === "promociones" &&
                    <Suspense fallback={<div>Cargando...</div>}>
                      <Promociones />
                    </Suspense>
                    }
                  </div>
                  <Precios setLoading={setLoading} />
                </StepWizard>
              </Segment>
            </Col>
          </div>
        </div>
      </LoadingOverlay>
      <NotificationContainer />
    </React.Fragment>
  );
};

const Wrapper = () => {
  return (
    <Layout>
      <SEO title="Inicio" />
      <Index/>
    </Layout>
  );
};

export default Wrapper;
