import React, { useState, useEffect, useRef } from 'react';
import { DayPilot, DayPilotScheduler } from "daypilot-pro-react";
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import { ElegirColor } from '../../../Funciones/utils'
import { RefrescarPrecioYDispo } from '../../../Funciones/utils'
import { Icon } from 'semantic-ui-react'

//ACTIONS
import setReserva from '../../../redux/actions/setReserva'
import setModal from '../../../redux/actions/setModalReserva'
import setFecha from '../../../redux/actions/displayFecha'
import { reservationList } from '../../../graphql/queries';

const BuscarReserva = (listaReservas, listaReservasAux, index) => {
  const reserva = listaReservas.find(x => x.reservationId == listaReservasAux[index].reservationId)
  return reserva
}

const Scheduler = (props) => {
  const scheduler = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const hooked = window.alert
    window.alert = (e) => { if (e.toLocaleLowerCase().split('daypilot').length == 1) { hooked(e) } }
  }, []);

  var {roomsTypesChoosed,listaGeneralDeptos,checkoutTime,listaReservas,withoutPlace,fechaInicio,cantidadDias,listaDeptos:listaDeptosAux,disable,isMobile,startCalendarDay}=props;
  const listaDeptos =  listaGeneralDeptos.filter(x => roomsTypesChoosed.includes(x.type))
  const todosLosLugaresSonNumeros = listaDeptos.filter(x=>x.number.split("-").length>1).length==0;
  if(listaReservas==undefined){
    return <React.Fragment></React.Fragment>
  }
  const listaReservasAux=JSON.parse(JSON.stringify(listaReservas)).map((x,o)=>(x.roomsList.length==0&&withoutPlace)?[{...x,number:"sn"}]:x.roomsList.map((y,i)=>({...x,number:y.number}))).flat()
  let obj=[]
  for (var item of listaDeptos.sort((a, b) => parseInt(a.name) < parseInt(b.name) ? -1 : (parseInt(a.name) > parseInt(b.name) ? 1 : 0)).sort((a,b)=>b.orderRank-a.orderRank)){
    if (obj.find(x=>x.id==item.sector)==undefined){
      obj=[...obj,{id:item.sector,name:`Sector ${item.sector}`,expanded: true,}]
    }
  }
  for (var item of obj){
    if(todosLosLugaresSonNumeros)
    {
      item.children=listaDeptos.filter(x=>x.sector==item.id).map(x=>({name:x.number,id:x.number,orderRank:x.orderRank})).sort((a, b) => parseInt(a.name) < parseInt(b.name) ? -1 : (parseInt(a.name) > parseInt(b.name) ? 1 : 0)).sort((a,b)=>b.orderRank-a.orderRank);
    }
    else{
      item.children=listaDeptos.filter(x=>x.sector==item.id).map(x=>({name:x.number,id:x.number,orderRank:x.orderRank})).sort((a,b)=>a.name.split("-").length>1?parseInt(a.name.split("-")[1])-parseInt(b.name.split("-")[1]):a.name>b.name?1:-1).sort((a,b)=>b.orderRank-a.orderRank)
    }
  }

  if (obj.length==1){
    obj=listaDeptos.map(x=>({name:x.number,id:x.number,orderRank:x.orderRank})).sort((a, b) => parseInt(a.name) < parseInt(b.name) ? -1 : (parseInt(a.name) > parseInt(b.name) ? 1 : 0)).sort((a,b)=>b.orderRank-a.orderRank);
  }

  if(withoutPlace){
    obj=[{name:'Sin lugar',id:'sn'},...obj]
  }
  const fechaInicioAux=!fechaInicio?moment().add(startCalendarDay,'days').add(-3,"hours").format():moment(fechaInicio).format();

  const config={
        locale: "es-mx",
        startDate: fechaInicioAux,
        days: cantidadDias,
        scale: "Day",
        heightSpec:"Max",
        height : 700,
        treeEnabled : true,
        treePreventParentUsage :true,
        timeHeaders: [
            { groupBy: "Month"},
            { groupBy: "Day", format: "ddd d"}
        ],
        cellWidth:100,
        eventHeight:30,
        allowEventOverlap: false,
        onBeforeEventDomAdd: args => {
        args.element = <div>
          {args.e.data.text}
            <div style={{position: "absolute", right: isMobile?21:5, top: "9px", width: "17px", height: "17px"}}
                 /*onClick={() => this.deleteEvent(args.e)}*/><Icon name={`${(args.e.data.toolTip&&args.e.data.toolTip.trim()!="")?"edit":""}`}/></div>
          </div>;
        },   
        resources: obj,
        events: listaReservasAux.length==0?[]:listaReservasAux.map((x,o)=>{
          const starredCustomer=x.customersList?.find(y=>y.customerId == x.starredCustomerId)?.fullName;
          return{
          id: o, 
          text: isMobile&&moment(x.checkoutEstimated).diff(moment(fechaInicioAux),"days")==0?starredCustomer.split(" ")[0]:starredCustomer, 
          payed:x.percentPayed,
          toolTip: x.description,
          start: moment(x.checkinEstimated).format('YYYY-MM-DD'), 
          end: moment(x.checkoutEstimated).format('YYYY-MM-DD'), 
          resource: x.number, 
          barColor: ElegirColor(x.state,x.isDeudor), 
          barBackColor: ElegirColor(x.state,x.isDeudor)
          }
        }
        )
    };
    return (
    <React.Fragment>
            <DayPilotScheduler
              {...config}
              onEventMoved={args => {
              alert("Accion no permitida")
              RefrescarPrecioYDispo(dispatch)
              }}
              bubble={null}
              onBeforeEventRender={ args => {
                var paid = args.e.payed;
                args.data.areas = [
                  { left: 4, bottom: 4, right: 4, height: 2, html: "<div style='background-color:black; height: 100%; width:" + paid + "%'></div>", v: "Visible" },
              ];
              if (isMobile){
                args.data.areas=[...args.data.areas,
                  {right:2, top:6, bottom:2, width: 20, backColor: "lightblue", html: "&gt;", action:"ResizeEnd"},
                
                ]
                
              }
            }}
              onEventResized={args => {
                alert("Accion no permitida")
                RefrescarPrecioYDispo(dispatch)
              }}
              onBeforeCellRender = {function(args) {

                if (args.cell.start.getDatePart().getTime() === new DayPilot.Date().getDatePart().getTime()) {
                  args.cell.backColor = "#fff8b7";
              }
              if (args.cell.isParent) {
                args.cell.backColor = "#f3f3f3";
              }
              }}
              onBeforeTimeHeaderRender = {function(args) {
                args.header.areas = [ {left: 0, top: 0, right: 0, bottom: 0, v: "Hover", action: "JavaScript", js: function(e) { dispatch(setFecha(e.start.value))} } ];
            }}
              onEventClick={  
                ars=>{
                  dispatch(setReserva(BuscarReserva(listaReservas,listaReservasAux,ars.e.data.id)));
                  dispatch(setModal(true))
                  scheduler.current.clearSelection()
                  }
              }
              onTimeRangeSelected={args => {
                if (!disable){
                const checkoutEstimated= moment(args.end.value).add(checkoutTime-3,'hours').format();
                const checkinEstimated=args.start.value;
               const nuevaReserva= {
                  "guests": 2,
                  "children": 0,
                  checkoutEstimated,
                  checkinEstimated,
                  servicesList:[],
                  customersList:[],
                  vehiclesList:[],
                  nuevaReserva:true,
                  nights:moment(checkoutEstimated).diff(checkinEstimated,'days'),
                  "state": "",
                  "way": "Presencial",
                  "roomsList":args.resource=="sn"?[]:[listaDeptos.find(x=>x.number==args.resource)],
              }
                dispatch(setReserva(nuevaReserva))
                dispatch(setModal(true));
                scheduler.current.clearSelection()
               }
              else{
                alert("Solo se puede crear una reserva en la seccion de confirmadas")
                window.location.reload(false);
              }
              }}
              ref={component => { scheduler.current = component && component.control; }}
            />
  </React.Fragment>
    );
}

export default connect(state => ({roomsTypesChoosed:state.mainReducer.roomsTypesChoosed,listaGeneralDeptos:state.mainReducer.listaGeneralDeptos,startCalendarDay:state.mainReducer.startCalendarDay,checkoutTime:state.mainReducer.checkoutTime,listaDeptos:state.mainReducer.listaDeptos,refrescarDispoYPrecios:state.mainReducer.refrescarDispoYPrecios,isMobile:state.mainReducer.isMobile}),null)(Scheduler)