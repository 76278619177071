import React, { useState, useEffect, useCallback, useMemo, useRef } from "react"
import { HabitacionesDisponibles, CambiarEstadoReserva, MandarFormularioDepto, PuedeReservar, SacarDeLista, GetListaHorarios, chooseStarredCustomer } from '../../../Funciones/utils'
import moment from 'moment'
import { connect, useSelector } from 'react-redux'
import { Col, Row, Input, Container } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { Normalizar, ParseScan,DevolverComponentesParaJSX, EsComponenteExtra,EsLugar,EsVehiculo } from '../../../Funciones/utils'
import TablaVehiculos from './tablas/tablaVehiculos'
import TablaPersonas from './tablas/tablaPersonas'
import { Button as ButtonSemantic, Icon,Dropdown, Divider } from 'semantic-ui-react'
import 'react-phone-input-2/lib/style.css'
import Precios from './precios'
import ConfirmarPago from './confirmarPago'
import { Collapse } from 'react-collapse';
import { v4 as uuidv4 } from 'uuid';

Array.prototype.unique = function (a) {
  return function () { return this.filter(a) }
}(function (a, b, c) {
  return c.indexOf(a, b + 1) < 0
});


const MandarIngreso = (upsertReservation,state,service, setLoading, dispatch, testFunction,makeBill, goToPayment, onComplete, sobrevender, withoutPlace,components=null) => {
  const { roomsList, customersList, vehicleList, checkInEstimated, checkoutEstimated, reservation, checkoutTime, isNewReservation } = upsertReservation;
  if (
    moment(checkInEstimated).isValid() == "" ||
    moment(checkoutEstimated).isValid() == ""
  ) {
    alert("Alguna de las fechas no tiene formato valido")
  }
  else if (
    checkInEstimated.trim() == "" ||
    checkoutEstimated.trim() == ""
  ) {
    alert("Faltan completar fechas")
  }
  else if (roomsList.length == 0 && !withoutPlace) {
    alert("Falta poner lugar")
  }
  else if (customersList.length == 0) {
    alert("Debes cargar al menos una persona en el registro")
  }
  else if (customersList.filter(x=>x.fullName).length == 0) {
    alert("Todas las personas deben tener nombre")
  }
  // check if all customer have a valid email
  else if (customersList.filter(x=>x.email).length > 0 && customersList.filter(x=>x.email).filter(x=>x.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) == null).length > 0) {
    alert("Todas las personas deben tener un email valido")
  }
  else if (state == "") {
    alert("Falta agregar si es reserva o checkin")
  }
  else {
    const customersListSanitized = customersList.map(x => {
      const sanitized = { ...x };
      delete sanitized.age;
      return sanitized;
    });
    reservation.checkinEstimated = moment(checkInEstimated).format()
    reservation.checkoutEstimated = moment(checkoutEstimated).set({hour: checkoutTime.split(':')[0], minute: 0, second: 0, millisecond: 0 }).add(-3, 'hours').format()
    const vehiclesListSanitized = vehicleList.map(x => {
      const { tableData , ...sanitized} = x
      return sanitized;
    });
    const servicesList = isNewReservation ? [service] : reservation.servicesList;
    delete reservation.nuevaReserva
    const nuevoIngreso = {
      ...reservation,
      guests: 0,
      components,
      roomsList,
      servicesList,
      customersList:customersListSanitized,
      vehiclesList:vehiclesListSanitized
    }
    const puedeReservar = PuedeReservar(moment(reservation.checkinEstimated).format('YYYY-MM-DD'), moment(reservation.checkoutEstimated).format('YYYY-MM-DD'), { ...nuevoIngreso, roomsList: roomsList })
    if (state != 'cancelada' && state != 'checkout' && !puedeReservar.resultado && !sobrevender &&
      // eslint-disable-next-line no-empty
      !window.confirm(`El lugar ${puedeReservar.habitacion.number} no esta disponible para las fechas\n¿Quiere insertarlo al registro de todas formas?`)) {
    }
    else {
      //Cambio el ingreso
      if (state != nuevoIngreso.state) {
        nuevoIngreso.state = state
        switch (state) {
          case "checkin":
            nuevoIngreso.checkinMade = moment().format()
            nuevoIngreso.checkoutMade = null
            break;
          case "checkout":
            nuevoIngreso.checkoutMade = moment().format()
            break;
          case "confirmada":
            nuevoIngreso.state = "confirmada"
            nuevoIngreso.checkoutMade = null
            nuevoIngreso.checkinMade = null
            break;
          case "cancelada":
            nuevoIngreso.state = "cancelada"
            break;

        }
      }
      delete nuevoIngreso.isNewReservation
      if (isNewReservation) {
        setLoading(true)
        MandarFormularioDepto(
          nuevoIngreso,
          setLoading)
      }
      else {
        CambiarEstadoReserva(nuevoIngreso, setLoading, onComplete, dispatch, null)
      }
    }
  }
}

//Tambien aca se carga la reserva editada
export const NuevaReserva = ({diaPlaya,isAdmin, reserva: ingreso, dispatch, testFunction, nuevaReserva, isMobile, goToPayment, listaDeptos: listaDeptosTotal, withoutPlace }) => {
  const [listaDeptos, setListaDeptos] = useState([]);
  const [fechaIngreso, setFechaIngreso] = useState("");
  const [fechaSalida, setFechaSalida] = useState("");
  const [estado, setEstado] = useState("");
  const [lugarElegido, setLugarElegido] = useState("");
  const [loading, setLoading] = useState(false);
  const [sobrevender, setSobrevender] = useState(false);
  const [recargar, setRecargar] = useState(1);
  const [servicio, setServicio] = useState();
  const [hora, setHora] = useState('11:00');
  const [listIndex, setListIndex] = useState(0);
  const [costo, setCosto] = useState(0)
  const [openPago, setOpenPago] = useState(false);
  const [pagoParcial,setPagoParcial]=useState(0)
  const [forma, setForma] = useState();
  const [onPay, setOnPay] = useState(()=>{});
  const [componentes, setComponentes] = useState()
  const [abrirComponentes, setAbrirComponentes] = useState(false)
  const escaner= useRef()
  const state=useSelector(state=>state.customersReducer)
  useEffect(() => {
    if (moment(fechaSalida).diff(fechaIngreso, 'days') < 0) {
      setFechaSalida(moment(fechaIngreso).add(1, 'days').format('YYYY-MM-DD'))
    }
  }, [fechaSalida])

  useEffect(() => {
    setPagoParcial(costo)
  }, [costo])
  
  useEffect(() => {
    dispatch({type:'INIT_CUSTOMERS_LIST',payload:ingreso.customersList})
    dispatch({type:'ADD_CUSTOMER'})
    
    if (!isMobile){
      escaner.current.focus()
    }

    setEstado(ingreso.state)
    setHora(moment(ingreso.checkoutEstimated).add(3, 'hours').format("HH:mm"))
    setListaDeptos(ingreso.roomsList)
    setFechaIngreso(moment(ingreso.checkinEstimated).format("YYYY-MM-DD"))
    setFechaSalida(moment(ingreso.checkoutEstimated).format("YYYY-MM-DD"))
    setSobrevender(false)
    setAbrirComponentes(ingreso.nuevaReserva)
  }, [ingreso]);

  useEffect(()=>{
    const componentesAux=DevolverComponentesParaJSX(ingreso.vehiclesList,state.customersList,{...ingreso,roomsList:listaDeptos},[]);
    const extraComponents=componentes&&Object.keys(componentes).reduce((acom,current)=>{
    if(EsLugar(current)||EsComponenteExtra(current)){
    return{...acom,[current]:componentes[current]}}
    else{
      return acom;
    }
  },{})
    setComponentes({...componentesAux,...extraComponents})
  },[state.customersList])

  useEffect(()=>{
    const componentesAux=DevolverComponentesParaJSX(ingreso.vehiclesList,state.customersList,{...ingreso,roomsList:listaDeptos},[]);
    
    const extraComponents=componentes&&Object.keys(componentes).reduce((acom,current)=>{
    if(!EsVehiculo(current)){
    return{...acom,[current]:componentes[current]}}
    else{
      return acom;
    }
  },{})

    setComponentes({...componentesAux,...extraComponents})
  },[recargar])

  useEffect(()=>{
    const componentesAux=DevolverComponentesParaJSX(ingreso.vehiclesList,state.customersList,{...ingreso,roomsList:listaDeptos},[]);

    const extraComponents=componentes&&Object.keys(componentes).reduce((acom,current)=>{
    if(!EsLugar(current)){
    return{...acom,[current]:componentes[current]}}
    else{
      return acom;
    }
  },{})

  setComponentes({...componentesAux,...extraComponents})
  },[listaDeptos.length])


  useEffect(() => {
    if (window.scaner) {
      window.scaner.onUnduplicatedRead = (txt) => {
        try {
          const parserta = ParseScan(txt);

          if (parserta.fullName && parserta.dni && parserta.bday && state.customersList.filter(x => x.dni == parserta.dni).length == 0) {
            const birthdate = parserta.bday.split('/')[2] + "-" + parserta.bday.split('/')[1] + "-" + parserta.bday.split('/')[0]
            alert(`Nombre: ${parserta.fullName}\nSexo: ${parserta.sex}\nDni: ${parserta.dni}\nCumpleaños: ${birthdate}\n\nPersonas: ${state.customersList.length + 1}`)
            const customer= {
              fullName: Normalizar(parserta.fullName),
              dni: parserta.dni,
              birthdate: moment(birthdate).format(),
              age:moment().diff(birthdate,"years")
            }
              const customerId=uuidv4();
              dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...state.customer,customerId,...customer}})
              dispatch({type:"ADD_CUSTOMER"})
              
          }
        }
        catch (error) {
          alert("No se reconoce correctamente el formato del documento")
        }
      };
    }
  }, [state.customersList]);

  const noches = moment(fechaSalida).diff(moment(fechaIngreso), "days")
  const vehiculos = ingreso.vehiclesList ? ingreso.vehiclesList.map(x=>({...x})) : [];
  const setVehiculos = (a) => { ingreso.vehiclesList = a; setRecargar(Math.random() * 1000000) };
  const onComplete = () => { goToPayment() }
  const habitacionesDisponible = useMemo(() => HabitacionesDisponibles(fechaIngreso, fechaSalida), [fechaIngreso,fechaSalida]) 
  const habitacionesDisponiblesInput = useMemo(() => habitacionesDisponible.filter(x=>!listaDeptos.find(y=>x.number==y.number)).map(x=>({id:x.roomId,text:x.number,value:x.number})), [habitacionesDisponible,listaDeptos]) 
  const listaHorarios = GetListaHorarios();
  const InsertarDepto=useCallback(
    (deptoNumber) => {
    if (habitacionesDisponible.find(x => x.number == deptoNumber)) {
        setListaDeptos([...listaDeptos.filter(x => x.number != deptoNumber), habitacionesDisponible.find(x => (x.number == deptoNumber))])
      }
      else if (listaDeptosTotal.find(x => x.number == deptoNumber)) {
        if (window.confirm(`El lugar ${deptoNumber} no esta disponible para las fechas\n¿Quiere insertarlo al registro de todas formas?`)) {
          setSobrevender(true)
          setListaDeptos([...listaDeptos.filter(x => x.number != deptoNumber), listaDeptosTotal.find(x => x.number == deptoNumber)])
        }
      }
      else {
        alert(`El lugar ${deptoNumber} no es un lugar valido`)
      }
    },
    [listaDeptos,habitacionesDisponible],
  )

  const CorroborarRegistros=useCallback((lugarElegido,listaDeptos)=>{
    if (state.customersList.length == 0){
      alert('Debes cargar al menos una persona al registro')
      return false
    }
    else if (state.customersList.filter(x=>x.fullName.trim()!="").length == 0) {
      alert("Todas las personas deben tener nombre")
      return false
    }
    else if (!withoutPlace&&listaDeptos.length == 0&&lugarElegido=="") {
      alert('Falta poner lugar')
      return false
    }
    else if (!withoutPlace&&lugarElegido==""){
      return true;
    }
    else if (lugarElegido==""){
      return true;
    }
    else if(lugarElegido!=""&&
    (window.confirm(`El lugar ${lugarElegido} quedó pendiente de ser seleccionado ¿Desea agregarlo?`))){
      if (habitacionesDisponible.find(x => x.number == lugarElegido)) {
        setListaDeptos([...listaDeptos.filter(x => x.number != lugarElegido), habitacionesDisponible.find(x => (x.number == lugarElegido))])
        setLugarElegido('')
        return false;
      }
      else if (listaDeptosTotal.find(x => x.number == lugarElegido)) {
        if (window.confirm(`El lugar ${lugarElegido} no esta disponible para las fechas\n¿Quiere insertarlo al registro de todas formas?`)) {
          setSobrevender(true)
          setListaDeptos([...listaDeptos.filter(x => x.number != lugarElegido), listaDeptosTotal.find(x => (x.number == lugarElegido))])
          setLugarElegido('')
          return false;
        }
        else if(withoutPlace){
         return true
        }
        else{
          alert('Falta poner lugar')
          return false
        }
      }
      else {
        alert(`El lugar ${lugarElegido} no es un lugar valido`)
        return false;
      }  
    }
    
  },[habitacionesDisponible,listaDeptosTotal,withoutPlace,state.customersList])
  const customersToBill=state.customersList.filter(x=>x.dni)
  const upsertReservation=useMemo(()=>{
    return {
      roomsList:listaDeptos, 
      customersList:state.customersList, 
      vehicleList:vehiculos, 
      checkInEstimated:fechaIngreso, 
      checkoutEstimated:fechaSalida, 
      reservation:ingreso, 
      checkoutTime:hora, 
      state:estado, 
      isNewReservation:nuevaReserva
    }
  }
  ,[listaDeptos,state.customersList,vehiculos,fechaIngreso,fechaSalida,ingreso,hora,estado,nuevaReserva])
  const handleCollapseInputs=()=>{    
    !isMobile&&escaner.current.focus()
    setAbrirComponentes(!abrirComponentes)
  }
  
  const openCollapseItems=()=>{
    setAbrirComponentes(true)
  }

  return (
    <LoadingOverlay
      active={loading}
      spinner
      text='Agregando'
    >
      {openPago&&<ConfirmarPago 
      pagoParcial={pagoParcial}
      setPagoParcial={setPagoParcial}
      hora={hora}
      customersList={customersToBill.length>0?customersToBill:[]}
      newReservation={nuevaReserva} onPay={onPay} loading={loading} setForma={setForma} isMobile={isMobile} precio={costo} 
      open={openPago} setOpen={setOpenPago} nombreCuenta={chooseStarredCustomer(state.customersList,ingreso?.starredCustomerId).fullName}
      fechaSalida={fechaSalida} noches={servicio.quantity} forma={forma} descripcion={servicio?.description} listaDeptos={listaDeptos}/>}
      <Collapse isOpened={abrirComponentes}>
      <Row>
        {isMobile&&<Col xs={12}>
          <ButtonSemantic disabled={window.scaner == undefined} color='teal' style={{ fontSize: "18px", paddingTop: "10px", paddingBottom: "10px" }} fluid onClick={() => {
            window.scaner.show()
            window.scaner.onUnduplicatedRead = (txt) => {
              try {
                const parserta = ParseScan(txt);
                if (parserta.fullName && parserta.dni && parserta.bday) {
                  const birthdate = parserta.bday.split('/')[2] + "-" + parserta.bday.split('/')[1] + "-" + parserta.bday.split('/')[0]
                  alert(`Nombre: ${parserta.fullName}\nSexo: ${parserta.sex}\nDni: ${parserta.dni}\nCumpleaños: ${birthdate}\n\nPersonas: ${state.customersList.filter(x => x.dni != parserta.dni).length + 1}`)
                  const customer= {
                    fullName: Normalizar(parserta.fullName),
                    dni: parserta.dni,
                    birthdate: moment(birthdate).format(),
                    age:moment().diff(birthdate,"years")
                  }
                    const customerId=uuidv4();
                    const foundCustomer=state.customersList.find(x=>x.dni==parserta.dni)
                    if (foundCustomer) {
                      dispatch({ type: 'REMOVE_CUSTOMER', payload: foundCustomer })
                      dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...foundCustomer,birthdate:customer.birthdate,fullName:customer.fullName}})

                    }
                    else{
                      dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...state.customer,customerId,...customer}})
                    }
                    dispatch({type:"ADD_CUSTOMER"})

                }
              }
              catch (error) {
                alert("No se reconoce correctamente el formato del documento")
              }
            };
          }}>Escanear DNI</ButtonSemantic>
        </Col>}

      </Row>
      <Row>
      {!isMobile&&<Col xs={12}>
        <input 
          className={'inputHidden'}
          autoComplete="off"
          ref={escaner}
          onBlur={()=>dispatch({type:'SET_SCANER',payload:false})}
          onFocus={()=>dispatch({type:'SET_SCANER',payload:true})}
          onKeyPress={(e)=>{
            if(e.charCode == 13){
              const parserta = ParseScan(e.target.value);  
              const birthdate = parserta.bday?parserta.bday.split('-').join('/').split('/')[2] + "-" + parserta.bday.split('-').join('/').split('/')[1] + "-" + parserta.bday.split('-').join('/').split('/')[0]:'' 
              const customer={fullName: Normalizar(parserta.fullName), dni:parserta.dni, birthdate,age:moment().diff(birthdate,"years")};
              const foundCustomer=state.customersList.find(x=>x.dni==parserta.dni)
              if (foundCustomer) {
                dispatch({type:"REMOVE_CUSTOMER",payload:foundCustomer})
                dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...foundCustomer,birthdate:customer.birthdate,fullName:customer.fullName}})

              }
              else{
                dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...state.customer,...customer}})
              }
              e.target.value=""
              dispatch({type:"ADD_CUSTOMER"})
            }
          }}
          />
        </Col>}
      <Col xs={12} md={8}>
      <Row>
      <Col xs={12} md={4}> <h3>Nombre y Apellido</h3><Input 
        autocomplete="off"
        value={state.customer.fullName??""} 
        onChange={(e) => {
            dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...state.customer,fullName:Normalizar(e.target.value)}})          
          }} 
        name="nombre" id={"nombre"}></Input></Col>
        <Col xs={12} md={4}>
          <h3>Documento</h3>
          <Input autocomplete="off" value={state.customer.dni??""}
          onKeyPress={(target)=>{
          if(target.charCode == 13){
            dispatch({type:"ADD_CUSTOMER"})
          }}}
          onChange={(e) => dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...state.customer,dni:e.target.value}})} 
          name="dni" id={"dni"}></Input>
        </Col>
        <Col xs={12} md={2}> 
          <h3>Edad</h3>
          <Input autocomplete="off" id="edad" name="edad" 
          onKeyPress={(target)=>{
            if(target.charCode == 13){
              dispatch({type:"ADD_CUSTOMER"})
            }}}
          onChange={(e) => dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...state.customer,age:e.target.value,birthdate:moment().subtract(e.target.value, 'years').format()}})} 
          type="number" pattern="[0-9]*" value={state.customer.age??""}></Input></Col>
        {(!isMobile||!diaPlaya)&&<Col xs={12} md={4}> 
          <h3>Email</h3>
          <Input autocomplete="off"
          id={'email'}
          onKeyPress={(target)=>{
            if(target.charCode == 13){
              dispatch({type:"ADD_CUSTOMER"})
            }}}  
          onChange={(e) => {
            const emailVal=e.target.value.trim()!=""?e.target.value.trim():null;
            dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...state.customer,email:emailVal}})}}
          value={state.customer.email??""}></Input></Col>}
        <Col xs={12} md={4}> 
          <h3>Telefono</h3>
          <Input 
          id={'telefono'}
          onKeyPress={(target)=>{
            if(target.charCode == 13){
              dispatch({type:"ADD_CUSTOMER"})
            }}}
          onChange={(e) => dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...state.customer,phone:e.target.value}})}
          autocomplete="off"  type={'number'} pattern="[0-9]*+" value={state.customer.phone?.split(" ").join("")??""}></Input>
        </Col>
        <Col xs={12} md={4} style={{display:(!isMobile||!diaPlaya)?'':'none'}}> <h3>Procedencia</h3><Input 
        onKeyPress={(target)=>{
          if(target.charCode == 13){
            dispatch({type:"ADD_CUSTOMER"})
          }}}
        onChange={(e) => 
          dispatch({type:"ON_UPDATE_CUSTOMER",payload:{...state.customer,geo:`{"value":"${e.target.value}"}`}})}
        value={state.customer.geo?JSON.parse(state.customer.geo).value:""}
        id={'address-input'}
        autocomplete="off" type="search"/></Col>
         </Row>
      </Col>
      <Col xs={12} md={4}>
      {!isMobile && <br></br>}
         <Col xs={12} md={6} className={"d-flex align-items-center m-auto"} >
          <br></br>
          <br></br>
          <ButtonSemantic.Group vertical={isMobile} style={{ margin: "auto" }} widths={ingreso.state == 'confirmada' ? '3' : '2'}>
            <ButtonSemantic circular basic icon={state.scanerReady?'barcode':'add user'} color={"teal"}  className={'fz-40'} onClick={() => {
              dispatch({type:"ADD_CUSTOMER"})
              !isMobile&&escaner.current.focus()
            }}></ButtonSemantic>
            </ButtonSemantic.Group>
          </Col>
        </Col>
      </Row>
      </Collapse>
      {!ingreso.nuevaReserva&&
        <>
          {abrirComponentes && <Divider horizontal><Icon onClick={handleCollapseInputs} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle up' size='small' /></Divider>}
          {!abrirComponentes && <Divider horizontal><Icon onClick={handleCollapseInputs} style={{ fontSize: isMobile ? '2.7rem' : '1.8rem', cursor: 'pointer' }} name='arrow circle down' size='small' /></Divider>}
        </>
        }
        <Row>
        <Col xs={12} md={7}><h3>Personas ({state.customersList.length})</h3>
          <TablaPersonas openCollapseItems={openCollapseItems} data={JSON.parse(JSON.stringify(state.customersList))}
              dispatch={dispatch} listIndex={listIndex} recargar={() => setRecargar(Math.random() * 1000000)} setListIndex={setListIndex}></TablaPersonas>
        </Col>
        <Col xs={12} md={5}><h3>Vehiculos ({vehiculos.length})</h3>
          <TablaVehiculos data={vehiculos} setData={setVehiculos} reservationId={ingreso.reservationId}></TablaVehiculos>
        </Col>
        </Row>

      {!nuevaReserva&&<Row>
        <Col xs={12} md={{ size: 3, offset: 1 }}> <h3>Fecha entrada</h3><Input type="date" value={fechaIngreso} onChange={(e) => { setFechaSalida(moment(e.target.value).add(1, 'days').format('YYYY-MM-DD')); setFechaIngreso(e.target.value) }}></Input></Col>
        <Col xs={12} md={{ size: 3 }}> <h3>Fecha salida</h3><Input type="date" value={fechaSalida} onChange={(e) => setFechaSalida(e.target.value)}></Input></Col>
        <Col xs={12} md={{ size: 2 }}> <h3>Noches</h3><Input autocomplete="off" style={{ fontWeight: '800', textAlign: 'center' }} type="number" pattern="[0-9]*" value={noches} onChange={(e) => setFechaSalida(moment(fechaIngreso).add(e.target.value, 'days').format('YYYY-MM-DD'))}></Input></Col>
        <Col xs={12} md={{ size: 2}}> <h3>Hora de salida</h3><Input type="select" value={hora} onChange={(e) => setHora(e.target.value)}>{listaHorarios.map((x,i) => <option key={i}>{x}</option>)}</Input></Col>
      </Row>}

      <br></br>

      <br></br>
      {nuevaReserva&&<Precios 
        vistaCompleta={(!isMobile||!diaPlaya)}
        setHora={setHora}
        hora={hora}
        listaHorarios={listaHorarios}
        fechaIngreso={fechaIngreso} setFechaIngreso={setFechaIngreso}
        modificarFechaIngreso={true} servicesList={[]} ingreso={ingreso} componentes={componentes} 
        setComponentes={setComponentes} setFechaSalida={setFechaSalida} setServicio={setServicio} 
        costo={costo} setCosto={setCosto}/>}
      <br></br>
      {(!isMobile||!diaPlaya)&&<Row>
        <Col xs={12} md={{ size: 3, offset: 3 }}>
          <h3>{`Lugares (${listaDeptos.length})`}</h3>
          {listaDeptos.map((x,i) => (<a key={i} href="#" onClick={() => { SacarDeLista(listaDeptos, setListaDeptos, x) }} className="and btand btn btn-info" style={{ margin: '0px 7px 7px',paddingRight:'5px', overflow: 'hidden' }}>{x.number + ""}<Icon style={{marginLeft:'10px'}} name="x icon" /></a>))}
        </Col>
        {/*<Col xs={6} md={2}><h3>{ingreso.roomsList[0].number}</h3></Col>*/}
        <Col xs={12} md={2}>
          <h3>{`Disponibles (${habitacionesDisponiblesInput.length})`} </h3>
          <Dropdown 
          className="dropdownx"
          placeholder='Disponibles'  
          selection value={''} 
          fluid
          clearable
          search={(lista, value) =>{
            return lista.filter(x => x.value.toLowerCase().startsWith(value.toLowerCase()))
          }}
          selectOnBlur={false}
          allowAdditions={true}
          additionLabel={'Agregar '}
          
          onChange={(e,data)=>InsertarDepto(data.value.trim())} 
          //If all items in habDisponiblesInput are numbers sort by number
          options={habitacionesDisponiblesInput.sort((a,b)=>{
            if(!isNaN(a.value)&&!isNaN(b.value)){
              return a.value-b.value
            }
            if(a.value.split('-')[0]==b.value.split('-')[0]){
              return a.value.split('-')[1]-b.value.split('-')[1]
            }
            return a.value.localeCompare(b.value)
          })}
          />
        </Col>
      </Row>}
      <br></br>

      <Container>
        <Row>
          <Col style={{ display: "contents" }}>
            <ButtonSemantic.Group vertical={isMobile} style={{ margin: "auto" }} widths={'5'}>
              {!nuevaReserva &&ingreso.state!='preingreso'&& <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='teal' disabled={loading} onClick={() => {
                if (CorroborarRegistros(lugarElegido,listaDeptos)) {
                  dispatch({type:"ADD_CUSTOMER"})
                  MandarIngreso(upsertReservation,estado,servicio, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,componentes)
                }
              }}>{'Guardar'}</ButtonSemantic>}
              {!isMobile && !nuevaReserva &&ingreso.state!='preingreso'&& <ButtonSemantic.Or text={"o"} />}

              {((isAdmin && ingreso.state != 'confirmada') || (nuevaReserva)||ingreso.state=='preingreso') && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='blue' disabled={loading}
                onClick={() => {
                  if(nuevaReserva&&isNaN(costo)){
                    alert("Debes cargar algun valor al precio del registro, puede ser 0 pero no puede estar vacio")
                    return
                  }
                  const estado = 'confirmada';
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                      (nuevaReserva || window.confirm(`¿Cambiar el estado a reserva?`))) {

                      const funct= (formaPago,makeBillAux, pagoParcialAux)=>
                      {
                        if (pagoParcialAux!=0&&formaPago=="") {
                          alert("falta agregar forma de pago")
                        }
                        else{
                          dispatch({type:"ADD_CUSTOMER"})
                          MandarIngreso(upsertReservation,estado,{...servicio,paymentsList:pagoParcialAux==0?[]:[{...servicio.paymentsList[0],amount:pagoParcialAux,method:formaPago}]}, setLoading, dispatch, testFunction,makeBillAux, goToPayment, onComplete, sobrevender, withoutPlace,servicio.components)
                        }
                      }
                      if (!diaPlaya&&nuevaReserva) {
                        setOpenPago(true)
                        setOnPay(()=>funct)
                      }
                      else{
                        dispatch({type:"ADD_CUSTOMER"})
                        MandarIngreso(upsertReservation,estado,servicio==undefined?"":{...servicio,paymentsList:pagoParcialAux==0?[]:[{...servicio.paymentsList[0],amount:pagoParcialAux,method:'Efectivo'}]}, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,componentes)
                      }
                  }
                }}>Reserva</ButtonSemantic>}
              {!isMobile && ingreso.state != 'checkin' &&<ButtonSemantic.Or text={"o"} />}
              {ingreso.state != 'checkin' && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='green' disabled={loading}
                onClick={() => {
                  if(nuevaReserva&&isNaN(costo)){
                    alert("Debes cargar algun valor al precio del registro, puede ser 0 pero no puede estar vacio")
                    return
                  }
                  const estado = 'checkin'
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                      (nuevaReserva || window.confirm(`¿Cambiar el estado a ${estado}?`))) {
                    const funct= (formaPago, makeBillAux, pagoParcialAux)=>
                    {
                      if (pagoParcialAux!=0&&!formaPago) {
                        alert("falta agregar forma de pago")
                      }
                      else{
                        dispatch({type:"ADD_CUSTOMER"})
                        MandarIngreso(upsertReservation,estado,{...servicio,paymentsList:pagoParcialAux==0?[]:[{...servicio.paymentsList[0],amount:pagoParcialAux,method:formaPago}]}, setLoading, dispatch, testFunction,makeBillAux, goToPayment, onComplete, sobrevender, withoutPlace,servicio.components)
                      }
                    }
                    if (!diaPlaya&&nuevaReserva) {
                      setOpenPago(true)
                      // reloadCustomeBilling()
                      setOnPay(()=>funct)
                    }
                    else{
                      dispatch({type:"ADD_CUSTOMER"})
                      MandarIngreso(upsertReservation,estado,servicio==undefined?"":{...servicio,paymentsList:pagoParcialAux==0?[]:[{...servicio.paymentsList[0],amount:pagoParcialAux,method:'Efectivo'}]}, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,componentes)
                    }
                }}}>CheckIn</ButtonSemantic>}
              {!isMobile && ingreso.state != 'abierta'&& (ingreso.state == 'confirmada') &&<ButtonSemantic.Or text={"o"} />}
              {ingreso.state != 'abierta' && (ingreso.state == 'confirmada') && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='purple' disabled={loading}
                onClick={() => {
                  const estado = 'abierta'
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                      (window.confirm(`¿Cambiar el estado a ${estado}?`))) {
                      dispatch({type:"ADD_CUSTOMER"})
                      MandarIngreso(upsertReservation,estado,servicio, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,componentes)
                    }}}>Abierta</ButtonSemantic>}
                
              {!isMobile && !nuevaReserva && ingreso.state != 'confirmada' && ingreso.state != 'checkout' && ingreso.state != 'preingreso' && <ButtonSemantic.Or text={"o"} />}
              {!nuevaReserva && ingreso.state != 'confirmada' && ingreso.state != 'checkout'&& ingreso.state != 'preingreso' && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='orange' disabled={loading}
                onClick={() => {
                  const estado = 'checkout'
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                  (nuevaReserva || window.confirm(`¿Cambiar el estado a ${estado}?`))) {
                    dispatch({type:"ADD_CUSTOMER"})

                    MandarIngreso(upsertReservation,estado,servicio, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,componentes)
                  }
                }}>Checkout</ButtonSemantic>}
              {!isMobile && ((!nuevaReserva && isAdmin)) && ingreso.state != 'cancelada' && <ButtonSemantic.Or text={"o"} />}
              {((!nuevaReserva && isAdmin)) && ingreso.state != 'cancelada' && <ButtonSemantic size={isMobile ? 'massive' : 'big'} color='grey' disabled={loading}
                onClick={() => {
                  const estado = 'cancelada'
                  if (CorroborarRegistros(lugarElegido,listaDeptos)&&
                  (nuevaReserva || window.confirm(`¿Cambiar el estado a ${estado}?`))) {
                    dispatch({type:"ADD_CUSTOMER"})
                    MandarIngreso(upsertReservation,estado,servicio, setLoading, dispatch, testFunction,false, goToPayment, onComplete, sobrevender, withoutPlace,componentes)
                  }
                }}>Cancelada</ButtonSemantic>}
      
      </ButtonSemantic.Group>
            <br></br>

          </Col>
        </Row>
        <br></br>
      </Container>
    </LoadingOverlay>

  )
}

export default connect(state => ({ diaPlaya: state.mainReducer.diaPlaya, 
  isAdmin: state.mainReducer.isAdmin, 
  withoutPlace: state.mainReducer.withoutPlace, 
  listaDeptos: state.mainReducer.listaDeptos, 
  listaPrecios: state.mainReducer.listaPrecios, 
  informacionHotel: state.mainReducer.informacionHotel,
  isMobile: state.mainReducer.isMobile }), null)(NuevaReserva)

